import { BASE_URL, MAP_LOADED, BASE_USER_INFO } from './mutations-type.js'
import Bus from '@/assets/js/vueBus'
import Tool from '@/assets/js/utils.js'

export default {
  baseUrl ({ commit, state }) {
    let lastVersion = window.localStorage.getItem('lastCCVersion')
    let currentVersion = window.localStorage.getItem('newCCVersion')
    let newCCConfig = window.localStorage.newCCInitConfig
    // 判断是否更新重新请求接口
    if (lastVersion != currentVersion || !newCCConfig) {
      $.ajax({
        url: '/ccp/tvucc-user/base/initConfig',
        type: 'get',
        async: false,
        success (data) {
          if (data.errorCode == '0x0' && data.errorInfo == 'Success') {
            let result = data.result || {}
            let portForhttpOrHttps = result.portForhttpOrHttps
              ? result.portForhttpOrHttps
              : []
            // 设置url存储在store中
            if (document.location.protocol === 'https:') {
              Object.keys(result).forEach(key => {
                portForhttpOrHttps.map(v => {
                  if (v.key == key) {
                    if (typeof result[key] == 'string') {
                      result[key] = result[key].replace(v.http, v.https)
                    }
                  }
                })
              })
            }
            let val = { urlInfo: result }
            // 设置url存储在store中
            commit(BASE_URL, val)
            Tool.getSession()
            // 设置url存储在localstroge
            window.localStorage.newCCInitConfig = JSON.stringify(val)

            // 存储跳转地址
            let { newuserserviceLoginUrl, authServerName } = result
            localStorage.setItem(
              'newuserserviceLoginUrl',
              newuserserviceLoginUrl
            )
            localStorage.setItem('authServerName', authServerName)

            // 设置上一次版本号
            localStorage.setItem('lastCCVersion', currentVersion)

            // configT和dashboard地址
            localStorage.setItem('dashboardUrl', data.result.dashboardUrl)
            localStorage.setItem('newCCUrl', data.result.mySourceUrl)
            // 不知道干嘛的
            window.localStorage.setItem('oldCCUrl', val.urlInfo.tvuccUrl)
          }
        }
      })
    } else {
      let newCCInitConfig = JSON.parse(window.localStorage.newCCInitConfig)
      // 设置url存储在store中
      let result = newCCInitConfig.urlInfo
      // 设置url存储在store中
      commit(BASE_URL, { urlInfo: result })
      Tool.getSession()
    }
  },
  baseUserInfo ({ commit, state }) {
    $.ajax({
      url: '/ccp/initLoginUser',
      type: 'get',
      success (res, status, xhr) {
        if (res.errorCode == '0x0') {
          let data = res.result ? { userInfo: res.result } : {}
          commit(BASE_USER_INFO, data)
        } else if (res.errorCode == '80000302') {
          window.location.href = res.errorInfo
        }
      },
      error (xhr) {
        if (xhr.status == 401 || xhr.status == 402) {
          console.log('initLoginUser ...' + xhr)
          Bus.$emit('jumpLoginPage')
        }
      }
    })
  },
  mapLoad ({ commit, state, rootState }) {
    commit(MAP_LOADED, 'true')
  }
}
