<template>
  <div>
    <el-select
      popper-class='lang_box'
      class="lang_box_input"
      v-model="langSelt"
      @change='changeLangEvent'
      :popper-append-to-body="true">
      <el-option
        v-for="item in languageList"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import store from '../store'
import Tool from '@/assets/js/utils'
export default {
  props: ['langOps'],
  data () {
    return {
      langSelt: langConfig.langValue || window.localStorage.getItem('lang') || navigator.language || navigator.userLanguage, // 获取当前浏览器语言
      languageList: langConfig.languageList,
      isMapTrackReal: false
    }
  },
  created () {
    this.changeLangEvent(this.langSelt, true)
    $(window).resize(() => {
      this.langSelt = langConfig.langValue || window.localStorage.getItem('lang') || navigator.language || navigator.userLanguage // 获取当前浏览器语言
    })
  },
  computed: {
    langLabel () {
      const selected = this.languageList.filter(v => v.value == this.langSelt)
      if (selected.length) return selected[0].label
      return 'English'
    }
  },
  methods: {
    changeLangEvent (langSelt, flag) { // 切换语言
      if (this.$route.name == 'SourceMapTrack' && Tool.getHashParams('timeType') == 0) {
        this.$message.info(this.$t('lang.realTrackCantChange'))
        return
      }
      langSelt = langSelt || this.$refs.lang.value
      window.localStorage.setItem('lang', langSelt)
      Bus.$emit('changIpLanguage', langSelt)
      Bus.$emit('changeListType', langSelt)
      langConfig && langConfig.changeLangCookie(langSelt)
      this.languageList = langConfig.languageList
      if (flag) window.localStorage.setItem('mapLang', langSelt)
      if (flag || typeof loadMergeLanguageAsync === 'undefined') {
        this.langSelt = langSelt
        this.$i18n.locale = langConfig.langValue
      }
      // 头部导航国际化处理
      if (!flag) {
        if (window.headerObj) window.headerObj.__proto__.bindDom(langSelt)

        Bus.$emit('changrLang', langSelt)
        if ($('#iframeBox').length && $('#iframeBox')[0].contentWindow) {
          const obj = {
            type: 'changeLang',
            data: langSelt
          }
          Bus.$emit('postMessage', JSON.stringify(obj))
        }
      }
      const localRouterN = this.$route.name
      if (!flag) {
        setTimeout(() => {
          document.title = `${this.$t('lang.titlePre')} - ${this.$t('lang.' + this.$route.meta.title)}`
        }, 600)
        if ($('.mediaDialog').length > 0) {
          mediaObj.changeType = 'changeLanguage'
          SM.__proto__.JumpMediaJs()
        }
      }
      Bus.$emit('langSeltSwitch', langSelt)// 将语言切换参数发送到内嵌的switch组件
      // 当前路由为地图模式时需要刷新界面，根据语言切换地图域名及语言
      if (!flag && langSelt != window.localStorage.getItem('mapLang')) {
        window.localStorage.setItem('mapLang', langSelt)
        if (localRouterN == 'SourceMap' || localRouterN == 'NSourceMap' || localRouterN == 'Setting' && store.state.settingMapShow) {
          this.$router.go(0)
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
.mobileLang ::v-deep .el-select.lang_box_input .el-input.el-input--suffix .el-input__inner {
  text-align: left;
}
::v-deep .el-select .el-input__inner {
  background: none;
  text-align: right;
  width: 130px;
}
.el-dropdown-link {
  color: #dbdbdb;
}
::v-deep .el-select.lang_box_input {
  width: 125px;
  .el-input.el-input--suffix,
  .el-input.el-input--suffix .el-input__inner {
    height: 100%;
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 13px;
    border-radius: 4px;
    outline: none;
    border: none;
  }
  .el-input__suffix {
    right: 0;
  }
}
</style>
<style lang='less'>
.el-select-dropdown.el-popper.lang_box li.el-select-dropdown__item:hover, .el-select-dropdown.el-popper.lang_box li.el-select-dropdown__item.hover {
  background: rgba(57,170,80,.25)!important;
}
.el-select-dropdown.el-popper.lang_box {
  z-index: 100000 !important;
  ul {
    padding: 0;
  }
  li {
    font-size: 13px;
    padding: 0 6px;
    height: 30px;
    line-height: 30px;
  }
  li.el-select-dropdown__item:hover,
  li.el-select-dropdown__item.hover {
    background: #409eff;
  }
  li.el-select-dropdown__item.selected:after {
    content: '';
  }
}
@media (max-width: 1024px) {
  .el-select-dropdown.el-popper.lang_box {
    li {
      font-size: 0.15rem;
    }
  }
}
</style>
