export default {
  lang: {
    CustomerUser: 'Customer User',
    switchTo: 'Switch to',
    internetOffline: 'Internet connection failed, please try again later.',
    notice: 'Notice',
    noticeTitle: 'We have detected that some services active on your account have now passed the free trial period',
    noticeContent1: 'During the ongoing Coronavirus pandemic, free services will not be discontinued without consent. To continue using these services, please read and agree to the',
    noticeContent2: 'To actively discontinue services, please contact features@tvunetworks.com',

    /* ---- title ---- */
    webrTitle: 'TVU Receiver',
    titlePre: 'TVU Command Center',
    TMySource: 'My Sources',
    TAdvanceControl: 'Advanced Control',
    TMultiControl: 'Multi Control',
    TMyReceivers: 'My Receivers',
    TFileDownload: 'File Download',
    TSetting: 'Setting',
    TMultiView: 'Multi-View',
    MultiControl: 'Multi-Control',
    TShare: 'Share',
    TToken: 'Token',
    TAlert: 'Alert',
    GridViaToken: 'TVU Grid via Token',
    GlobalGrid: 'Global Grid',
    TRouterMonitor: 'Router Monitor',
    TBooking: 'Booking',
    TPlayout: 'Playout',
    RPS: 'RPS',
    homeTitle: 'Home',
    SearchMonitor: 'Monitor',

    title: 'Command Center',
    title1: 'Switch',
    total: 'Total',
    inputDescrible: 'Video Name',
    modelTips: 'Tips',
    searchT: 'Source Name',
    searchTText: 'PeerID or Name',
    equipmentName: 'Name：',
    equipmentIdentity: 'PeerID：',
    platform: 'Platform',

    /* ---- status ---- */
    status: 'Status',
    online: 'Online',
    offline: 'Offline',
    live: 'Live',
    going: 'Ongoing Event',

    /* ---- button ---- */
    stop: 'Stop',
    Switch: 'Take',
    Take: 'Take',
    Stop: 'Stop',
    Ok: 'Ok',
    On: 'On',
    Off: 'Off',
    cancel: 'Cancel',
    Cancel: 'Cancel',
    close: 'No',
    confirm: 'Yes',
    loading: 'Loading',
    stopping: 'Stopping',
    pushing: 'Pushing',

    /* ---- filter ---- */
    source: 'Source',
    destination: 'Destination',
    Enable: 'Enable',
    grid: 'TVU Grid',
    logout: 'Sign Out',
    allall: 'All',
    sort: 'Sort by Status',
    Type: 'Type',
    receiver: 'Receiver',
    version: 'Version',

    /* ---- popup ---- */
    notSource: 'Please select a source.',
    rightNumber: 'Please input right number.',
    NoResult: 'No Results Found',
    of: 'of',
    serverError: 'The operation failed, please try again later',
    ReceiverNotFound: 'Receiver not found, please confirm and try again',
    ReceiverOffLine: 'Receiver is offline',
    sourceNotExist: 'Source does not exist',
    ParamError: 'Server error, please contact TVU Support',
    SendToRFail: 'Server error, please contact TVU Support',
    youtubeSendToRFail: 'Youtube failed to send to receiver',
    DeviceOffLine: 'Source offline',
    ReceiverOnLive: 'This receiver is LIVE with another transmitter. Would you like to stop it and start LIVE with this transmitter?',
    ReceiverOnLive1: 'This receiver is LIVE with another transmitter',
    ReceiverOnLive2: 'Would you like to stop it and start LIVE with this transmitter?',
    ReceiverDeviceOnLine: 'This transmitter is LIVE with another receiver. Would you like to stop it and start LIVE with this receiver?',
    ReceiverDeviceOnLine1: 'This transmitter is LIVE with another receiver',
    ReceiverDeviceOnLine2: 'Would you like to stop it and start LIVE with this receiver?',
    isAutoReceiver: 'This transmitter is LIVE with auto channel, would you like to stop it.',
    isAutoRLive: 'Would you want to use auto receiver to LIVE this transmitter',
    isManualReceiver: 'This transmitter is LIVE with manual channel, would you like to stop it.',
    DeviceOnLine: 'Current source device is on live, do you still want to continue this operation?',
    RnotfoundthisSource: 'Source not found, please confirm and try again',
    ReceiverNotLive: 'Receiver is not live',
    ReceiverNotLiveWithSource: 'Receiver not live with source',
    timeOut: 'Request timed out. Please try again.',
    versionLow61: 'The source version is too low, it is recommended to upgrade.',
    receiverisnotexist: 'No Receiver found.',
    sourceNotLiveWithCurrentReceiver: 'Source is not live with current receiver',
    receiverNotLiveWithCurrentSource: 'Source is not live with current receiver',
    i18n_transimitterLiveReceiver: 'This receiver is LIVE with another transmitter. Would you like to stop it and start LIVE with this transmitter?',

    /* ---- my source page ---- */
    locationFailed: 'Transceiver location acquisition failed',
    extSourceNameTips: 'Name cannot be empty and must be 3 characters minimum.',
    GLinkSourceIpTips: 'Please enter the correct IP format!',
    GLinkSourcePortTips: 'Please enter the correct port format!',
    GlinkUpperLimit: 'The number of IPs and ports has reached the upper limit',
    extSourceTokenTips: 'Token cannot be empty.',
    trackCollection: 'Track Collection',
    realTime: 'Real Time',
    history: 'History',
    multiTracking: 'Multi-tracking',
    addCpllection: 'Add to Track Collection',
    collectionName: 'Collection Name',
    deleteCollection: 'Delete this collection',
    collectionPrompt: 'After saving, it can be used as a reference track in “Multi-tracking" mode',
    confirmBtn: 'Confirm',
    deleteOnlyCollectionPrompt: 'The “Multi-tracking” mode requires at least one collection.Do you still want to delete this collection?',
    deleteCollectionPrompt: 'Do you want to delete this collection?',
    selectCollection: 'Please select the collection trajectory',

    /* ---- advanced control page ---- */
    validate: 'Validate',
    validating: 'validating...',
    record: 'Record',
    downloadAll: 'Download All',
    Preview: 'Preview',
    Download: 'Download',
    state: 'State',
    size: 'Size',
    compeleted: 'Compeleted',
    Play: 'Play',
    deleteAll: 'Delete All',
    downList: 'DownList',
    exportSelection: 'Export Selection As',
    saveAs: 'Save As',
    export: 'Export',
    /* ---- popup ---- */
    notSelectClip: 'Please select you want to export clip.',
    moreThanLimitation: 'Max number of previews - {0} has been reached. Please turn off other preview windows first.',
    syncTitle: 'Time Lock is only availble when selected receivers are live with TVUPack',
    syncCanUseTitle: "Start failed, please check the R and T version is more than 6.1 or not, it's right please select al least two receiver. ",
    notHaveSyncFeature: 'Device {0} not access tpc time lock feature',
    synchronizing: 'Device is synchronizing',
    deviceSynchronizing: 'Exist device synchronizing by other account',
    maxMultiControlNumber: 'You can only select six video to preview',
    notLastOne: 'You need to delete from last one',
    picturesFormat: 'The max picture size is 480*640',

    // advanced control settingDaiog
    notRecording: 'Not recording',
    Recording: 'Recording',
    clipboard: 'Copy Full Name to Clipboard',
    downloadedFiles: 'Downloaded Files',
    startTime: 'Start Time',
    endTime: 'End Time',
    compeletedTime: 'Compeleted Time',
    noBindUser: 'no bind user',
    emailNotExist: 'Email not exist',
    // nameRequired: 'Please enter name',
    urlRequired: 'Please enter URL',
    urlPathRequired: 'Please enter URL / Path',

    /* ---- Booking ---- */
    selectR: 'Select a destination',
    monthKey: 'Month',
    weekKey: 'Week',
    dayKey: 'Day',
    todayKey: 'Today',
    invalidEvent: 'This event is completed',
    // booking.common
    bookingRTimeconflict: 'Receiver booking schedule conflict',
    bookingTTimeconflict: 'TVUPack booking schedule conflict',
    // booking.addEvent
    endTimeMoreCurTime: 'The end time must be greater than the current time',
    endTimeMoreStartTime: 'The end time must be greater than the start time',
    deadLineMoreEndTime: 'The deadline must be greater than the start time and end time',
    startTimeMoreCurTime: 'The start time must be greater than the current time',
    invalidSourcePeerId: 'Invalid Source Peer ID',
    invalidDestPeerId: 'Invalid Destination Peer ID',
    sourceTypeIsNull: 'Source type is empty',
    invalidStartTime: 'Invalid startTime',
    invalidEndTime: 'Invalid endTime',
    biggerEndTime: 'End Time must be greater than Start Time',
    biggerStartTime: 'Start Time must be greater than now',
    sourceSetDelay: 'Delay value cannnot be empty for TVUPack sources',
    delayInputRange: 'Delay: 0.5s to 50s',
    sourceSetBitrate: 'Delay value cannnot be empty for TVUPack sources',
    bitrateInput: 'Bitrate: 100kbps - 10240kbps',
    sourceSetUrl: 'URL cannot be empty for External Source',
    matrixSetOutputId: 'The Matrix destination must set outputId',
    matrixSetSwitchtype: 'The Matrix destination must set switchtype',
    setswitchtype: 'The Switch Type must be 1 or 2',
    matrixSupport: 'The Matrix only supports EXT or SDI source',
    sdiInNotSupport: 'This SDI source does not support Matrix',
    cannotModifyEvent: 'Finished events cannot be modified',
    endTimeBigger: 'End Time must be greater than now',
    startTimeBigger: 'Start Time must be greater than now',
    cannotModifyStartTime: 'Cannot modify Start Time',
    refuseCall: 'Peer refuse call request!',
    // booking.delete
    deleteEventFail: 'Event delete operation failed',
    eventIdEmpty: 'Event ID is empty',
    // booking.update
    eventNotexist: 'Event not exist',
    eventNotEdit: 'Event will end in one minute. Editing not permitted. ',
    matrixEventCannotModify: 'Matrix Booking Event cannot be modified',
    notEditEvent: 'Cannot edit this event',
    msgpipeConnectError: 'Msgpipe connect error',
    accountExpired: "The selected '{nickName}' streaming accounts have expired, please re-authenticate",

    /* ---- title ---- */
    Bookmark: 'Bookmark',

    /* ---- home ---- */
    unSupport: 'Not support this function',
    receiverControlTitle: 'Receiver Control',
    sourceControlTitle: 'Source Control',
    adjustCamera: 'Adjust Camera',
    manualPan: 'Manual Pan',
    autoPan: 'Auto Pan',
    Close: 'Close',
    reset: 'Reset',
    receiverID: 'Receiver ID:',
    playerVersion: 'Player Version:',
    recRemain: 'Rec remain:',
    coreVersion: 'Core Version:',
    productVersion: 'Product Version:',
    buildDate: 'Build Date:',
    IdleCpu: 'Idle CPU:',
    CpuTemp: 'CPU Temperature:',
    FreeMem: 'Free Memory:',
    RemainSpace: 'Storage Remaining:',
    SpaceRemainTime: 'Time Remaining:',
    LoadAvg1Min: 'Load Average 1min:',
    LoadAvg5Min: 'Load Average 5min:',
    LoadAvg15Min: 'Load Average 15min:',

    favorite: 'My Favorite',
    PHSearch: 'Search video or transceiver',
    Codec: 'Codec',
    CardID: 'Card ID',
    DeviceNoSource: 'Source is abnormal, please check the video connection',
    selectWebrtcSource: 'Sorry...The video preview is currently unavailable, please check the WebRTC Settings of the device. ',
    pleaseWait: 'Request has been sent. Please wait.',
    netSlow: 'Your operation will be synchronized when you reconnect,possibly failing. Trying to reconnect…',
    updateBrowser: 'The latest Chrome browser for the best viewing',
    guideFilter: 'Filtering by its name/PID, status or your favorite',
    guideFavorite: 'Mark this be your favorite',
    checkFail: 'check Fail',
    confirmStopBooking: 'This receiver is currently running a booked event. Are you sure you want to stop it?',
    guideSelT: 'Select a source to take',
    guideDesVideo: 'Describe the video',
    next: 'Next',
    goIt: 'Go it',
    notSwitch: "Can't switch the same one",
    cameraOff: 'No camera.',
    i18n_stopLiveFailed: 'Stopping live failed.',
    i18n_startLiveFailed: 'Starting live failed.',
    vaildVal: 'Valid value is {0}<->{1}',
    adminUser: 'Support or super administrator account is temporarily unavailable for this system.',
    sourceNotHave: 'The current source is not in current account.',
    netStop: 'Network interruption...',
    liveFail: 'Video playback failed, retry?',
    testMsg: '{0} is occupied by {1}',
    copy: 'Duplicate',
    duplicateEvent: 'Duplicate Event',
    create: 'Create',
    delete: 'Delete',
    viewSchedule: 'View Schedule',
    runningBookings: 'Running Bookings',
    pastBookings: 'Past Bookings',
    cancelledBookings: 'Cancelled Bookings',
    bookingFilter: 'Filter',
    filters: 'Filters',
    apply: 'Apply',
    Apply: 'Apply',
    applying: 'Applying',
    scheduleTitle: 'Title',
    start: 'Start',
    end: 'Stop',
    Start: 'Start',
    duration: 'Duration',
    deviceSource: 'Source',
    delayAndBit: 'Delay/Bitrate',
    storySlug: 'Story Slug',
    operation: 'Operation',
    createTitle: 'Title',
    reporter: 'Reporter',
    des: 'Story Slug',
    time: 'Time',
    // to: 'to',
    delay: 'Delay',
    bit: 'Bitrate',
    order: 'Creating Booking',
    editOrder: 'Edit Booking',
    interrupted: 'Interrupted',
    failed: 'Failed',
    planning: 'Planning',
    running: 'Running',
    completedStatus: 'Completed',
    deleted: 'Deleted',
    pending: 'Pending',
    edit: 'Edit',
    min: 'min',
    RVersion: 'R version:',
    RPeerID: 'R PeerID:',
    ErrorRate8s: 'Error Rate(8s):',
    ErrorRate1s: 'Error Rate(1s):',
    LineQuality8s: 'Line Quality(8s):',
    LineQuality1s: 'Line Quality(1s):',
    TBattery: 'Battery:',
    TInput: 'Video Input:',
    TFormat: 'Input Format:',
    RFormat: 'Output Format:',
    Output: 'Output',
    ProcessID: 'Process ID',
    Frames: 'Frames',
    Fps: 'FPS',
    IFB: 'IFB:',
    IFBSignal: 'IFB Signal:',
    VoIPSignal: 'VoIP Signal:',
    VoIPOut: 'Audio Out',
    VoIPIn: 'Audio In',
    Delay: 'Delay',
    Bitrate: 'Bitrate',
    kbps: 'kbps',
    Dynamic: 'Dynamic',
    Fixed: 'Fixed',
    VBR: 'VBR',
    CBR: 'CBR',
    Quality: 'Quality:',
    Home: 'Home',
    ReceiverControl: 'ReceiverControl',
    Alert: 'Alert',
    MultiView: 'MultiView',
    EventAlert: 'Event Alert',
    Token: 'Token',
    Booking: 'Booking',
    Share: 'Share',
    Setting: 'Setting',
    FileDownload: 'FileDownload',
    More: 'More',
    ReceiverNotExist: 'Receiver not exist',
    Sourceliving: 'The source is living',
    ReceiverNotConnect: 'Reciver not connect',
    ReceiverError: 'Unknown',
    PeerNotFound: 'The current peer is not found',
    selectEventFirst: 'Please select an event first',
    confirmHttps: 'VoIP uses HTTPS protocol which may slightly slow down the page.',
    HadVoip: 'To continue with VoIP, please stop the last active session first.',
    AnalyzeUrlFail: 'The current url is not available.',
    AnalyzingUrl: 'Analyzing Url...',
    AnalyzingFail: 'Analyzing failed',
    AnalyzingSuccess: 'Analyzing success',
    getLocalMediaFail: 'Failed to get access to local media',
    EndVoIP: 'The call has ended',
    Details: 'Details',
    note: 'Note',
    device: 'Device',
    color: 'Color',
    save: 'Save',
    creating: 'Creating',
    TVUPack: 'TVUPack',
    TVUAnywhere: 'TVUAnywhere',
    'Grid-Pack': 'TVU Grid-Pack',
    'Grid-SDI': 'TVU Grid-SDI',
    Ext: 'Ext',
    'Local-SDI': 'Local SDI',
    SMPTE2022: 'SMPTE2022',
    YouTube: 'YouTube',
    selectReceiver: 'Select Receiver',
    Name: 'By Name',
    Online: 'Online First',
    Live: 'Live First',
    Filter: 'Filter',
    trackName: 'Tracking',
    addExtSource: 'Add External Source',
    editExtSource: 'Edit External Source',
    addGLinkSource: 'Add G-Link Source',
    editGLinkSource: 'Edit GLink Source',
    selectOutputFormat: 'Please select a output format',
    name: 'Name',
    Save: 'Save',
    BasicSetting: 'Basic Setting',
    General: 'General',
    TimeZone: 'Time Zone',
    FeatureControl: 'Feature Control',
    GridEncoder: 'TVU Grid Encoder',
    GLinkEncoder: 'GLink Encoder',
    Transcriber: 'Transcriber',
    SDIOutput: 'SDI Output Setting',
    AutoRecord: 'Auto Record',
    IPStreamOut: 'IP Stream Out',
    FileBrowser: 'File Browser',
    AutoDownload: 'Auto Download',
    ProgressiveDownload: 'Progressive Download',
    localAllPackPosition: 'Locate All Pack Position',
    SetLocation: 'Set Location',
    IPandPortMapping: 'IP and Port Mapping',
    SetMedia: 'Set Media',
    gLinkEncoder: 'G-Link Encoder',
    Delete: 'Delete',
    option: 'Select',
    tableView: 'Table View',
    calendarView: 'Calendar View',
    deviceNameIpt: 'PeerID or name',
    errRate: 'Error Rate',
    lineQuality: 'Line Quality',
    tBattery: 'T Battery',
    tFormat: 'T Format',
    tInput: 'T Input',
    rFormat: 'R Format',
    liveVideo: 'Live Video',
    runningBooking: 'Running Bookings',
    audio: 'Audio',
    left: 'L',
    right: 'R',
    capitalDelay: 'DELAY',
    capitalBit: 'BITRATE',
    quality: 'Quality',
    'en-US': 'English(United States)',
    'ja-JP': 'Japanese(Japan)',
    'pt-PT': 'Poruese(Portugal)tug',
    'zh-Hans': 'Chinese(Simplified,PRC)',
    eventAlert: 'Event Alert',
    send: 'Send',
    receivers: 'Receivers',
    sources: 'Sources',
    transmission: 'Transmission',
    noTransmission: 'No transmission info',
    receiverPID: 'Receiver PID',
    sourcePID: 'Source PID',
    priority: 'Priority',
    eventName: 'Event Name',
    FolderName: 'Folder Name',
    eventTime: 'Event Time',
    location: 'Location',
    errorCodeFormartError: 'error code formart error',
    livePort: 'Live Port',
    previewPort: 'Preview Port',
    description: 'Description',
    notes: 'Notes',
    errorInfo: 'Please input necessary data!',
    deleteBookingPrompt: 'Are you sure you want to delete the selected bookings?',
    log: 'Log',
    notPaired: 'Source and Receiver are not paired',
    sourceOccupied: 'Source  occupied',
    liveFailed: 'Live Failed',
    serviceClosed: 'Booking Service for this Receiver is not enabled',
    receiverOffline: 'Receiver offline',
    receiverStopped: 'Receiver stopped',
    sourceSwitched: 'Source switched manually',
    receiverStoppedManually: 'Receiver stopped manually',
    goodNight: 'Good Night',
    bookmark: 'Bookmark',
    filter: 'Filter',
    tvuPack: 'TVUPack',
    tvuAnywhere: 'TVUAnywhere',
    gridPack: 'TVU Grid-Pack',
    gridSDI: 'TVU Grid-SDI',
    receiverOccupied: 'Receiver occupied',
    titleEmpty: 'Title cannot be empty',
    durEmpty: 'Duration cannot be empty',
    sourceEmpty: 'Source cannot be empty',
    sourceSelectFirst: 'Please select a Source/SCTE first',
    unClassified: 'Unclassified',
    compose: 'Compose',
    inbox: 'Inbox',
    sent: 'Sent',
    selAll: 'Select All',
    from: 'From',
    to: 'To',
    allDevice: 'All Devices',
    now: 'Now',
    hour: 'hour ago',
    receiverControl: 'TVU Command Center',
    mySource: 'TVU Command Center -- Homepage',
    settingControl: 'TVU Command Center -- Setting',
    token: 'Token',
    all: 'All',
    rIsNotLiving: 'Current receiver is not living.',
    localSourceCanNotStop: 'The locally connected source cannot be stopped.',
    noCamera: 'No camera!',
    tokenPending: 'Pending',
    battery: 'Battery',
    PeerIncorrectStatus: 'The status of the Source is detected to be incorrect or the status of the Source is not obtained by Receiver.',
    prompEnsureDel: 'Are you sure want to delete selected events?',
    good: 'Good',
    bad: 'Bad',
    veryBad: 'Very bad',
    worse: 'Worse',
    rDEmpty: 'Destination cannot be empty',
    rEmpty: 'Channel cannot be empty',
    btn: 'OK',
    selectPlaceholder: 'Please Choose Device',
    sureDeleteEvent: 'Are you sure you want to stop this event?',
    minAgo: 'min ago',
    hourAgo: 'hour ago',
    sentAlert: 'Sent Alerts',
    i18n_noauthorization: 'No authorization',
    refreshInterval: 'Refresh interval (seconds):',
    coordinate: 'coordinate',
    accountExist: 'This user account already has Command Center access. Please contact TVU Support for assistance.',
    nameExist: 'The name already exists',
    nameNotExist: 'Device does not exist',
    correctLocation: 'Please select the correct location',
    noAccessApp: 'User can not access AccessApp',
    bindedDevice: 'This user had binded with this device',
    bindFail: 'bind user fail',
    removeAllFail: 'remove all fail',
    removeFail: 'remove fail',
    firstName: 'First Name',
    lastName: 'Last Name',
    nameRequired: 'First Name and Last Name cannot be empty',
    copyUserTo: 'Copy user to',
    destinationDevice: 'Destination Device',
    deleteOriginalDevice: 'Delete the original device',
    fullName: 'Enter the full PID/Name',
    newDeviceNotExit: 'New device not exist',
    Copy: 'copy',
    capitalCopy: 'Copy',
    clearFilter: 'Clear Filters',

    // share
    i18n_shareLiveVideo: 'Live Video',
    SocialAccount: 'Social Account',
    VirtualCloudR: 'Virtual Cloud Receiver',
    // liveVideo
    i18n_shareSearchTText: 'PeerID or Name',
    i18n_refresh: 'Refresh',
    // Source
    i18n_destination: 'Destination',
    i18n_source: 'Source',
    i18n_moreShare: 'Publish',
    i18n_shareDirectory: 'Share Directly',
    i18n_sharing: 'sharing~',
    i18n_loading: 'Loading~',
    // Social
    i18n_websiteType: 'Website Type',
    i18n_outputName: 'Output Name',
    i18n_yes: 'Yes',
    i18n_no: 'No',
    i18n_operate: 'Operation',
    i18n_ifSharing: 'Sharing',
    Facebook: 'Facebook',
    Periscope: 'Twitter',
    Twitter: 'Twitter',
    Twitch: 'Twitch',
    Instagram: 'Instagram',
    KuaiShou: 'KuaiShou',
    YiLive: 'Yi Live',
    Others: 'Others',
    Vimeo: 'Vimeo',
    i18n_confirm: 'OK',
    i18n_quite: 'Cancel',
    i18n_ifDelete: 'Are you sure you want to remove it?',
    i18n_deleteSuccess: 'Remove success!',
    noChart: 'Search content contains special characters, please re-enter!',
    // map
    i18n_mapFailed: 'Fail to load map, you can try to reload the page.',

    available: 'Available',
    noSource: 'Online with no source',
    viaToken: 'TVU Grid Via Token',
    globalGrid: 'Global Grid',
    alertContent: 'Please input alert content',
    iptNesInfo: 'Please input necessary information!',
    addReceiver: 'Please add recipient for the message.',
    sourceType: 'Source type',
    format: 'Format',
    error: 'Error',
    errorTips: 'Error',
    batteryVal: 'Battery',
    slot: 'Slot',
    metadata: 'Metadata',
    noMetadata: 'No Metadata info',
    tokenCode: 'Token Code',
    tokenCodeTips: 'Enter the token code',
    self: 'Introduce Yourself',
    selfTips: 'Who are you (Optional) ?',
    chooseRecevice: 'Select which Receivers the Token TVU Grid sources should be paired to',
    chooseReceviceTips: 'Enter the PeerID or name to search',
    /* home lang */
    home_EnterReceiverNamePeerID: 'Enter Receiver Name/PeerID',
    home_Live: 'Live',
    live_OnHold: 'Live on hold',
    home_Online: 'Online',
    home_clickOfflineR: 'This device is offline.',

    // Setting
    theSameDevice: 'The current device is already exists',
    wait2Second: 'Click it 2 seconds later',
    removeIt: 'Are you sure you want to remove it',
    createAccess: 'Create an Access App',
    editAccess: 'Edit Access App',
    company: 'Company',
    accessSearch: 'Company / Email / AppKey / AppSecret',
    email: 'Email',
    emailOrId: 'Full matching of accounts/Full PID',
    role: 'Role',
    accountType: 'Account Type',
    appKey: 'AppKey',
    appSecret: 'AppSecret',
    whiteIP: 'WhiteIP',
    operationEmail: 'Operation Account',
    emailEmpty: 'Email cannot be empty',
    companyEmpty: 'Company cannot be empty',
    emailformat: 'Email format is incorrect!',
    emailAddress: 'Please input a correct Email address.',
    ExternalSource: 'External Source',
    Account: 'Account',
    relationHistory: 'Account Relation History',
    AccessAPP: 'Access APP',
    SalesForce: 'Sales Force',
    FileServer: 'File Server',
    Device: 'Device',
    MenuList: 'Menu List',
    set_name: 'The name cannot be empty!',
    set_nameLength: 'Please input minimum 3 characters.',
    set_url: 'The URL cannot be empty',
    ipFormatError: 'IP format error',
    userCreation: 'User Creation',
    userEditing: 'Modify Permission',
    choice: 'Please Select',
    inputLegitEmail: 'Please input a legit Email address!',
    inputUesr: 'Please select owner',
    inputPassword: 'Please input password!',
    inputPhone: 'Input error. Only +, - and numbers are allowed in a phone number. E.g. +1-541-754-3010',
    admin: 'Admin',
    user: 'User',
    phone: 'Phone',
    remark: 'Remark',
    instanceType: 'instanceType',
    modifyPermission: 'Modify Permission',
    nodeactivate: 'Inactive',
    yesactivate: 'Active',
    activate: 'Activate',
    deactivate: 'Deactivate',
    inactive: 'Inactive',
    active: 'Active',
    deleteRole: 'Delete Role',
    addRole: 'Add Role',
    degradeRole: 'Degrade Account',
    increaseRole: 'Increase Account',
    password: 'Password',
    permission: 'Permission',
    licensedFeature: 'Licensed Feature',
    licensedFeatureReport: 'Licensed Feature Report',
    owner: 'Owner',
    subscriber: 'Subscriber',
    input3Character: 'Please input at least 3 characters to filter',
    availableDevice: 'Available Device',
    bindingDevice: 'Binding Device',
    unknow: 'Unknow',
    salesforceAccount: 'Salesforce Account',
    uername: 'Username',
    deviceType: 'Device Type',
    relationManagement: 'Relation Management',
    relationManagementWarning: '*Warning: when removing a receiver from an account, the existing token pairings with the receiver will also be removed.',
    bindingAccount: 'Binding Account',
    changeDevice: 'Change Device Property:',
    showPaired: 'Show the paired devices',
    automatic: 'Automatic',
    manual: 'Manual',
    noPosition: 'No Location',
    userInfo: 'User Information',
    GEOLocation: 'Location',
    LastOnlineTime: 'Last Online',
    peerID: 'PeerID',
    function: 'Function',
    availableFunction: 'Available Function',
    enabledFunction: 'Enabled Function',
    editApi: 'Edit API Function',
    aipFunction: 'API Function',
    addWhiteIp: 'Add WhiteIP',
    existIp: 'This IP already exists',
    whiteIPList: 'WhiteIPList',
    accessControl: 'Access Control',
    Licensed: 'Licensed Feature Report',
    startTimeText: 'Start Time',
    endTimeText: 'End Time',
    requestId: 'Request Id',
    userId: 'User Id',
    relationAccount: 'Relation Account',
    relationAccountId: 'Relation Account Id',
    param: 'Param',
    operationType: 'Operation Type',
    excelAll: 'Excel All',
    decivesAssignedAnotherUser: 'One or more devices have been assigned to another user. Would you like to continue?',
    sameIp: 'The same IP exists',
    activateTips: 'Are you sure you want to Active this user?',
    deactivateTips: 'Are you sure you want to Inactive this user?',
    monitorUserTips: 'Are you sure you want to View this user?',
    gotoSupportTips: 'Are you sure you want to return to the support account?',
    editDevice: 'Edit Device Name',
    removelAllUsers: 'Remove all users',
    removeUsers: 'Remove users',
    removelUsers: 'Remove this users',
    ifRemoveUsers: 'Do you want to remove all users?',
    ifRemoveUser: 'Do you want to remove this user?',
    userAccountTips: 'please select user account',
    setlocationTips: 'Please select the location below or enter the location',
    bookmarkNameTips: 'Bookmark name exist',
    connotDelete: 'This source has already been scheduled by the Booking Service. Please remove the existing bookings to proceed.',
    useExist: 'User not exist',
    anotherSourceEdit: 'Another source is being edited',
    completeEditOperation: 'Please complete the editing operation first',
    bookmarkNameRequired: 'Bookmark name cannot be empty.',
    companyExist: 'company name is exist!',
    extLiving: 'Device is living',
    RPSEncoder: 'RPS Encoder',
    RPSDecoder: 'RPS Decoder',
    set_name_space: 'Space is not allowed in device names',
    accountEmpty: 'account is not empty',
    moduleNotFound: 'Module Not Found',
    idNotFound: 'Id not found',
    addUser: 'Add User',
    DeviceChangeLog: 'DeviceChangeLog',
    clear: 'clear',
    passwordLength: 'Password is at least 6 digits',
    TestInstance: 'Test Instance',
    startInstance: 'Start Instance',
    runInstance: 'Instance is running',
    starting: 'Starting',
    ending: 'Ending',
    noPairR: 'not pair R!',
    GroupManage: 'Group',
    connectedCC: 'Connected to CC',
    getSessionFailed: 'get session failed',
    waitMinutes: 'Starting a WebR may take approximately 3 - 5 minutes',
    selectVaildTime: 'Please select a vaild time',
    deviceFeature: 'Device Feature',
    startDate: 'Start Date',
    endDate: 'End Date',
    explainA: 'To extend these features, please contact',
    explainB: 'your sales representative or TVU Support',
    reloadTips: 'Account reloading may take some time to finish. Please be aware.',
    addReloadTips: 'Add missing sources that are paired with the receivers.',
    removeReloadTips1: 'Remove sources that are not paired with any receivers. ',
    removeReloadTips2: 'Proceed cautiously with RPS/Router/Producer accounts.',
    addEmail: 'Add Email',

    // SourceMap
    i18n_equipmentIdentity: 'PeerID：',
    i18n_equipmentName: 'Name：',

    // Pagination
    i18n_first: 'First page',
    i18n_pre: 'Previous page',
    i18n_next: 'Next page',
    i18n_last: 'The last page',
    i18n_jump: 'Redirect to',
    i18n_total: 'Total is',
    i18n_severalData: 'piecesdata',

    // token
    autoApprove: 'automatically approve all incoming Token requests',
    editDeviceBtn: 'Edit Device',
    expireTime: 'Expire time:',
    gridReceiver: 'TVU Grid Source',
    createToken: 'Create Token',
    editToken: 'Edit Token',
    tokenName: 'Token Name',
    tokenType: 'Token Type',
    expiration: 'Expiration',
    availableList: 'Available List',
    saleAvailableList: 'Available List',
    selectedList: 'Selected List',
    pid: 'PID',
    deviceName: 'Device Name',
    add: 'Add',
    remove: 'Remove',
    addAll: 'All',
    removeAll: 'All',
    iptTokenName: 'Give Token a name',
    iptExpiration: 'Choose an expired time',
    iptAvailable: 'Enter 3 characters minimum',
    peeridOrName: 'PeerID or name',
    qrPromp: 'Share the QR or token code with others for pairing with the following devices.',
    receiverList: 'Receiver List',
    snedCodeToEmail: 'Send Code To Email',
    ensureDelete: 'All devices paired are going to be lost!',
    snedCodeTo: 'To',
    emialAds: 'Email address',
    bookmarkName: 'Bookmark name',
    createBookmark: 'Create Bookmark',
    includeInBokmk: 'Include in bookmark',
    bookmarkBars: 'Bookmarks Bar',
    deleteBookmark: 'Do you want to delete this bookmark?',
    addDevice: 'Add Device',
    move: 'Move',
    moveDeviceTo: 'Move Device to',
    copyDeviceTo: 'Duplicate Device to',
    success: 'Success',
    chooseBookmark: 'Please choose one bookmark',
    sureDeleteDevice: 'Are you sure you want to delete these devices from this bookmark?',
    relationMang: 'Relation Management',
    external: 'External',
    local: 'Local',
    fileServerID: 'File Server ID',
    modules: 'Modules',
    ip: 'IP',
    monitor: 'Monitor',
    worker: 'Worker',
    createFileServer: 'Create a File Server',
    saveIdPort: 'SlaveId Port',
    saveId: 'SlaveId',
    port: 'Port',
    salesName: 'Sales Name',
    region: 'Region',
    salesAccount: 'Sales Account',
    salesForceName: 'Sales Force Name',
    englishText: 'English Text',
    chineseText: 'Chinese Text',
    pageMark: 'Page Mark',
    Aspect: 'Aspect',
    LastFrame: 'Last Frame',
    LastFrameSeconds: 'Last Frame lasts for N seconds',
    AudioChannels: 'Audio channels',
    Output12: 'Output-12db audio while standing by',
    AsfFrameFroze: 'AsfFrameFroze',
    Encoder: 'Encoder',
    Resolution: 'Resolution',
    Port: 'Port',
    VBitrate: 'VBitrate',
    ABitrate: 'ABitrate',
    AChannel: 'AChannel',
    Channel: 'Channel',
    SetMediaDevice: 'Set Media Device',
    Microphone: 'Microphone',
    Speaker: 'Speaker',
    IPAndPort: 'IP and Port Mapping',
    External: 'External',
    Local: 'Local',
    WebPort: 'Web Port',
    StreamPort: 'Stream Port',
    AutoConvert: 'Auto convert to',
    VisionTag: 'Vision Tag Settings',
    Language: 'Language',
    Landmarks: 'Landmarks',
    Celebrities: 'Celebrities',
    Adult: 'Adult',
    Faces: 'Faces',
    VisualFeatures: 'Visual Features',
    Settings: 'Settings',
    Encapsulation: 'Encapsulation',
    Framesrate: 'Frame rate',
    TimeElapsed: 'Time Elapsed',
    EncoderPid: 'Encoder Pid',
    TimeLock: 'Time Lock',
    GOP: 'GOP',
    FileSize: 'File Size',
    StartedAt: 'Started At',
    Deinterlace: 'Deinterlace',
    ProgramID: 'Program ID',
    Samplerate: 'Samplerate',
    OutputPath: 'Output Path',
    FileName: 'File Name',
    FullName: 'Full Name',
    Status: 'Status',
    VideoBitrate: 'Video Bitrate',
    FPS: 'FPS',
    show: 'Show',
    other: 'Other',
    tvucc: 'TVUCC',
    hide: 'Hide',
    editMenu: 'Edit Menu',
    addMenu: 'Add Menu',
    menuSort: 'Sort',
    dragSort: 'Drag menus below to adjust their order',
    sureRemove: 'Are you sure you want to remove it?',
    isModifyBookmarkName: 'Would you like to modify this bookmark name?',
    menu: 'Menu',
    orderNo: 'OrderNo',
    feature: 'Feature',
    menuError: 'The menu cannot be empty',
    enNameError: 'The enName cannot be empty',
    cnNameError: 'The cnName cannot be empty',
    dataMarkError: 'The dataMark cannot be empty',
    typeError: 'The type cannot be empty',
    enableError: 'The enable cannot be empty',
    remarkError: 'The remark cannot be empty',
    orderNoError: 'The rderNo cannot be empty and can only be a number',
    adminAutoOwnError: 'Admin Auto Own cannot be empty and can only be a number',
    FeatureList: 'Feature List',
    MailTemplate: 'Mail Template',
    adminAutoOwn: 'Admin Auto Own',
    addFeature: 'Add Feature',
    editFeature: 'Edit Feature',
    setTranscriber: 'Transcriber Settings',
    transcriberDelay: 'Transcriber Delay(s)',
    video: 'Video',
    languageSelection: 'Language Selection',
    saveSRT: 'Save SRT Files To',
    activatedSystem: 'Actived System',
    scheduledTime: 'Scheduled Time',
    applyBtn: 'Apply',
    displayLines: 'Display Lines',
    charactersPerLine: 'Characters Per Line',
    ClearUppercase: 'Clear',
    captionType: 'Caption Type',
    Profranity: 'Profranity',
    autoMuteWords: 'Auto Mute Words',
    Timed: 'Timed',
    enableTVUTranscriber: 'Enable TVU Transcriber',
    bitrateDelay: 'Please input number between {0} ~ {1}',
    Enabled: 'Enabled',
    showMore: 'Show More',
    deviceList: 'Device List',
    accepted: 'Accepted',
    denied: 'Denied',
    joinTime: 'Join Time',
    introduction: 'Introduction',
    expired: 'expired',
    rejected: 'Denied',
    setExpired: 'Mark as expired',
    deny: 'Deny',
    accept: 'Accept',
    existName: 'Token name already exists',
    tokenNameEmpty: 'Token Name is required',
    expiredTimeEmpty: 'Time cannot be empty',
    shareTo: 'Share to',
    userAccount: 'User account',
    members: 'members',
    notSpecialChar: 'The device name cannot contain special characters',
    // Start && stop live code
    SourceNotBelongToUser: 'Source not belong to user.',
    ReceiverNotBelongToUser: 'Receiver not belong to user.',
    UserRoleNoFeature: 'No permission.Please contact your Command Center account administrator or TVU Support for help.',
    receiverOnline: 'Receiver online',
    GivenPeerNotSelected: 'Parameter error',
    featuresEffect: 'The change of features will take effect after restart',
    enditFailed: 'Edited failed',
    requestFailed: 'Request failed',
    setLocationTips: 'Please Set Receiver Location',
    invalidUrl: 'Ivalid Url',
    supportManager: 'Support Manager',
    systemAdmin: 'System Admin',
    featureError: 'The feature cannot be empty',
    autoOwnError: 'The autoOwn cannot be empty',
    urlError: 'The url cannot be empty',
    accessPage: 'Access to Pages',
    account: 'Account',
    support: 'Support',
    control: 'Control',
    autoOwn: 'AutoOwn',
    currentRLiveSourceNotBelongToUser: 'Receiver current living source not belong to user',
    isBooking: 'The receiver is executing a reservation event',
    mailAddrTips: 'Please input a valid email address',
    emailTips: 'What do you want to say?',
    operationFailure: 'Operation failure',
    noTokenPrompt: 'Token is a more efficient way to facilitate the pairing of TVU devices',
    operationFailed: 'Operation failed, please try again!',
    tokenItems: 'Total Tokens',
    searchDevicePrompt: 'Please input at least 3 characters to filter.',
    timeError: 'The expiration time is less than the current time',
    type: 'Type',
    bitrate: 'Bitrate',
    receiverNotConnect: 'Receiver not connect',
    notHaveStartauthor: 'No Start Live permission.Please contact your Command Center account administrator or TVU Support for help.',
    notHaveStoptauthor: 'No stop live permission.Please contact your Command Center account administrator or TVU Support for help.',
    notHaveVoipauthor: 'VoIP access not permitted. Please contact your administrator or TVU support for assistance.',
    // My Receiver Page
    rinputDescrible: 'Enter memo',
    sourceFrom: 'Source From',
    newMyRecevierInputTitle: 'Video or Destination',
    newMyRecevierSort: 'Sort by status',
    receiverDataIsEmpty: 'Receiver data empty',
    recevierMyFavorite: 'My Favorite',
    addFavoriteSuccess: 'Add to favorite success.',
    EnterSourceNamePeerID: 'Enter Source Name/PeerID',
    notSupportFunction: 'Not support this operation',
    // Mail Template
    params: 'Params',
    TimeRemaining: 'Time Remaining:',
    StorageRemaining: 'Storage Remaining:',
    createMailTemplate: 'Create Mail Template',
    editMailTemplate: 'Edit Mail Template',
    emptyURLCheck: 'Please enter template URL',
    validURLCheck: 'Please enter the template URL that exists and can be accessed by the service.',
    emptyNameCheck: 'Please enter template name',
    emptyTypeCheck: 'Please select template type',
    emptyParamsCheck: 'Please select template params',
    tokenExpired: 'Expired',
    invalidTime: 'Invalid time',
    uploadExcel: 'Export to Excel',
    uploadExcelAll: 'Export All',
    // new home
    dynamicsorting: 'Dynamic Sorting',
    serverFailed: 'Server exception',
    modeSwitching: 'Mode switching, please operate later',
    anywhereTimeout: 'Anywhere connection timed out, please check device settings or contact technical support',

    // new multiview
    cannotSwitchSplitScreenMode: 'Your current video number is larger than you want to switch the split-screen mode, can not switch!',
    rVerionNeedV56Above: 'The receiver software requires to be v5.6 or above.',
    showInfo: 'Show Info',
    AddToCall: 'Add to call',
    startToCall: 'Start the call',
    selectMonitorSelectCall: 'Only TVUPack and TVU Anywhere support VoIP.',
    selectObjCall: 'Please select which you want to establish the voice call with.',
    setVideoFeedbackTrueViewVideo: 'Video preview is disabled. Please enable it or contact TVU support for assistance.',
    setVideoFeedbackTrueViewVideoMoreLive: 'Max number of previews - 5 has been reached. Please turn off other preview windows first.',
    continueCall: 'Continue call',
    interruptAllCall: 'Would you like to end the active calls?',
    interruptAll: 'Hang up',
    changeOwner: 'Change Owner',
    belongTo: 'Belong to',
    alreadyBelong: 'Customer user already belong to admin user',
    startTimeLessThanCurTime: 'Start time is less than the current time',
    stopTimeLessThanCurTime: 'End time is less than the current time',
    selectData: 'Select date and time',
    enterStartTime: 'Please enter the start time',
    enterEndTime: 'Please enter the end time',
    enterDescription: 'Please enter the event description',
    selectOwner: 'Please select an administrator account',
    switchToWeek: 'Please switch to the week view',
    extMustSetUrl: 'The ext OR sdiin source must set url',
    magnifying: 'Zoom +',
    faildCallTpcModifyName: 'failed to call TPC to modify name!',
    scte: 'scte',
    scteCaptial: 'SCTE',
    trigger: 'Trigger',
    addScte: 'Add SCTE',
    spliceType: 'Splice Type',
    prerollTime: 'Preroll Time',
    nameEmpty: 'Name connot be empty',
    scteName: 'SCTE Name',
    bookingEvents: 'Booking Events',
    runningEvents: 'Running Events',
    pastEvents: 'Past Events',
    createTime: 'Create Time',
    deviceSourceScte: 'Source',
    Parameters: 'Parameters',
    file: 'File',
    addfile: 'Add File',
    addFolder: 'Add Folder',
    Folder: 'Folder',
    selectAFolder: 'Select a folder',
    addMedia: 'Add Media',
    addvideo: 'Add Local Video',
    editfile: 'Edit File',
    editEmail: 'Edit Email',
    fileName: 'File name',
    bookingImportFile: 'Import Excel or CSV files to add in batches.',
    bookingImportFileTemp: 'View file template.',
    search: 'Search',
    bookingChannel: 'Channel',
    bookingDeleteFail: 'Some file is being used by an event and cannot be deleted',
    bookingEditFail: 'Some events are using this file and cannot be modified',
    durationEmpty: 'Duration connot be empty.',
    durationType: 'Duration need enter a positive integer',
    nameIsExist: 'The name already exist ',
    durationUnit: '( s )',
    upload: 'Upload',
    proccessing: 'Proccessing...',
    uploadVideoLocal: 'Upload local video file',
    uploadHasSame: 'The file you selected has already been uploaded',
    selectAVideo: 'Please select a video to add.',
    videoExist: 'Video already exists',
    fileNeedType: 'Upload files can only be in xls, xlsx, csv format!',
    videoNeedType: 'Upload videos can only be in ts,rm,rmvb,wmv,wtv,avi,3gp,mkv,mpg,video/mpeg,video/mp4,mov format!',
    noDevice: 'No Device',
    readonly: "video type or scte type can't change end time",
    sctePrerollTime: 'PrerollTime length can not greater than 5',

    // playout
    fileNotformat: 'The file content format is incorrect',
    editWarning: 'Live is about to start. Are you sure you want to change it?',
    mediaFolder: 'Media',

    // alert
    offlined: 'went offline',
    onlined: 'came online',
    startLiveTip: 'started live transmission',
    stopLiveTip: 'stopped live transmission',
    noAvailable: 'N / A',

    // setting
    deviceNameBytes: 'Device name can not exceed 15 bytes.',
    instanceId: 'Instance id',
    rId: 'Receiver ID',
    Ip: 'IP',
    creater: 'Starter',
    stopR: 'Stop Receiver',
    stopTime: 'Shutdown time',
    unAssigned: 'Unassigned',
    shutdownP: 'Closer',
    ClosedCloudR: 'Closed',
    Shutdown: 'Shut-down',
    launchTime: 'Launch',
    ShutdownTime: 'Shut-down Time',
    scheduleTime: 'Schedule Time',
    scheduleShutdownTime: 'Schedule Shut-down Time',
    scheduleStartTime: 'schedule start-up time',
    usageType: 'Usage Type',
    selectUsageType: 'Please select the usage type',
    selectInstanceType: 'Please select the instance type',
    startUpCloudR: 'Start-up Cloud Receiver',
    ClosedBySupport: 'Closed By Support',
    ScheduleFeature: 'Scheduled feature',
    ClosedBySystem: 'Closed By System',
    canUseArea: 'Available area',
    selectArea: 'Please select a region you want to start',
    selectNumOfMachine: 'Please select a number of Rs required by the virtual machine you want to start',
    selectProject: 'Please select project type',
    noRegion: 'There is no available region under the current type, please select other',
    connectTpcFail: 'Connect TPC failed',
    connectInstanceFail: 'Connect Instance-service failed',
    getRegionFail: 'Get active region failed',
    createInsFail: 'Create instance failed',
    shutdownInsFail: 'Shutdown instance failed',
    userInsnull: 'User instance is not exist',
    userInsreadyCLose: 'User instance already closed',
    userExist: 'User already exist in Command Center',
    confirmShutDown: 'Are you sure you want to shut it down?',
    confirmDeleteSchedule: 'Are you sure you want to delete this schedule Cloud R?',
    virtualRDes: 'Virtual cloud R',
    needRNum: 'Number of Rs required by the virtual machine',
    emailNotice: 'Email Notification When Cloud Receiver Starts',
    projectType: 'Project Type',
    replaceEmail: 'Replace Email',
    delelteUser: 'Delete',
    editDeviceNameFaild: 'Edit T device name is peer service failed',
    addNewBookmark: 'Add to a new bookmark',
    includeInBookmark: 'include in My Bookmark',
    bookmarksBar: 'Please Select Bookmarks Bar',
    offTime: 'Off Time',
    offTimeNotEmpty: 'shut-down time connot be empty',
    hebdomad: 'Hebdomad',
    thirtyDays: 'Thirty Days',
    deviceShutdownDate: 'The equipment shutdown time is',
    receiverPidOrName: 'Receiver PeerID or name',
    gridOwner: 'TVU Grid Owner',
    createEvent: 'Create Event',
    Reload: 'Reload',
    reloadConfirm: 'Reloading receivers and sources of this account will need some time. Do you still want to continue?',
    reloadPageConfirm: 'Reloading receivers and sources of current page will need some time. Do you still want to continue?',
    deviceReloadConfirm: 'Reloading this receiver will need some time. Do you still want to continue?',
    deviceReloadPageConfirm: 'Reloading the receivers of current page will need some time. Do you still want to continue?',
    switchDeviceSourcesOld: 'Switch to Previous Auth Method to Allow Authorizing Sources',
    switchDeviceSourcesNew: 'Switch to New Receiver-centric Authorization',
    // 定制化CC
    manualChannel: 'Manual Channel',
    channelPool: 'Channel Pool',
    channelSetting: 'Channel Setting',
    searchChannel: 'Search Channel',
    autoType: 'Mode',
    occurType: 'Type',
    occupy: 'Occupy',
    idle: 'Idle',
    unUseable: 'Unusable',
    channelName: 'Name',
    channelType: 'Channel Type',
    occupyStatus: 'Occupy Status',
    groupName: 'Group Name',
    channelSource: 'Source',
    deviceStatus: 'Device Status',
    occupyUser: 'occupyUser',
    occupyTime: 'occupyTime',
    currentChannel: 'Current Channel',
    alreadyUse: 'Already in use',
    availabelUser: 'Available channel',
    allChannel: 'Total',
    offlineChannel: 'Offline',
    address: 'Address',
    outputAddress: 'Output Address',
    channelGroup: 'Channel Group',
    selectSocial: 'Search output address',
    pushSuccess: 'Push success',
    stopPushSuccess: 'Stop push success',
    destinationChannel: 'Destination',
    groupEmpty: 'Channel Group cannot be empty',
    searchGroup: 'Search Group',
    defaultGroup: 'Is Default',
    addGroup: 'Add Group',
    inputGroup: 'Please input group name',
    editGroup: 'Edit Group',
    set_groupName: 'The group name cannot be empty',
    changeGroup: 'Change Group',
    noGroup: 'Please select a group',
    setAutoBtn: 'Set to channel pool',
    setManualBtn: 'Set to manual channel',
    groupExist: 'Group exist',
    defaultNotDelete: 'default group cannot delete',
    existDeviceGroup: 'Group exist device, cannot delete',
    eventNoExist: 'Event not exist',
    sourceNoExist: "Source doesn't exist",
    noIdleChannel: 'There is no available live channel',
    groupNotExist: "Group doesn't exist",
    userNoGroup: 'Group not belong user',
    deviceNotT: 'Device not T',
    registFailed: 'Regist device failed',
    accountSharing: 'This account is streaming now',
    accountNoSharing: "Current account doesn't sharing",
    accountNoExist: "Streaming account doesn't exist",
    systemError: 'System error',
    pushFailed: 'Share video failed',
    stopPushFailed: 'Stop share video failed',
    // 其他
    shareToeknSelect: 'Select',
    shareBy: 'Shared by',
    noPairDestiation: 'There is no transceiver paired with the selected source under the current group',

    waitCalled: 'Waiting UGC',
    channelOccupied: 'Exist channel occupied',

    // multiview plus

    workspace: 'Workspace',
    currentDevice: 'Current Device',
    workspaceList: 'Workspace List',
    newWorkspace: 'New Workspace',
    enternewWorkspace: 'Enter new workspace',
    plzSelectLayout: 'Please select layout.',
    plzEnterspaceName: 'Please enter workspace name.',
    duplicate: 'Duplicate',
    deleteSpace: 'Are you sure you want to delete this workspace?',
    BacktoView: 'Back to View',
    ManageWorkspace: 'Manage Workspace',
    addTo: 'Add to',
    moveTo: 'Move to',
    copyTo: 'Copy to',
    newWorkSpace: 'New Workspace',
    moveSuccess: 'Move Success',
    copySuccess: 'Copy Success',
    addSuccess: 'Add Success',
    deleteSuccess: 'Delete Success',
    deleteInfo: 'Are you sure you want to remove this device from current workspace?',
    ManageDevices: 'Manage Devices',
    showDesc: 'Show Description',
    mvpRecevierSort: 'Sort by name',
    userNotExist: 'User not exist in CC',
    emailExistInCC: 'Email exist in CC',
    emailExistInDB: 'Email exist in dashboard',
    USUpdateFailed: 'UserService update email failed',
    DBUpdateFailed: 'Dashboard update email failed',
    tomultiview: 'Go Multi-view',
    tomultiviewplus: 'Go Multi-view-plus',
    Rename: 'Rename',
    Enter: 'Enter',
    Duplicate: 'Duplicate',
    dragAndDrop: 'Drag and drop to the workspace',
    overMax: 'over the maximum quantity',
    loadMore: 'Load more',

    // Router Monitor
    tvuRouterMonitor: 'TVU Router Monitor',
    curSession: 'Current Session',
    totalUpload: 'Total Upload',
    totalDownload: 'Total Download',
    totalUsage: 'Total Usage',
    onlineTime: 'Online Time',
    numOfClients: 'Number of Clients',
    countDownPrompt: 'Click again after 5 seconds',
    selectSource: 'Please select a aource',
    agreement: 'Agreement',
    lastLogin: 'Last Login',
    goWebR: 'Go To WebR',
    delayRang: 'Delay input rang: Only can be number, from 0.5s to 50s',
    overDevice: 'layout less than the number of device',
    tcpReportCount: 'Heartbeat',

    thumbnail: 'Thumbnails',
    liveBitrate: 'Live Bitrate',
    latency: 'Latency',
    selectBookmark: 'Please select a bookmark',
    goLive: 'Go Live',
    largeSize: 'Large',
    mediumSize: 'Medium',
    smallSize: 'Small',
    typeVal: 'Type',
    workspaceName: 'workspace name',
    nameLessThan: 'The name length is less than or equal to',
    thumbnailObj: 'Thumbnail',
    second: 'sec',
    sourceNoHaveR: 'There is no R available to change source',
    GLink: 'GLink',
    RTIL: 'RTIL',
    voipStart: 'VoIP Start With Live',
    RestartService: 'Restart Service',
    Connectivity: 'Connectivity',
    cardName: 'Card Name:',
    inputFormat: 'Input Format:',
    outputFormat: 'Output Format:',
    inputFor: 'InFormat',
    outputFor: 'OutFormat',
    tranSmission: 'Transmission Parameters',
    durationTime: 'The end time can only be {time} minutes longer than the start time',
    modeTypeEmpty: 'mode cannot be empty',
    liveDontOption: 'The live operation does not support this operation',
    offSourNotReal: 'Offline devices can only select history mode',
    networkBad: 'The network is not good, please refresh the browser',
    nameExit: 'Duplicate name',
    creatName: 'Account email cannot use @tvunetworks.com',
    Preference: 'Preference',
    stopLiveConfirm: 'Are you sure you want to stop this live transmission?',
    stopLiveCheck: "Don't remind me next time",
    preferenceTitle: 'Transmission',
    stopAlertMid: 'Stop a live transmission with confirm',
    stopAlertRight: 'Stop a live transmission',
    startAlertMid: 'Start a live transmission',
    showLocation: 'Show Geolocation over Thumbnails',
    rightAlert: 'Allow Pop-up Notification',
    alertRight: 'New message alert for the Alert page',
    accountURL: 'Account/URL',

    // user behavior
    UserBehavior: 'Operation Log',
    ubcc: 'Command Center',
    ubpp: 'Producer',
    ubchannel: 'Channel',
    ubnodata: 'No Data',
    ubtimeOut: 'Request timed out.',
    operationDescription: 'Operation Description',
    operationParams: 'Operation Params',
    operationTime: 'Operation Time',
    localTime: 'Local Time',
    UTCTime: 'UTC Time',
    userAgent: 'Browser Information',
    ubemailPlace: 'Email (Separate searches with “,”)',
    ubAccept: 'accepted',
    ubRejected: 'rejected',
    ubDeleted: 'deleted',
    ubStartLive: 'Start live',
    ubStopLive: 'Stop live',
    ubSetDelayAndBitrate: 'Set delay and bitrate',
    ubSetIPAndPort: 'Set IP and Port Mapping',
    ubSetSDIoutput: 'Set SDI output',
    ubShare: 'Share',
    ubStopShare: 'Stop share',
    ubDownloadRecord: 'Download record',
    ubEditMemo: 'Edit memo',
    ubRequestToken: 'Request Token',
    ubAcceptRequest: 'Accept request',
    ubEditDeviceName: 'Edit device name',
    ubEditToken: 'Create Token',
    ubSendEmail: 'Send email',
    ubSetDeviceLocation: 'Set device location',
    ubdStartLive: 'Source (XXX) has started live with receiver (XXX)',
    ubdStopLive: 'Source  has stopped live with receiver (XXX)',
    ubdSetDelayAndBitrate: 'Receiver (XXX) has set delay to XXX s and bitrate to XXX kb/s at page XXX',
    ubdSetIPAndPort: 'Setted the IP and port of receiver "XXX"',
    ubdSetSDIoutput: 'Setted the SDI output of receiver "XXX"',
    ubdShare: 'Receiver (XXX) has shared the live stream to XXX by XXX: resolution XXX bitrate XXX',
    ubdStopShare: 'Receiver (XXX) has stoppped live streaming to XXX by XXX.',
    ubdDownloadRecord: 'Receiver (XXX) has downloaded file XXX',
    ubdEditMemo: 'Device (XXX) has been added alias XXX',
    ubdAcceptRequest: 'TVUAnywhere (XXX) token pairing request has been ',
    ubdRequestToken: 'TVUAnywhere (XXX) has made a pairing request by scanning the token code XXX',
    ubdEditDeviceName: 'Device (XXX) has been renamed to XXX',
    ubdEditToken: 'Token XXX has been changed to: Expiration time XXX. Included devices XXX',
    ubdSendEmail: 'Token XXX has been emailed to XXX',
    ubdSetDeviceLocation: 'Device (XXX) has been set new location to XXX',

    All: 'All',
    Receiver: 'Receiver',
    pack: 'TVUPack',
    anywhere: 'TVUAnywhere',
    gridSdi: 'TVU Grid-SDI',

    // 新增anywhere
    manPanTitle1: 'Drag the mouse to the left video area',
    manPanTitle2: 'to adjust the position of the character on the screen',
    autoPanTitle1: 'Automatically recognize faces',
    autoPanTitle2: 'keep people The object is always in the center of the screen',
    autoSelectQuality: 'Auto',
    detailTemp: 'Temp',
    detailUsage: 'Usage',
    // profile
    profile: 'profile',
    OpenPath: 'Open Path',
    enterID: 'Please enter program id',
    selectProfile: 'Please select profile',
    ProfileAdd: 'Add',
    ProfileBack: 'Back',
    ProfileName: 'Profile Name',
    SetEncoderFail: 'Set Encoder Model Fail',
    ProgrameNum: 'Programe ID can only be numbers',
    ConfirmRecording: 'Do you want to delete this recording setting profile?',
    RecordingPrompt: 'Prompt',
    ProfileNameMust: 'Please input Profile name',
    EditSuccess: 'Edit Success',
    CreateSuccess: 'Create Success',
    SampleRate: 'Sample Rate',
    Tune: 'Tune',
    AudioEncoder: 'Audio Encoder',
    Preset: 'Preset',
    Level: 'Level',
    Scale: 'Scale',
    ProfileAuto: 'Auto',
    GlobalQuality: 'Global Quality',
    VideoEncoder: 'Video Encoder',
    Muxer: 'Muxer',
    VideoCodec: 'Video Codec',
    AudioCodec: 'Audio Codec',
    hasProfileName: 'The profile name already exists, please re-enter.',
    // TokenService&VLAN
    tokenService: 'Token Service',
    creatNewToken: 'Create a new Token',
    ReceiverName: 'Receiver Name',
    ReceiverID: 'Receiver ID',
    ReceiverIP: 'Receiver IP',
    ReceiverPort: 'Receiver Port',
    ReceiverTimeSpan: 'Receiver Time Span',
    ReceiverEmail: 'Receiver Email',
    TransmitterName: 'Transmitter Name',
    TransmitterPID: 'Transmitter PID',
    TransmitterEmail: 'Transmitter Email',
    SaveAs: 'Save As...',
    SetAToken: 'Set a Token',
    CheckTokenInfo: 'Check Token Info',
    SetToken: 'Set Token',
    TokenMessage: 'Token Message',
    ShouldMailedTo: 'Should be mailed to:',
    CopyEmail: 'Copy Email',
    TakeNextToken: 'Take next Token',
    TransmitterValidTime: 'Transmitter Valid Time',
    CreateTime: 'Create Time',
    ExpiredAt: 'Expired at',
    SetValidTime: 'Set Valid Time',
    SureSetNoTime: 'Do you sure about setting the Token valid time to 0?',
    SureDeletePeer: 'Please confirm if you want to delete peer ',
    VLANSetting: 'VLAN Setting',
    IPServicePort: 'IP and Service Port',
    Interface: 'Interface',
    IPMethod: 'IP Method',
    MACAddress: 'MAC address',
    IPAddress: 'IP address',
    Mask: 'Mask',
    Gateway: 'Gateway',
    RouterServicePort: 'Router Service Port',
    StopInterface: 'Stop Interface',
    RouterClientList: 'Router Client List',
    DeleteToken: 'Delete Token',
    SelectInterface: 'Select Interface',
    EnterRouterPort: 'Enter Router Service Port',
    NoClients: 'No Clients',
    SureBeUsed: 'will be used for VLAN tunnel server and could not be used for regular live transmission or internet access. Are you sure',
    StartRouterFail: 'Start Router Fail',
    StopRouterFail: 'Stop Router Fail',
    SaveSuccess: 'Save success',
    SaveFail: 'Save Fail',
    PeerName: 'Name',
    IPAdress: 'IP Address',

    RoleManagement: 'Role Management',
    roleName: 'Role Name',
    accounts: 'Accounts',
    devices: 'Devices',
    inputRoleName: 'Role name cannot be empty',
    inputPermission: 'Permission cannot be empty',
    editRole: 'Modify',
    createRole: 'Create Role',
    editeRole: 'Edit Role',
    deleteAdmin: 'Are you sure you want to remove the Admin account?',
    deleteIncluld: 'Inculde its sub-accounts.',
    userAccountTip: 'User account',
    selectAccount: 'Please select user account',
    deleteAccount: 'Are you sure you want to remove this account? ',
    deletetipsPre: 'There are',
    deletetipsLast: 'accounts under this role.Do you still want to delete it?',
    roleNotExist: 'This role name is exist!',
    preTipsUp: 'Are you sure you want to upgrade the account type from ',
    preTipsDown: 'Are you sure you want to downgrade the account type from ',
    afterTipsUp: ' to ',
    afterTipsDown: ' to ',
    // Haran新增Transcriber
    TranscriberText: 'Transcriber',
    Repeat: 'Repeat',
    endRepeat: 'End Repeat',
    halfHour: 'Half an hour',
    anHour: 'An hour',
    allDay: 'All day',
    TranscriberBooking: 'Create Transcriber Booking',
    EndtimeEmpty: 'End time cannot be empty',

    switchSuccess: 'Switch area success,please restart R service',
    removeShare: 'Are you sure to remove the share?',
    parentBookmark: 'Parent\'\s Bookmark',
    otherBookmark: '\'\s Bookmark',
    allAdded: 'All accounts have been added',

    myBookmark: 'My Bookmark',
    // parentBookmark: 'Parent Bookmark',
    startAll: 'Take all',
    stopAll: 'Stop all',
    bookmarks: 'Bookmarks',
    dragHere: 'Drag source here for device',
    searchReceivers: 'Search \'Receivers\'',
    searchIn: 'Search in',
    warningTitle: 'Warning',
    Reboot: 'Reboot Device',
    RestartBtn: 'Restart',
    RebootBtn: 'Reboot',
    ifRestartService: 'Restart the service may take some time. Do you still want to restart it?',
    ifRebootR: 'Reboot the device may take some time. Do you still want to reboot it?',
    alreadyShare: 'Current account has been shared',
    PairedUnits: 'Paired Units',
    pairedDeviceName: 'Device Name:',
    Update: 'Update:',
    // TimeLock相关
    MainSource: 'Main time source',
    removeMainRTips: 'Warning: once the main time source is removed, the entire TimeLock session will be ended.',
    serverTimeLocking: 'Time locking(Server)',
    clientTimeLocking: 'Time locking(Client)',
    otherOccur: ': this device is already under multi-control with user {userEmail}',
    TimeLockTitle: 'Time Lock',
    failedDevice: 'Device {device} failed to sync.',
    pendingDevice: ' Timelock status unknown for devices {device}.',
    serverIPTiltle: 'Server Device NTP',
    InternalIp: 'Internal IP/Port:',
    ExternallIp: 'External IP/Port:',
    TimeService: 'Time Service',
    ReleaseTimeLock: 'Release Time Lock',
    StillRemove: 'Do you still want to remove it?',
    StillRelease: 'Do you still want to release them?',
    OneReceiverTip: 'TimeLock session will be closed since there’s only one receiver left.',
    ReceiverUsingTimeLock: 'receiver is currently in the TimeLock mode.',
    ThereAre: 'There are',
    CreatedBy: 'Created by',
    At: 'at',
    NullIp: 'Receiver local IP address hasn\'\t been configured in the local configuraton file.',
    sameMachineTip: 'In cases of DualR or QuadR, only one R instance is required to be added to TimeLock.',
    noTimeLockFeature: 'TimeLock feature is not enabled in this device.',
    failedGetIp: 'Getting IP/Port info failed.',
    NotDevice: 'Device does not exist',
    NotTR: 'Device must be a Pack or a Receiver',
    MoreThanTwo: 'The number of receivers must be greater than two',
    AccountSynchronizing: 'Your account is Synchronizing',
    // deviceSynchronizing: 'Exist device synchronizing'
    IpSynchronizing: 'Exist intranet ip synchronizing',
    failedGetFeature: 'Verifying TimeLock feature failed.',
    notSynchronizing: 'Not synchronized. ',
    failedGetMainR: 'Failed to get main R with the time service.',
    mainRcantRemove: 'Main R with the time service cannot be removed.',
    syncSuccess: 'Synchronization success',
    removeSyncSuccess: 'Synchronization removal success',
    sourcePeerId: 'Source PeerID',
    noPermission: 'Sorry, you do not have permission to remove.',
    otherOccurDevices: 'are currently time locked by user',
    askUsersRelease: 'Please ask these users to release the receivers before proceeding.',
    askUserRelease: 'Please ask this user to release the receivers before proceeding.',

    // ecoPreview
    ecoPreviewBtn: 'Eco-Preview',
    exitPreviewBtn: 'Exit-Preview',
    editTime: 'Edit Timer',
    previewSetting: 'Economy Preview-Timer Setting',
    timer: 'Timer',
    laterPreview: ' mins later to auto-exit preview.',
    remainPreview: 'remain in preview mode until manually exit.',
    visitWebR: 'Visit WebR',
    refreshIP: 'Refresh IP mapping',

    pictureSize: 'Picture size must be smaller than 480×640',
    offsetX: 'X offset',
    offsetY: 'Y offset',

    // transcribre
    CaptionPosition: 'Caption Position:',
    Lower: 'Lower',
    Upper: 'Upper',
    wordReplace: 'Enable Word Replacement:',
    ManageTxt: 'Manage',
    speakerRecogition: 'Speaker Recognition:',
    onlySupportEng: 'Only supports reconition of English language when this feature enabled.',
    scheduleTimeTxt: 'Scheduled time:',
    MayInterruptLive: 'Enabled Transcriber or delay changes may interrupt live, please confirm to continue setting?',
    SetSmartCaption: 'Set smartCaption',
    lowerAdd: 'add',
    startTimeEmpty: 'Please select start time',
    endTimeEmpty: 'Please select end time',
    CaptionFontFamily: 'Caption font family',
    wordReplacement: 'Word Replacement',
    OriginalWord: 'Original Word',
    ReplacedWord: 'Replaced Word',
    PleaseInputWord: 'Please input word',
    outputStreaming: 'The output is streaming, please stop first',
    encoderOneNDI: 'Each encoder can only have one NDI',

    ExternallOnlyIp: 'External IP',
    ExternallPort: 'External Port',
    LocalIP: 'Local IP',
    LocalPort: 'Local Port',
    controlAndPort: 'Web Control & Stream Port',
    restartToEffect: 'Please restart the receiver service for the setting to take effect',
    customLogo: 'Custom Logo',
    videoBitDepth: 'Video bit depth',
    stayLastFrame: 'Stay on last frame during transmission interruption',
    VLANClientInfo: 'VLAN Client Info',
    VLANInterface: 'VLAN Interface:',
    VLANMAC: 'VLAN MAC:',
    IPLabel: 'IP:',
    MASKLabel: 'MASK:',
    VLANServerInfo: 'VLAN Server Info',
    ServerIPHost: 'Server IP or Host',
    PortLabel: 'Port:',
    connectServer: 'Connection to the VLAN Server',
    ConnectedDevices: 'Connected Devices',
    VLANClient: 'VLAN Client',
    VLANServer: 'VLAN Server',
    ConnectBtn: 'Connect',
    beUserdVLAN: ' will be used for VLAN tunnel server and could not be used for regular live transmission or internet access. Are you sure',
    MainLevel: 'Main Level',
    IPAndRPort: 'Receiver IP & Port',
    getIP: 'Get IP',
    InputContent: 'please input content',
    gridDescriptionRight: 'Open Event description popup when start/stop live',
    limitBelow: 'Please keep the image below {0} kb',

    updateSlugFailed: 'Update description failed',
    gridTitle: 'Event',

    byChosen: 'By Chosen Order',
    byName: 'By Name',
    byStatus: 'By Status',

    Advanced: 'Advanced',
    AdvancedMore: 'Advanced...',
    bufferSize: 'Receive Buffer Size',
    connectTimeout: 'Connect Timeout',
    rwTimeout: 'RW Timeout',
    passphrase: 'Passphrase',
    additional: 'Additional',
    shareFeedBack: 'Share Your Feedback',

    sameExtName: 'This Ext already exists, duplicate of {name} source',
    realTrackCantChange: 'Switching language in real time mode may cause track deviations. Please use it carefully.',
    startNewEvent: 'Are you sure you want to start a new event?',
    replaceEvent: 'Are you sure you want to start a new event? The current event will be stopped',
    stopEvent: 'Are you sure you want to stop this event?',
    updateEvent: 'Are you sure you want to update the current event’s settings?',
    SDIFormat: '3G-SDI formats',
    ReceiverLiveWithOther: 'Would you like to start LIVE with the new source now?',
    /* General */
    EasternTime: 'Eastern Standard Time',
    NTPService: 'Custom NTP Service',
    NTPContent: 'NTP uses system services by default. You can<br />turn on the Custom NTP Service to change it. <br />Please note that if the device has enabled<br />Time lock, NTP will do not take effect.',
    DomainName: 'IP/ Domain name',
    NTPOccupy: 'This device has enabled Timelock, the time will be based on {name}',
    stopTips: 'Prompt a Warning When Stopping a Live Transmission',
    defaultDelay: 'Default Delay and Bitrate',
    switchRegionTips: 'Switching region will require restarting the receiver service.',
    confirmSwitch: 'Yes, I want to switch',
    NTPEnterTips: 'Please enter the NTP Server IP/domain name and port',
    DelaySecond: 'Delay (second)',
    BitrateKbps: 'Bitrate (kbps)',

    TryNewUI: 'Try New UI',
    // Passthrough Streaming
    passThroughStream: 'Passthrough Streaming',
    Protocol: 'Protocol',
    Mode: 'Mode',
    Caller: 'Caller',
    Listener: 'Listener',
    ListenerPortTip: 'Listener port range must be from 60000 to 65000',
    IpNotEmpty: 'IP can not be empty',
    portNotZero: 'Port can not be 0',
    delayTip: 'SRT delay range must be from 20ms to 8000ms',
    // Remote File Storage
    remoteFileStorage: 'Remote File Storage',
    DestinationName: 'Destination Name',
    accessKeyId: 'Access Key ID',
    parallelThread: 'Parallel Thread',
    secretAccessKey: 'Secret Access Key',
    dataConnType: 'Data ConnType',
    autoPassive: 'Auto Passive',
    cannotEmpty: 'Can not input empty value',
    destinationManage: 'Destination Management',
    keyOrUserName: 'Key ID/User Name',
    keyPassword: 'Key/Password',
    uploadPreferences: 'Upload Preferences',
    destinationManagemention: 'Destination Managemention',
    uploadDestination: 'Upload Destination',
    deleteAfter: 'Delete After Upload',
    remoteDirectory: 'Remote Directory',
    uploadRecord: 'Upload Record',
    uploadModule: 'Module',
    retry: 'Retry',

    FollowLive: 'Follow Receiver Live/Stop',

    preAssignPid: 'Pre-assign PID',
    durationHour: 'Duration(hours)',
    trackTips: '(Only supports the latest month’s data)',
    confirmModeChange: 'Are you sure you want to use {mode} mode?',
    keepRestart: 'Keep the relevant configurations after shut-down',
    Restore: 'Restore',

    onlineNotify: 'Equipment Online Notification',
    offlineNotify: 'Equipment Offline Notification',
    advanceControlOption: 'Advanced Control',
    openWebR: 'Open Web Receiver (applicable to receiver software version 7.8 and below, if and when the web port of the receiver is open)',
    release: 'Release',
    // workbench
    CreateCloudR: 'Create a Cloud Receiver',
    CloudReceiver: 'Cloud Receiver',
    Custom: 'Custom',
    DoesNotRepeat: 'Does not repeat',
    Daily: 'Daily',
    EveryWeekday: 'Every weekday (Monday to Friday)',
    EveryWeekend: 'Every weekend (Saturday and Sunday)',
    RepeatEvery: 'Repeat every',
    RepeatOn: 'Repeat on',
    Ends: 'Ends',
    Never: 'Never',
    After: 'After',
    occurrences: 'occurrences',
    MonthlyOnDay: 'Monthly on day {Day}',
    MonthlyOnWeek: 'Monthly on the {SeveralFew} {WeeksSeveral}',
    first: 'first',
    // second: 'second',
    third: 'third',
    fourth: 'fourth',
    fifth: 'fifth',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    EveryFewDays: 'Every {number} days',
    AWeekAFew: 'Weekly on {week}',
    EveryFewWeeks: 'Every {number} weeks on {week}',
    MonthlyDay: 'Monthly on day {day}',
    MonthlyFirstWeeks: 'Monthly on the {first} {week}',
    EveryMonthlyDay: 'Every {number} months on day {day}',
    EveryMonthlyFirstWeeks: 'Every {number} months on the {first} {week}',
    HowTimes: ', {times} times',
    UntilTime: ', until {time}',
    fromUntil: 'from {start} until {end}',
    fromLess: 'from {start}',
    setStartEnd: 'Please set the start-up and shut-down time',
    selectFuture: 'Please select a future time',
    startUpperStopTime: 'Start-up time must be earlier than shut-down time',
    startUpperEndDate: 'From date must be earlier than end date',
    selectAdmin: 'Please select the admin account to assign',
    saveFirst: 'Please save the schedule settings first',
    onlyOne: 'Only this time',
    allSchedule: 'All the schedule time',
    confirmDeleteCloudR: 'Are you sure you want to delete it?',
    cloudRLimit: 'You have reached the limit of {num}. <br />Please delete some of the unused Cloud Receivers first.',
    gotIt: 'Got it',
    createProject: 'Create Cloud Receiver',
    editProject: 'Edit Cloud Receiver',
    projectName: 'Cloud Receiver Name',
    inputInstanceName: 'Please input Cloud Receiver name',
    startingWait: 'Starting now. Please wait.',
    noMatch: 'No Data',
    updateSuccess: 'Update successfully',
    hardware: 'Hardware',
    NoSource: 'No video source available',
    transmissionError: 'Transmission Error',
    batteryError: 'Low Battery Level',
    noData: 'No results. Please check your search criteria and filter settings.'
  }
}
