<template>
  <el-dialog
    :title="$t('lang.notice')"
    :visible.sync="dialogVisible"
    width="640px"
    class='notice'
    :before-close="handleClose">
    <div class="content">
      <div class='message'>{{$t('lang.noticeTitle')}}</div>
      <div
        v-for='(item, index) in msg'
        :key='index'>{{item}}</div>
      <div class='message_con'>
        {{$t('lang.noticeContent1')}}
        <span
          class='pointer'
          @click='openAgreement'>service agreement</span>{{getPunctuation()}} {{$t('lang.noticeContent2')}}
        <!-- <p> 650 400 4812{{getPunctuation()}}</p> -->
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="primary"
        v-repeat-click='{event:markRead}'>{{$t('lang.Ok')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Outil from '@/assets/js/utils.js'

export default {
  props: {
    dialogVisible: {
      type: Boolean
    },
    msg: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  filters: {
    transformTime (time, day) {
      const dateTime = Outil.getDateTime(new Date(Number(time)))
      return day ? `${dateTime.month}/${dateTime.day}/${dateTime.year}` : `${dateTime.hour}:${dateTime.min} ${dateTime.month}-${dateTime.day}-${dateTime.year}`
    }
  },
  methods: {
    // 关闭弹窗
    handleClose () {
      this.$emit('update:dialogVisible', false)
      $('body .v-modal').removeClass('notice')
    },
    // 获取标点符号
    getPunctuation () {
      return this.$i18n.locale.toLowerCase().includes('en') ? '.' : '。'
    },
    // 标记为已读
    markRead () {
      this.axios.post('/ccp/tvucc-alert/featureagreement/updateAgreement', {})
        .then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.$emit('update:dialogVisible', false)
            $('body .v-modal').removeClass('notice')
          }
        }).catch(() => {})
    },
    // 打开agreement
    openAgreement () {
      const strWindowFeatures = 'height=600, width=1027, top=200, left=300, toolbar=no, menubar=no, titlebar=no, scrollbars=no, resizable=no,channelmode=no, location=no, status=no'
      window.open(
        `${location.protocol}//${localStorage.getItem('newCCUrl')}/agreement.html`,
        '_blank',
        strWindowFeatures
      )
    }
  }
}
</script>
<style lang='less' scoped>
.notice {
  z-index: 20002 !important;
  p {
    margin: 0;
  }
  text-align: left;
  ::v-deep .el-dialog {
    padding: 0 32px;
    .el-dialog__header {
      padding: 0;
      .el-dialog__headerbtn {
        right: 32px;
      }
    }
    .el-dialog__body {
      padding: 15px 0 0;
    }
    .el-dialog__footer {
      padding: 79px 0 24px 0;
      button {
        width: 75px;
        height: 32px;
        line-height: 32px;
        padding: 0;
        text-align: center;
      }
    }
  }
  ::v-deep .el-dialog__title {
    width: 100%;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ::v-deep
    .el-dialog
    .el-dialog__header
    .el-dialog__headerbtn
    .el-icon-close:before {
    content: '\E78D';
  }
  .content {
    div {
      font-size: 14px;
      color: rgba(223, 223, 223, 1);
      line-height: 17px;
      word-wrap: normal !important;
      word-break: normal;
      span.pointer {
        color: #1774cc;
      }
    }
    .message {
      margin-bottom: 20px;
    }
    .message_con {
      margin-top: 20px;
    }
  }
}
</style>
