import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 引入elementui 的中英文翻译
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import langZh from './zh'
import langEn from './en'
import langRu from './ru'
import Tool from '@/assets/js/utils'

Vue.use(VueI18n)
const DEFAULT_LANG = Tool.getCookie('userLanguage') || navigator.language || navigator.userLanguage || 'en'
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: {
    en: {
      ...enLocale,
      ...langEn
    },
    zh: {
      ...zhLocale,
      ...langZh
    },
    ru: {
      ...enLocale,
      ...langRu
    }
  },
  fallbackLocale: 'en',
  silentTranslationWarn: true
})
window.i18n = i18n
export default i18n
