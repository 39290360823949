export const BASE_URL = 'BASE_URL'
export const BASE_USER_INFO = 'BASE_USER_INFO'
export const MAP_LOADED = 'MAP_LOADED'
export const CURRENT_RID = 'CURRENT_RID'
export const CURRENT_RINFO = 'CURRENT_RINFO'
export const SETTING_MAP_SHOW = 'SETTING_MAP_SHOW'
export const DEVICELIST_VOLUMECHARTDATA = 'DEVICELIST_VOLUMECHARTDATA'
export const DEVICELIST_SLOTINFODATA = 'DEVICELIST_SLOTINFODATA'
export const DEVICELIST_RECEIVERDATADATA = 'DEVICELIST_RECEIVERDATADATA'
export const DEVICELIST_CURRENTRINFODATA = 'DEVICELIST_CURRENTRINFODATA'
export const DEVICELIST_SETINFODATA = 'DEVICELIST_SETINFODATA'
export const CHANGE_SOURCECONTROL_TAB = 'CHANGE_SOURCECONTROL_TAB'
export const CHANGE_SOURCESize = 'CHANGE_SOURCESize'
export const CHANGE_RecevierSize = 'CHANGE_RecevierSize'
