export default {
  lang: {
    CustomerUser: '客户账号',
    switchTo: '切换账号到',
    internetOffline: '网络连接失败,请稍后再试!',
    notice: '通知',
    noticeTitle: '我们发现您的账号上的一些服务已经通过了免费试用期',
    noticeContent1: '在正在进行的冠状病毒大流行期间，未经同意不得停止免费服务。如欲继续使用这些服务，请阅读并同意',
    noticeContent2: '主动停止服务，请联系features@tvunetworks.com',

    /* ---- title ---- */
    webrTitle: 'TVU收发机',
    titlePre: 'TVU控制中心',
    TMySource: '我的源',
    RPS: 'RPS',
    TAdvanceControl: '收发机控制',
    TMultiControl: '多路控制',
    TMyReceivers: '我的收发机',
    TFileDownload: '文件下载',
    TSetting: '设置',
    TMultiView: '多路监控',
    MultiControl: '多路控制',
    TShare: '分享',
    TToken: '配对',
    TAlert: '消息',
    GridViaToken: 'Token配对转播源',
    GlobalGrid: '全球配对转播源',
    TRouterMonitor: '路由管理',
    TBooking: '预约',
    TPlayout: '播放',
    homeTitle: '主页',
    SearchMonitor: '监控',

    /* ---- my source page ---- */
    extSourceNameTips: '名称不能为空且不得少于3个字符！',
    GLinkSourceIpTips: '请输入正确的IP格式！',
    GLinkSourcePortTips: '请输入正确的端口格式！',
    GlinkUpperLimit: 'IP和端口数已达到上限',
    extSourceTokenTips: '配对码不能为空！',
    trackCollection: '轨迹收藏',
    realTime: '实时',
    history: '历史',
    multiTracking: '多路追踪',
    addCpllection: '添加到轨迹收藏',
    collectionName: '收藏名称',
    deleteCollection: '删除这个收藏',
    collectionPrompt: '保存后，可在“多路追踪”模式下作为参考轨迹使用',
    confirmBtn: '确认',
    deleteOnlyCollectionPrompt: '“多路追踪”模式需要至少一个收藏。您还想删除这个收藏吗?',
    deleteCollectionPrompt: '要删除这个收藏吗?',
    selectCollection: '请选择收藏轨迹',

    /* ---- advanced control page ---- */
    validate: '验证',
    validating: '验证中...',
    record: '录制',
    downloadAll: '下载所有',
    Preview: '预览',
    Download: '下载',
    size: '文件大小',
    compeleted: '已完成',
    Play: '播放',
    deleteAll: '删除所有',
    downList: '下载列表',
    state: '状态',
    exportSelection: '导出选择为',
    saveAs: '保存为',
    export: '导出',
    platform: '平台',
    /* ---- popup ---- */
    notSelectClip: '请选择要导出的视频片段',
    moreThanLimitation: '已经达到视频最大预览个数 {0} 个. 请先关闭其他视频预览页面.',
    syncTitle: '同步功能只有在R和Pack直播时才能启用',
    syncCanUseTitle: '启动失败，请检查R和T的版本是否都大于6.1版本,并且有大于6.1版本的两个或两个以上的R在和T直播',
    notHaveSyncFeature: '设备 {0} 没有开启同步功能',
    synchronizing: '设备正在同步中',
    deviceSynchronizing: '设备已经在其他账号同步',
    maxMultiControlNumber: '最多可以预览6路视频',
    notLastOne: '你需要从最后一个开始删除',
    picturesFormat: '图片最大宽高为480*640',

    // advanced control settingDaiog
    notRecording: '未自动录制',
    Recording: '自动录制',
    clipboard: '拷贝全称至剪切板',
    downloadedFiles: '文件下载',
    startTime: '开始时间',
    endTime: '结束时间',
    startTimeText: '开始时间',
    endTimeText: '结束时间',
    requestId: '请求Id',
    userId: '用户Id',
    relationAccount: '关联账户',
    relationAccountId: '关联账户Id',
    param: '设备和账号参数信息',
    operationType: '操作类型',
    compeletedTime: '完成时间',
    noBindUser: '没有绑定账户',
    emailNotExist: '邮箱不存在',
    urlRequired: '请输入URL',
    urlPathRequired: '请输入URL / Path',
    copyUserTo: '复制用户至',
    destinationDevice: '目标设备',
    deleteOriginalDevice: '删除原始设备',
    fullName: '请输入完整的PID',
    newDeviceNotExit: '设备不存在',
    Copy: '复制',
    capitalCopy: '复制',

    /* ---- home ---- */
    unSupport: '不支持此功能',
    receiverControlTitle: '收发机控制',
    sourceControlTitle: '信号源控制',
    adjustCamera: '调整视频',
    manualPan: '手动操作',
    autoPan: '自动操作',
    Close: '关闭',
    reset: '重置',
    receiverID: '收发机 ID:',
    playerVersion: '播放器版本:',
    recRemain: '可播放时间:',
    coreVersion: '核心版本:',
    productVersion: '产品版本:',
    buildDate: ' 生成日期:',
    IdleCpu: 'CPU空闲:',
    CpuTemp: 'CPU温度:',
    FreeMem: '剩余内存:',
    RemainSpace: '剩余空间:',
    SpaceRemainTime: '剩余时间:',
    LoadAvg1Min: '1分钟平均负载:',
    LoadAvg5Min: '5分钟平均负载:',
    LoadAvg15Min: '15分钟平均负载:',

    receiverControl: 'TVU控制中心',
    mySource: 'TVU控制中心 -- 主页',
    settingControl: 'TVU控制中心 -- 设置',
    title: '调度中心',
    title1: '信号切换',
    source: '信号源',
    destination: '目的地',
    Enable: '启用',
    grid: 'TVU转播源',
    token: '配对',
    logout: '登出',
    all: '全部',
    live: '直播',
    online: '在线',
    offline: '离线',
    going: '事件进行中',
    sort: '动态排序',
    favorite: '收藏',
    PHSearch: '搜索视频或设备',
    status: '状态',
    total: '总共',
    stop: '停止',
    Switch: '切换',
    Take: '直播',
    Stop: '停止',
    NoResult: '数据为空',
    of: '至',
    Type: '类型',
    Ok: '确定',
    On: '启用',
    Off: '关闭',
    Cancel: '取消',
    Codec: '编码器',
    CardID: 'Card ID',
    rightNumber: '请输入正确的数字',
    BasicSetting: '基本设置',
    General: 'General',
    TimeZone: 'Time Zone',
    FeatureControl: '功能列表',
    GridEncoder: 'Grid编码器',
    GLinkEncoder: 'GLink编码器',
    Transcriber: '实时字幕',
    SDIOutput: 'SDI转IP',
    Output: '输出',
    ProcessID: '进程ID',
    Frames: '框架',
    Fps: '帧率',
    VisionTag: '人脸识别',
    AutoRecord: '自动录制',
    FileBrowser: 'File Browser',
    IPStreamOut: 'IP流输出',
    AutoDownload: '自动下载',
    localAllPackPosition: '设备位置',
    SetLocation: '设置位置',
    IPandPortMapping: 'IP端口设置',
    SetMedia: '选择媒体设备',
    gLinkEncoder: 'G-Link编码',
    Bookmark: '书签',
    notSource: '请选择视频源',
    serverError: '操作失败,请稍后再试',
    ParamError: '服务器错误，请联系技术支持',
    ReceiverNotFound: '未找到指定设备，请确认后重试',
    ReceiverOffLine: '接收端设备离线',
    sourceNotExist: '资源不存在',
    SendToRFail: '服务器错误，请联系技术支持',
    youtubeSendToRFail: 'Youtube发送给接收机失败',
    DeviceOffLine: '视频源设备离线',
    DeviceOnLine: '当前设备正在直播中，继续执行操作吗？',
    DeviceNoSource: '视频源异常，请检查设备的视频连接',
    RnotfoundthisSource: '视频源未找到，请确认后重试',
    ReceiverNotLive: '当前设备未处于直播状态',
    ReceiverNotLiveWithSource: '信号源和接收机尚未建立直播',
    selectWebrtcSource: '抱歉，视频预览暂不可用，请检查该设备的WebRTC设置. ',
    timeOut: '请求超时，请稍后再试',
    pleaseWait: '请求已经发送，请稍等',
    inputDescrible: '视频名称',
    netSlow: '正在尝试重连....您的操作将会进行同步，但可能会失败',
    modelTips: '提示',
    close: '取消',
    confirm: '确定',
    loading: '切换中',
    stopping: '停止中',
    pushing: '推流中',
    updateBrowser: '建议使用最新版本的谷歌浏览器，以获得最佳显示效果',
    guideFilter: '可通过名称、状态以及是否收藏，筛选设备列表',
    guideFavorite: '是否加入收藏',
    guideSelT: '选择视频源进行切换',
    guideDesVideo: '为视频添加描述',
    next: '下一步',
    goIt: '知道了',
    searchT: '视频源名称',
    notSwitch: '不能切换相同的源',
    checkFail: '服务器错误,请联系技术支持', // check Fail
    confirmStopBooking: '此收发机正在执行预约事件，确认停止预约事件么?',
    versionLow61: '非常抱歉，您所选择的设备的系统版本较低，部分功能的使用将可能受限，建议升级到6.1以上版本。',
    receiverisnotexist: '接收端设备不存在',
    sourceNotLiveWithCurrentReceiver: '当前源不是和当前的接收机直播',
    rIsNotLiving: '当前接收机没在直播',
    localSourceCanNotStop: '接收机本地添加的视频源,不能停止',
    receiverNotLiveWithCurrentSource: '该源不是和此接收机在直播',
    cameraOff: '没有摄像机！',
    i18n_transimitterLiveReceiver: '此设备正与其他设备进行直播， 您要中断并使用新设备启动直播吗？',
    i18n_stopLiveFailed: '直播停止失败',
    i18n_startLiveFailed: '直播启动失败',
    vaildVal: '有效值为 {0}<->{1}',
    adminUser: '本系统暂不支持技术支持账户或超级管理员账户',
    sourceNotHave: '当前账号下没有此设备.',
    netStop: '网络中断',
    liveFail: '视频播放失败,是否重试?',
    testMsg: '{0}被{1}占用',
    copy: '复制',
    duplicateEvent: '复制事件',
    create: '创建',
    delete: '删除',
    viewSchedule: '待执行',
    runningBookings: '预约',
    pastBookings: '已完成',
    cancelledBookings: '已失效',
    bookingFilter: '时段',
    apply: '查找',
    Apply: '应用',
    applying: '应用中',
    scheduleTitle: '标题',
    start: '开始',
    end: '结束',
    Start: '开始',
    duration: '时长',
    deviceSource: '设备源',
    delayAndBit: '延迟/码率',
    reporter: '记者',
    storySlug: '描述',
    operation: '操作',
    createTitle: '标题',
    des: '描述',
    time: '时间',
    to: '到',
    // delay: '延迟',
    bit: '比特率',
    order: '预约',
    editOrder: '编辑预约',
    interrupted: '被中断',
    failed: '失败',
    planning: '待执行',
    running: '执行中',
    completedStatus: '已完成',
    deleted: '已删除',
    pending: '待执行',
    tokenPending: '待处理',
    edit: '编辑',
    min: '分钟',
    RVersion: '接收机版本',
    RPeerID: '接收机ID',
    ErrorRate8s: '错误率(8s)',
    ErrorRate1s: '错误率(1s)',
    LineQuality8s: '线路质量(8s)',
    LineQuality1s: '线路质量(1s)',
    TBattery: '背包电池',
    TInput: '背包输入',
    TFormat: '背包格式',
    RFormat: '接收端格式',
    IFB: 'IFB',
    IFBSignal: 'IFB Signal',
    VoIPSignal: 'VoIPSignal',
    VoIPOut: 'Audio Out',
    VoIPIn: 'Audio In',
    Delay: '延迟',
    Bitrate: '码率',
    kbps: 'kbps',
    Dynamic: '动态',
    Fixed: '固定',
    VBR: 'VBR',
    CBR: 'CBR',
    Quality: '质量:',
    Home: '首页',
    ReceiverControl: '收发机控制',
    receiver: '收发机',
    Alert: '消息',
    MultiView: '多路监控',
    EventAlert: '事件消息',
    Token: '配对',
    Booking: '预约',
    Share: '分享',
    Setting: '设置',
    FileDownload: '文件下载',
    More: '更多',
    clearFilter: '清除过滤',
    // Details: '信息',
    ReceiverNotExist: '接收机并不存在',
    Sourceliving: '设备正在直播中',
    ReceiverNotConnect: '接收机没有连通',
    ReceiverError: '未知',
    PeerNotFound: '设备不存在',
    PeerIncorrectStatus: '检测到源的状态不正确或接收机未获得源的状态。',
    selectEventFirst: '请先选择一个事件',
    confirmHttps: 'VoIP需要使用HTTPS访问是否要切换到HTTPS，这可能会降低网页速度',
    HadVoip: '如果想进行VoIp通话，请先停掉上一个语音通话',
    getLocalMediaFail: '获取本地媒体资源失败',
    EndVoIP: '通话停止',
    AnalyzeUrlFail: '无效视频地址',
    AnalyzingUrl: '视频地址解析中...',
    AnalyzingFail: '解析视频地址失败',
    AnalyzingSuccess: '解析视频地址成功',
    note: '标签',
    device: '设备',
    color: '颜色',
    save: '保存',
    cancel: '取消',
    creating: '创建中',
    TVUPack: 'TVU直播背包',
    TVUAnywhere: 'TVU直播手机',
    'Grid-Pack': 'TVU转播源',
    'Grid-SDI': 'TVU SDI 转播源',
    Ext: 'Ext',
    'Local-SDI': 'Local SDI',
    SMPTE2022: 'SMPTE2022',
    YouTube: 'YouTube',
    searchTText: '设备标识/名称',
    selectReceiver: '接收机列表',
    Name: '按名称排序',
    Online: '在线优先',
    Live: '直播优先',
    Filter: '筛选',
    filters: '筛选',
    trackName: '轨迹',
    addExtSource: '添加外部源',
    editExtSource: '编辑外部源',
    addGLinkSource: '添加GLink源',
    editGLinkSource: '编辑GLink源',
    selectOutputFormat: '选择一个输出格式',
    name: '名称',
    Save: '保存',
    Delete: '删除',
    option: '选项',
    tableView: '表格视图',
    calendarView: '日历视图',
    deviceNameIpt: '设备标识/名称',
    errRate: '错误率',
    lineQuality: '线路质量',
    battery: '电池',
    tBattery: '背包电池',
    tFormat: '背包格式',
    tInput: '背包输入',
    rFormat: '接收端格式',
    liveVideo: '直播视频',
    runningBooking: '执行中',
    audio: '声音',
    left: '左',
    right: '右',
    capitalDelay: '延迟',
    capitalBit: '比特率',
    quality: '质量',
    'en-US': '英语(美国)',
    'ja-JP': '日语(日本)',
    'pt-PT': '葡萄牙语(葡萄牙)',
    'zh-Hans': '中文(简体，中国)',
    eventAlert: '事件消息',
    send: '发送',
    receivers: '收发机',
    sources: '源',
    transmission: '传输',
    noTransmission: '无传输信息',
    receiverPID: '接收机PID',
    sourcePID: '视频PID',
    priority: '优先次序',
    eventName: '名称',
    FolderName: '文件夹名称',
    eventTime: '时间',
    location: '地址',
    livePort: '播放端口',
    previewPort: '预览端口',
    description: '描述',
    notes: '笔记',
    errorInfo: '请输入必填信息！',
    btn: '确定',
    prompEnsureDel: '确认删除选中的事件么？',
    deleteBookingPrompt: '确认删除选中的事件么?',
    log: '记录',
    notPaired: '设备源和收发机未配对',
    sourceOccupied: '设备源被占用',
    receiverOccupied: '收发机被占用',
    liveFailed: '播放失败',
    serviceClosed: '收发机的预约功能未开启',
    receiverOffline: '收发机离线',
    receiverStopped: '收发机停止工作',
    sourceSwitched: '手动切换设备源',
    receiverStoppedManually: '手动停止收发机',
    goodNight: '停播',
    good: '好',
    bad: '差',
    veryBad: '非常差',
    worse: '很差',
    bookmark: '书签',
    filter: '类别',
    tvuPack: 'TVU直播背包',
    tvuAnywhere: 'TVU直播手机',
    gridPack: 'TVU转播源',
    gridSDI: 'TVU SDI 转播源',
    ReceiverOnLive: '当前的接收机正在和另外一个设备直播,确定要和当前的设备直播吗？',
    ReceiverOnLive1: '当前的接收机正在和另外一个设备直播',
    ReceiverOnLive2: '确定要和当前的设备直播吗？',
    ReceiverDeviceOnLine: '当前的设备正在和另外一个接收机直播，是否停掉之前的接收机和当前的接收机直播？',
    ReceiverDeviceOnLine1: '当前的设备正在和另外一个接收机直播',
    ReceiverDeviceOnLine2: '是否停掉之前的接收机和当前的接收机直播？',
    isAutoReceiver: '当前设备是通过自动模式直播的是否想停掉当前接收机直播.',
    isAutoRLive: '是否要通过自动通道的R去和设备直播',
    isManualReceiver: '当前设备是通过手动模式直播的是否想停掉当前接收机直播.',
    titleEmpty: '标题不能为空',
    durEmpty: '请选择一段有效时间',
    sourceEmpty: '设备不能为空',
    rDEmpty: '收发机不能为空',
    rEmpty: '收发机不能为空',
    sourceSelectFirst: '请先选择一个设备源/SCTE',
    unClassified: '未分类',
    compose: '编写',
    inbox: '收件箱',
    sent: '发送',
    selAll: '选择所有',
    from: '从',
    sureDeleteEvent: '确定终止当前事件么?',
    hourAgo: '小时前',
    minAgo: '分钟前',
    sentAlert: '发送消息',
    version: '版本',
    i18n_noauthorization: '没有访问权限',
    errorCodeFormartError: '错误代码为mart错误',
    nameExist: '该名称已存在',
    nameNotExist: '设备不存在',
    correctLocation: '请选择正确的地理位置',
    noAccessApp: '该用户不能创建访问应用程序',
    bindedDevice: '该用户已经绑定了该设备',
    bindFail: '用户绑定失败',
    removeAllFail: '移除所有失败',
    removeFail: '移除失败',
    firstName: '名',
    lastName: '姓',
    nameRequired: '姓名不能为空',

    // booking
    selectR: '选择一个R',
    monthKey: '月',
    weekKey: '周',
    dayKey: '天',
    todayKey: '今天',
    // booking.common
    bookingRTimeconflict: 'R预订时间冲突',
    bookingTTimeconflict: 'T预订时间冲突',
    // booking.addEvent
    endTimeMoreCurTime: '结束时间必须大于当前时间',
    endTimeMoreStartTime: '结束时间必须大于开始时间',
    deadLineMoreEndTime: '截止时间必须大于开始和结束时间',
    startTimeMoreCurTime: '开始时间必须大于当前时间',
    invalidSourcePeerId: '源PeerId无效',
    invalidDestPeerId: '目标PeerId无效',
    sourceTypeIsNull: '源类型为null',
    invalidStartTime: '开始时间无效',
    invalidEndTime: '结束时间无效',
    biggerEndTime: '结束时间必须大于开始时间',
    biggerStartTime: '开始时间必须大于当前时间',
    sourceSetDelay: 'T必须设置延迟',
    delayInputRange: '延迟的输入范围: 只能输入数字（0.5s to 50s）',
    sourceSetBitrate: 'T必须设置比特率',
    bitrateInput: '比特率: 只能输入数字（100kb/s~10240kb/s）',
    sourceSetUrl: 'Ext源必须设置URL',
    matrixSetOutputId: 'Matrix必须设置Output Id',
    matrixSetSwitchtype: 'Matrix必须设置切换类型',
    setswitchtype: '切换类型必须设置为1或2',
    matrixSupport: 'Matrix只支持Ext或SdiIn源',
    allDevice: '所有设备',
    now: '现在',
    sdiInNotSupport: 'SdiIn不支持Matrix',
    cannotModifyEvent: '已完成的事件不可修改',
    endTimeBigger: '结束时间必须大于当前时间',
    startTimeBigger: '开始时间必须大于当前时间',
    cannotModifyStartTime: '不可修改开始时间',
    refuseCall: '请求被拒绝',
    // booking.delete
    deleteEventFail: '删除事件失败',
    eventIdEmpty: '事件id为空',
    // booking.update
    eventNotexist: '事件不存在',
    eventNotEdit: '事件将在1分钟内完成，不能编辑',
    matrixEventCannotModify: 'Matrix预约事件不可修改',
    notEditEvent: '无法编辑此事件',
    msgpipeConnectError: '服务器连接错误',
    accountExpired: '选中的 “{nickName}” 推流账号已过期，请重新认证',

    // share
    i18n_shareLiveVideo: '直播视频',
    SocialAccount: '社交账号',
    VirtualCloudR: '虚拟云R',
    // liveVideo
    i18n_shareSearchTText: '设备标识/名称',
    i18n_refresh: '刷新',
    // Source
    i18n_destination: '收发机',
    i18n_source: '信号源',
    i18n_moreShare: '多分享',
    i18n_shareDirectory: '直接分享',
    i18n_sharing: '分享中~',
    i18n_loading: '加载中~',
    // Social
    i18n_websiteType: '直播网站',
    i18n_outputName: '输出名称',
    i18n_yes: '是',
    i18n_no: '否',
    i18n_operate: '操作',
    i18n_ifSharing: '分享中',
    Facebook: 'Facebook',
    Periscope: 'Twitter',
    Twitter: 'Twitter',
    YiLive: '一直播',
    Twitch: 'Twitch',
    Instagram: 'Instagram',
    KuaiShou: '快手',
    Others: '其他',
    Vimeo: 'Vimeo',
    i18n_ifDelete: '您确定要移除它吗？',
    i18n_confirm: '确定',
    i18n_quite: '取消',
    i18n_deleteSuccess: '删除成功！',
    noChart: '搜索内容包含特殊字符，请重新输入！',
    // map
    i18n_mapFailed: '地图加载失败，请刷新重试',

    available: '可用',
    noSource: '无视频源',
    viaToken: 'Token配对转播源',
    globalGrid: '全球配对转播源',
    alertContent: '请输入消息内容',
    iptNesInfo: '请输入必填信息',
    addReceiver: '请添加邮件收件人',
    sourceType: '源类型',
    format: '格式',
    batteryVal: '电量',
    slot: 'Slot',
    metadata: '元数据',
    noMetadata: '没有元数据信息',
    tokenCode: '配对码',
    tokenCodeTips: '输入配对码',
    self: '自我介绍',
    selfTips: '你是谁（可选）？',
    chooseRecevice: '选择需要进行配对的收发机',
    chooseReceviceTips: '输入要搜索的PeerID或名称',
    /* home lang */
    home_EnterReceiverNamePeerID: '输入收发机名称/PeerID',
    home_Live: '直播',
    live_OnHold: '直播暂停',
    home_Online: '在线',
    home_clickOfflineR: '此设备已离线',

    // Setting
    wait2Second: '两秒之后允许点击',
    removeIt: '您确定要移除它吗？',
    createAccess: '访问应用程序创建',
    editAccess: '访问应用程序修改',
    company: '公司',
    accessSearch: '公司 / 邮箱 / 识别码 / 密钥',
    email: '邮箱',
    emailOrId: '完整账户/完整PID',
    role: '角色',
    accountType: '账号类型',
    appKey: '应用识别码',
    appSecret: '应用密钥',
    whiteIP: '白名单',
    operationEmail: '操作账户',
    whiteIPList: '白名单列表',
    emailEmpty: '邮箱不能为空！',
    emailformat: '邮箱格式不正确！',
    companyEmpty: '公司不能为空',
    emailAddress: '请输入正确的邮箱地址',
    ExternalSource: '外部源',
    Account: '账户',
    relationHistory: '账户关联历史',
    Device: '设备',
    AccessAPP: '访问应用程序',
    SalesForce: '销售人员',
    FileServer: '文件管理',
    MenuList: '菜单管理',
    set_name: '名称不能为空！',
    set_nameLength: '请输入至少3个字符',
    set_url: 'URL不能为空！',
    ipFormatError: 'IP格式错误',
    userCreation: '用户创建',
    userEditing: '修改权限',
    choice: '请选择',
    inputLegitEmail: '请输入合法的电子邮件地址',
    inputUesr: '请选择所有者',
    inputPassword: '请输入密码！',
    inputPhone: '电话号码输入错误，只能由+，-，数字组成',
    admin: '管理员',
    user: '用户',
    phone: '电话',
    remark: '备注',
    instanceType: '实例类型',
    modifyPermission: '编辑权限',
    nodeactivate: '禁用',
    yesactivate: '启用',
    inactive: '禁用',
    active: '启用',
    activate: '启用',
    deactivate: '禁用',
    deleteRole: '删除角色',
    addRole: '添加角色',
    degradeRole: '降级账户',
    increaseRole: '升级账户',
    password: '密码',
    permission: '权限',
    licensedFeature: '收费功能',
    licensedFeatureReport: '收费功能报告',
    owner: '所有者',
    subscriber: '订阅者',
    input3Character: '至少输入3个字符进行搜索',
    availableDevice: '可用设备',
    bindingDevice: '绑定设备',
    unknow: '未知',
    salesforceAccount: '公司账户',
    uername: '用户名',
    deviceType: '设备类型',
    relationManagement: '关系管理',
    relationManagementWarning: '*警告：从帐户中移除收发机时，该设备的Token配对也会被移除。',
    bindingAccount: '已绑定账户',
    changeDevice: '更改设备属性:',
    showPaired: '显示已配对设备',
    automatic: '自动',
    manual: '手动',
    noPosition: '无位置',
    userInfo: '用户信息',
    GEOLocation: '地理位置',
    LastOnlineTime: '最后在线时间',
    peerID: '设备标识',
    function: '功能',
    availableFunction: '可用功能',
    enabledFunction: '已启用功能',
    editApi: '编辑应用功能',
    aipFunction: '应用功能',
    addWhiteIp: '添加白名单',
    existIp: '已存在相同IP',
    accessControl: '访问控制',
    Licensed: '授权功能报告',
    excelAll: '导出所有',
    decivesAssignedAnotherUser: '一个或多个设备已分配给另一个用户。 您要继续吗？',
    sameIp: '存在相同的IP',
    // FeatureList: '权限管理',
    MailTemplate: '邮件模板',
    activateTips: '你确定要 启用 此用户吗?',
    deactivateTips: '你确定要 禁用 此用户吗?',
    monitorUserTips: '你确定要 查看 此用户吗?',
    gotoSupportTips: '你确定要返回 Support 用户吗?',
    editDevice: '编辑设备名称',
    removelAllUsers: '移除所有用户',
    removeUsers: '移除用户',
    removelUsers: '移除该用户',
    ifRemoveUsers: '是否要移除所有用户？',
    ifRemoveUser: '是否要移除当前用户？',
    userAccountTips: '请选择用户账号',
    setlocationTips: '请选择下面的位置或在此输入',
    refreshInterval: '刷新间隔(秒)：',
    coordinate: '坐标',
    userExist: '用户已存在',
    accountExist: '此用户帐户已经具有控制中心访问权限。请联系TVU技术支持部门寻求帮助。',
    bookmarkNameTips: '书签名称已存在',
    connotDelete: '当前源已被预约，请删除现有预约以继续',
    useExist: '用户不存在',
    anotherSourceEdit: '另一个信号源正在编辑中',
    completeEditOperation: '请先完成编辑操作',
    bookmarkNameRequired: '书签名称不能为空',
    companyExist: '公司名称已存在',
    extLiving: '设备正在直播',
    RPSEncoder: 'RPS编码器',
    RPSDecoder: 'RPS解码器',
    set_name_space: '设备名称不能输入空格',
    accountEmpty: '账号不能为空',
    moduleNotFound: '暂未开启该功能',
    idNotFound: '没有找到设备ID',
    addUser: '添加用户',
    DeviceChangeLog: '设备修改日志',
    clear: '清空',
    passwordLength: '密码至少为6位',
    TestInstance: '实例测试',
    startInstance: '启动实例',
    runInstance: '实例运行中',
    starting: '启动中',
    ending: '关机中',
    noPairR: '没有配对的R',
    GroupManage: '分组',
    connectedCC: '连接调度中心',
    getSessionFailed: '获得会话失败',
    deviceFeature: '设备功能',
    startDate: '开始日期',
    endDate: '结束日期',
    explainA: '要扩展这些功能，请联系',
    explainB: '您的销售代表或TVU的支持',

    // SourceMap
    i18n_equipmentIdentity: '设备标识：',
    i18n_equipmentName: '名称：',
    i18n_locationFailed: '收发机位置获取失败',

    // Pagination
    i18n_first: '首页',
    i18n_pre: '上一页',
    i18n_next: '下一页',
    i18n_last: '尾页',
    i18n_jump: '跳转',
    i18n_total: '共',
    i18n_severalData: '条数据',

    // token
    autoApprove: '自动通过所有配对申请',
    editDeviceBtn: '编辑设备',
    expireTime: '有效期:',
    gridReceiver: 'Grid源',
    createToken: '创建配对',
    editToken: '编辑配对',
    tokenName: '配对名称',
    tokenType: '配对类型',
    expiration: '有效期',
    availableList: '可选接收机',
    saleAvailableList: '可用列表',
    selectedList: '选择列表',
    pid: '设备标识',
    deviceName: '设备名称',
    add: '添加',
    remove: '移除',
    addAll: '全部',
    removeAll: '全部',
    iptTokenName: '创建配对名称',
    iptExpiration: '选择一个有效期',
    iptAvailable: '至少输入3个字符',
    peeridOrName: '设备标识/名称',
    qrPromp: '扫描或输入配对码与接收机完成配对',
    receiverList: '接收机列表',
    snedCodeToEmail: '邮件发送配对码',
    ensureDelete: '所有配对的设备都将丢失!',
    snedCodeTo: '发送到',
    emialAds: '邮箱地址',
    bookmarkName: '书签名称',
    createBookmark: '创建书签',
    includeInBokmk: '全部书签',
    bookmarkBars: '书签栏',
    deleteBookmark: '您确定要删除这个书签吗？',
    addDevice: '添加设备',
    move: '移动',
    moveDeviceTo: '移动到',
    copyDeviceTo: '复制到',
    success: '操作成功',
    chooseBookmark: '请选择一个书签',
    sureDeleteDevice: '您确定要从这个书签中删除这些设备吗？',
    relationMang: '关系管理',
    external: '外部源',
    local: '本地',
    modules: '模块',
    ip: 'IP',
    monitor: '监控',
    worker: 'Worker',
    createFileServer: '用户创建',
    saveIdPort: 'SaveId Port',
    saveId: 'SlaveId',
    port: '端口',
    salesName: '销售名称',
    region: '地区',
    salesAccount: '销售帐户',
    englishText: '英文文本',
    chineseText: '中文文本',
    pageMark: '页面标签',
    Aspect: '样式',
    LastFrame: '最后一帧',
    LastFrameSeconds: '最后一帧持续时间',
    AudioChannels: '声道数量',
    Output12: '待机时显示-12db',
    AsfFrameFroze: 'ASF帧',
    Encoder: '编码器',
    Resolution: '分辨率',
    Port: '端口',
    VBitrate: '视频比特率',
    ABitrate: '音频比特率',
    AChannel: '声道',
    Channel: '声道',
    SetMediaDevice: '媒体设置',
    Microphone: '麦克风',
    Speaker: '语音设备',
    IPAndPort: 'IP端口设置',
    External: '外网',
    Local: '内网',
    WebPort: '网页端口',
    StreamPort: '输出端口',
    ProgressiveDownload: '渐进下载',
    AutoConvert: '自动转换成',
    // VisionTag: '人脸设置',
    Language: '语言',
    Landmarks: '地标',
    Celebrities: '名人',
    Details: '详细',
    Adult: '成年人',
    Faces: '人脸',
    VisualFeatures: '视觉特征',
    Settings: '设置',
    Encapsulation: '封装格式',
    Framesrate: '帧率',
    GOP: 'GOP',
    TimeLock: '同步时间',
    Deinterlace: 'Deinterlace',
    ProgramID: '进程ID',
    Samplerate: '取样帧率',
    OutputPath: '路径',
    FileName: '文件名称',
    AudioCodec: '音频编码',
    VideoCodec: '视频编码',
    FullName: '全名',
    Status: '状态',
    FileSize: '文件大小',
    StartedAt: '开始时间',
    TimeElapsed: '录制时长',
    FPS: 'FPS',
    VideoBitrate: '视频比特率',
    EncoderPid: '编码 PID',
    show: '显示',
    other: '其他',
    tvucc: 'Tvucc',
    hide: '隐藏',
    editMenu: '编辑菜单',
    addMenu: '添加菜单',
    dragSort: '拖拽排序',
    menuSort: '排序',
    sureRemove: '您确定要移除它吗？',
    isModifyBookmarkName: '您要修改此书签名称吗?',
    menu: '菜单',
    orderNo: '排序',
    feature: '功能',
    menuError: '菜单不能为空',
    enNameError: '英文文本不能为空',
    cnNameError: '中文文本不能为空',
    dataMarkError: '页面标签不能为空',
    typeError: '类型不能为空',
    enableError: '状态不能为空',
    remarkError: '备注不能为空',
    orderNoError: '排序不能为空,并且只能为数字',
    adminAutoOwnError: 'Admin Auto Own不能为空,并且只能为数字',
    FeatureList: '功能列表',
    addFeature: '创建功能',
    editFeature: '编辑功能',
    setTranscriber: '实时字幕设置',
    transcriberDelay: '实时字幕延时(s)',
    video: '视频',
    languageSelection: '选择语言',
    saveSRT: '保存SRT文件至',
    activatedSystem: '已激活系统',
    scheduledTime: '计划时间',
    applyBtn: '确定',
    displayLines: '显示行数',
    charactersPerLine: '每行字数',
    ClearUppercase: '清除',
    captionType: '字幕类型',
    Profranity: '亵渎',
    autoMuteWords: '自动静音词',
    Timed: '时间',
    enableTVUTranscriber: '启用TVU实时字幕',
    Enabled: '开启',
    bitrateDelay: '请输入 {0} ~ {1} 之间的数字',
    showMore: '显示更多',
    deviceList: '设备列表',
    denied: '已拒绝',
    joinTime: '加入时间',
    introduction: '简介',
    accepted: '已配对',
    // accepted: '接受',
    rejected: '拒绝',
    setExpired: '设置为过期',
    selectPlaceholder: '请选择设备',
    deny: '拒绝',
    accept: '接受',
    existName: '配对名称已存在',
    tokenNameEmpty: '配对名称不能为空',
    expiredTimeEmpty: '有效期不能为空',
    theSameDevice: '设备已存在',
    shareTo: '分享到',
    userAccount: '用户账号',
    members: '成员',
    notSpecialChar: '设备名称不能含有特殊字符',
    // Start && stop live code
    SourceNotBelongToUser: '设备不属于本用户',
    ReceiverNotBelongToUser: '接收机不属于本用户',
    UserRoleNoFeature: '没有权限，请联系调度中心的账号管理员或者TVU技术支持以获得帮助',
    receiverOnline: '收发机在线',
    GivenPeerNotSelected: '参数错误',
    featuresEffect: '功能更改将在重新启动后生效',
    requestFailed: '请求失败',
    enditFailed: '编辑失败',
    setLocationTips: '请设置收发机的地理位置',
    invalidUrl: '无效的地址',
    supportManager: '超级管理员',
    systemAdmin: '系统管理员',
    preTipsUp: '你是否要将账号类型从',
    preTipsDown: '你是否要将账号类型从',
    afterTipsUp: '升为',
    afterTipsDown: '降为',
    featureError: '功能不能为空',
    autoOwnError: 'autoOwn不能为空',
    urlError: 'url不能为空',
    accessPage: '访问页面',
    account: '账户',
    support: '技术支持',
    control: '控制',
    autoOwn: '默认拥有',
    adminAutoOwn: '默认拥有',
    isBooking: '接收机正在执行预约事件',
    currentRLiveSourceNotBelongToUser: '设备正在live的source不属于当前用户',
    mailAddrTips: '请输入合法的电子邮件地址',
    emailTips: '发送内容',
    operationFailure: '操作失败',
    noTokenPrompt: 'Token是促进TVU设备配对的更有效方式',
    operationFailed: '操作失败!',
    tokenItems: '全部配对',
    searchDevicePrompt: '请至少输入3个字符进行过滤',
    timeError: '过期时间小于当前时间',
    type: '类型',
    bitrate: '比特率',
    delay: '延迟率',
    error: '错误率',
    errorTips: '错误',
    receiverNotConnect: '接收机未连接',
    notHaveStartauthor: '没有开启直播的权限，请联系调度中心的账号管理员或者TVU技术支持以获得帮助',
    notHaveStoptauthor: '没有停止直播的权限，请联系调度中心的账号管理员或者TVU技术支持以获得帮助',
    notHaveVoipauthor: '没有Voip的权限，请联系调度中心的账号管理员或者TVU技术支持以获得帮助',
    // My Receiver Page
    rinputDescrible: '输入名称',
    sourceFrom: '源来自于',
    newMyRecevierInputTitle: '视频或目的地',
    newMyRecevierSort: '按状态排序',
    receiverDataIsEmpty: '设备为空',
    recevierMyFavorite: '收藏',
    addFavoriteSuccess: '添加收藏成功',
    EnterSourceNamePeerID: '输入资源名称/PeerID',
    notSupportFunction: '不支持此项操作',
    // 邮件模板
    params: '参数',
    TimeRemaining: '剩余时间:',
    StorageRemaining: '剩余空间:',
    createMailTemplate: '创建邮件模板',
    editMailTemplate: '编辑邮件模板',
    emptyURLCheck: '请输入模板地址',
    validURLCheck: '请输入事实存在的,且能被该服务访问到的邮件模板地址',
    emptyNameCheck: '请输入邮件名称',
    emptyTypeCheck: '请选择邮件类型',
    emptyParamsCheck: '请输入邮件参数',
    tokenExpired: '过期',
    invalidTime: '无效时间',
    expired: '过期',
    endTimeNotLTCurTime: '结束时间不能小于当前时间',
    invalidEvent: '此事件已完成',

    // new home
    dynamicsorting: '动态排序',
    serverFailed: '服务器异常',
    modeSwitching: '模式切换中，请稍后',
    anywhereTimeout: 'Anywhere连接超时，请检查设备设置或联系技术支持',

    // new multiview
    cannotSwitchSplitScreenMode: '您当前的视频个数大于您要切换的分屏模式，不可切换！',
    rVerionNeedV56Above: '收发机的版本必须在V5.6或以上',
    showInfo: '显示信息',
    AddToCall: '添加到通话',
    startToCall: '开始通话',
    selectMonitorSelectCall: '只有TVU背包和手机能够支持语音通话',
    selectObjCall: '请先选择通话对象',
    setVideoFeedbackTrueViewVideo: '如需观看视频，请确保收发机的VideoFeedback功能开启或者让工作人员帮你开启。',
    setVideoFeedbackTrueViewVideoMoreLive: '预览的最大数量-已达到5。请先关闭其他预览窗口。',
    continueCall: '继续通话',
    interruptAllCall: '中断所有通话？',
    interruptAll: '中断',
    changeOwner: '变更所属账号',
    belongTo: '属于',
    alreadyBelong: '客户用户已经属于管理用户',
    startTimeLessThanCurTime: '开始时间小于当前时间',
    stopTimeLessThanCurTime: '结束时间小于当前时间',
    selectData: '选择日期时间',
    enterStartTime: '请输入开始时间',
    enterEndTime: '请输入结束时间',
    enterDescription: '请输入事件描述信息',
    selectOwner: '请选择一个管理员账号',
    switchToWeek: '请切换到周视图',
    extMustSetUrl: 'ext或sdiin源必须设置url',
    magnifying: '放大 +',
    faildCallTpcModifyName: '调用TPC修改名称失败!',
    scte: 'scte',
    scteCaptial: 'SCTE',
    trigger: '触发',
    addScte: '添加SCTE',
    spliceType: 'Splice Type',
    prerollTime: 'Preroll Time',
    nameEmpty: '名称不能为空',
    scteName: 'SCTE Name',
    bookingEvents: '待执行',
    runningEvents: '预约',
    pastEvents: '已完成',
    createTime: '创建时间',
    deviceSourceScte: '设备源',
    Parameters: '参数',
    file: '文件',
    addfile: '添加文件',
    addFolder: '添加文件夹',
    addMedia: '添加媒体',
    Folder: '文件夹',
    selectAFolder: '选择一个文件夹',
    addvideo: '添加本地视频',
    editfile: '编辑文件',
    editEmail: '编辑Email',
    fileName: '文件名称',
    bookingImportFile: '请选择Excel/CSV的文件类型上传',
    bookingImportFileTemp: '查看上传文件模板示例',
    search: '搜索',
    bookingChannel: '频道',
    bookingDeleteFail: '有事件正在使用此文件,不能删除',
    bookingEditFail: '有事件正在使用此文件,不能修改',
    durationEmpty: '时间间隔不可为空',
    durationType: '时间间隔需为正整数',
    nameIsExist: '名称已经存在',
    durationUnit: '( 秒 )',
    upload: '上传',
    proccessing: '上传中...',
    uploadVideoLocal: '上传本地视频文件',
    uploadHasSame: '您选择的视频文件已经上传',
    selectAVideo: '请选择您要添加的视频',
    videoExist: '视频已存在',
    fileNeedType: '上传文件只能是 xls、xlsx、csv格式!',
    videoNeedType: '上传视频只能是 ts,rm,rmvb,wmv,wtv,avi,3gp,mkv,mpg,video/mpeg,video/mp4,mov格式!',
    noDevice: '没有设备',
    readonly: 'video或scte类型不能修改结束时间',
    sctePrerollTime: 'PrerollTime长度不能超过5个字符串',
    offTime: '关机时间',
    offTimeNotEmpty: '关机时间不能为空',
    hebdomad: '一周',
    thirtyDays: '30天',
    uploadExcel: '导出到Excel',
    uploadExcelAll: '导出全部',
    // playout
    fileNotformat: '上传文件内容格式不正确',
    editWarning: 'live就要开始了,您确认要编辑吗？',
    mediaFolder: '媒体',
    // alert
    offlined: '已下线',
    onlined: '上线啦',
    startLiveTip: '开始直播',
    stopLiveTip: '停止直播',
    noAvailable: '不可用',

    deviceNameBytes: '设备名称不能超过15个字节',

    // setting
    instanceId: '实例编号',
    rId: '接收机ID',
    Ip: 'IP',
    creater: '创建者',
    stopR: '关闭接收机',
    stopTime: '关机时间',
    unAssigned: '未分配',
    shutdownP: '关机人员',
    ClosedCloudR: '已关机',
    ClosedBySupport: '被Support关机',
    ScheduleFeature: '未来计划',
    ClosedBySystem: '被系统管理员关机',
    startUpCloudR: '开启虚拟云R',
    Shutdown: '关机',
    launchTime: '启动时间',
    ShutdownTime: '关机时间',
    scheduleTime: '预约时间',
    scheduleShutdownTime: '预约关机时间',
    scheduleStartTime: '预约开机时间',
    usageType: '使用类型',
    selectUsageType: '请选择使用类型',
    selectInstanceType: '请选择实例类型',
    canUseArea: '可用区域',
    selectArea: '请选择您要开机的地区',
    selectNumOfMachine: '请选择您要开机的虚拟机所需R数量',
    selectProject: '请选择项目类型',
    noRegion: '当前类型下无可用区域，请重新选择',
    connectTpcFail: '连接TPC失败',
    connectInstanceFail: '连接 Instance-service 失败',
    getRegionFail: '获取地区信息失败',
    createInsFail: '创建实例失败',
    shutdownInsFail: '关闭实例失败',
    userInsnull: '用户实例不存在',
    userInsreadyCLose: '用户实例已经关闭',
    confirmShutDown: '你确定要关闭该实例吗?',
    confirmDeleteSchedule: '你确定要删除该计划实例吗？',
    virtualRDes: '虚拟云R',
    needRNum: '虚拟机所需R数量',
    emailNotice: '云R启动邮件通知',
    projectType: '项目类型',
    replaceEmail: '替换邮箱',
    delelteUser: '删除',
    editDeviceNameFaild: '编辑设备名称失败',
    waitMinutes: '启动WebR可能需要3-5分钟',
    selectVaildTime: '请选择一个有效时间',
    addNewBookmark: '添加一个新书签',
    includeInBookmark: '包括在书签',
    bookmarksBar: '请选择书签栏',
    deviceShutdownDate: '设备关机时间为',
    receiverPidOrName: '接收器的设备标识/名称',
    gridOwner: 'Grid所有者',
    createEvent: 'Create Event',
    Reload: '重载',
    reloadConfirm: '重新加载此帐户下的接收机和源将需要一些时间。是否继续？',
    reloadPageConfirm: '重新加载当前页的接收机和源将需要一些时间。是否继续？',
    deviceReloadConfirm: '重新加载此帐户下的接收机将需要一些时间。是否继续？',
    deviceReloadPageConfirm: '重新加载当前页的接收机将需要一些时间。是否继续？',
    switchDeviceSourcesOld: '切换到以前的授权方式以对源授权',
    switchDeviceSourcesNew: '切换到以收发机为中心的新授权方式',
    reloadTips: '请注意，帐户重载会需要一些时间。',
    addReloadTips: '添加与收发机配对时丢失的源。',
    removeReloadTips1: '移除未与任何收发机配对的源。',
    removeReloadTips2: 'RPS/Router/Producer帐户请谨慎使用。',
    addEmail: '添加邮箱',

    // 定制化CC
    manualChannel: '手动通道',
    channelPool: '自动通道',
    channelSetting: '通道设置',
    searchChannel: '搜索通道',
    autoType: '模式',
    occurType: '类型',
    occupy: '被占用',
    idle: '非占用',
    unUseable: '不可用的',
    channelName: '名称',
    channelType: '通道类型',
    occupyStatus: '占用状态',
    groupName: '组名',
    channelSource: '信号源',
    deviceStatus: '设备状态',
    occupyUser: '占用者',
    occupyTime: '占用时间',
    currentChannel: '当前通道',
    alreadyUse: '已使用',
    availabelUser: '可用通道',
    allChannel: '总通道数',
    offlineChannel: '离线数',
    address: '地址',
    outputAddress: '输出地址',
    channelGroup: '收发机组名',
    selectSocial: '搜索输出地址',
    pushSuccess: '推流成功',
    stopPushSuccess: '停止推流成功',
    destinationChannel: '收发机',
    groupEmpty: '收发机组名不能为空',
    searchGroup: '搜索分组',
    defaultGroup: '是否默认',
    addGroup: '添加分组',
    inputGroup: '请输入分组名称',
    editGroup: '修改分组',
    set_groupName: '组名不能为空！',
    changeGroup: '切换组',
    noGroup: '请选择一个组名',
    setAutoBtn: '设置为自动',
    setManualBtn: '设置为手动',
    groupExist: '当前组名已存在',
    defaultNotDelete: '默认分组不能进行删除操作',
    existDeviceGroup: '当前分组下存在设备，无法删除',
    eventNoExist: '当前任务不存在',
    sourceNoExist: '信号源不存在',
    noIdleChannel: '没有可用的直播通道',
    groupNotExist: '分组不存在',
    userNoGroup: '分组不属于当前用户',
    deviceNotT: '设备不是Anywhere/Pack',
    registFailed: '注册设备失败',
    accountSharing: '账号正在推流',
    accountNoSharing: '账号未推流',
    accountNoExist: '推流账号不存在',
    systemError: '系统错误',
    pushFailed: '推流失败',
    stopPushFailed: '停止推流失败',
    // token
    shareToeknSelect: '请选择',
    shareBy: '共享来自',
    noPairDestiation: '当前组下没有与选中源配对的收发机',

    waitCalled: '等待 UGC',
    channelOccupied: '通道被占用',

    // multiview plus

    workspace: '工作区',
    currentDevice: '当前设备',
    workspaceList: '工作区列表',
    newWorkspace: '创建工作区',
    enternewWorkspace: '输入新的工作区',
    plzSelectLayout: '请选择工作区大小',
    plzEnterspaceName: '请输入工作区名字',
    duplicate: '复制',
    deleteSpace: '您确定要删除当前工作区吗?',
    BacktoView: '返回',
    ManageWorkspace: '管理工作区',
    addTo: '添加到',
    moveTo: '移动到',
    copyTo: '复制到',
    newWorkSpace: '创建工作区',
    moveSuccess: '移动成功',
    copySuccess: '复制成功',
    addSuccess: '添加成功',
    deleteSuccess: '删除成功',
    deleteInfo: '您确定要将此设备从当前工作区移除吗?',
    ManageDevices: '管理工作区设备',
    showDesc: '显示描述',
    mvpRecevierSort: '按名称排序',
    userNotExist: '用户在CC中不存在',
    emailExistInCC: 'CC中存在这个邮箱',
    emailExistInDB: 'dashboard中存在这个邮箱',
    USUpdateFailed: 'UserService更新电子邮件失败',
    DBUpdateFailed: 'Dashboard更新电子邮件失败',
    tomultiview: '进入 多路监控 页面',
    tomultiviewplus: '进入 多路监控-Plus 页面',
    Rename: '重命名',
    Enter: '进入',
    Duplicate: '复制',
    dragAndDrop: '拖放到工作区',
    overMax: '超过最大数量',
    loadMore: '加载更多',

    // Router Monitor
    tvuRouterMonitor: 'TVU路由管理',
    curSession: '当前的session',
    totalUpload: '总上传量',
    totalDownload: '总下载量',
    totalUsage: '总使用量',
    onlineTime: '在线时间',
    numOfClients: '客户端数量',
    countDownPrompt: '5秒钟之后再点击',
    selectSource: '请选择一个源',
    agreement: '协议',
    lastLogin: '最近登录',
    goWebR: '前往WebR',
    delayRang: '延迟输入范围: 只能是数字类型, 从0.5秒到50秒',
    overDevice: '布局小于设备数量',
    tcpReportCount: 'UDP监听',

    thumbnail: '缩略图',
    liveBitrate: '播放比特率',
    latency: '延迟率',
    selectBookmark: '请选择一个标签',
    goLive: '直播',
    largeSize: '大号',
    mediumSize: '中号',
    smallSize: '小号',
    typeVal: '类型',
    workspaceName: '工作区名称',
    nameLessThan: '名称长度必须小于等于',
    thumbnailObj: '缩略图',
    second: '秒',
    sourceNoHaveR: '此source没有可用的R',
    GLink: 'GLink',
    RTIL: 'RTIL',
    voipStart: 'VoIP开启',
    RestartService: '重新启动服务',
    Connectivity: '切换服务',
    cardName: '卡名:',
    inputFormat: '输入格式:',
    outputFormat: '输出格式:',
    inputFor: '输入格式',
    outputFor: '输出格式',
    tranSmission: '传输参数',
    durationTime: '结束时间最多只能比开始时间大{time}分钟',
    modeTypeEmpty: '模式不能为空',
    liveDontOption: '正在live的不支持此项操作',
    offSourNotReal: '离线设备只能选择历史模式',
    networkBad: '网络不好，请刷新浏览器',
    nameExit: '名字重复!',
    creatName: '账号邮箱不能使用@tvunetworks.com',
    Preference: '偏好设置',
    stopLiveConfirm: '您确定要停止直播吗',
    stopLiveCheck: '下次不要提醒我',
    preferenceTitle: '传输',
    stopAlertMid: '停止直播确认',
    startAlertMid: '开始直播',
    stopAlertRight: '停止直播',
    showLocation: '在缩略图上显示地理位置',
    rightAlert: '允许弹出通知',
    alertRight: '通知页面的新消息',
    accountURL: '账号/URL',

    // user behavior
    UserBehavior: '操作日志',
    ubcc: '调度中心',
    ubpp: 'TVU 云导播',
    ubchannel: 'Channel',
    operationDescription: '操作描述',
    ubtimeOut: '请求超时',
    operationParams: '操作参数',
    operationTime: '操作时间',
    localTime: '本地时间',
    UTCTime: 'UTC时间',
    userAgent: '浏览器信息',
    ubemailPlace: '邮箱(搜索项以“,”分隔)',
    ubAccept: '同意',
    ubRejected: '拒绝',
    ubDeleted: '删除',
    ubStartLive: '开始直播',
    ubStopLive: '停止直播',
    ubSetDelayAndBitrate: '设置延迟、比特率',
    ubSetIPAndPort: 'IP端口设置',
    ubSetSDIoutput: 'SDI转IP',
    ubShare: '分享',
    ubStopShare: '停止分享',
    ubDownloadRecord: '记录下载',
    ubEditMemo: '编辑名称',
    ubRequestToken: '请求Token',
    ubAcceptRequest: '同意请求',
    ubEditDeviceName: '编辑设备名称',
    ubEditToken: '创建Token',
    ubSendEmail: '发送邮件',

    ubSetDeviceLocation: '设置设备位置',
    ubdStartLive: '使用收发机“XXX”直播源“XXX”',
    ubdStopLive: '停止收发机“XXX”的直播',
    ubdSetDelayAndBitrate: '收发机“XXX”在页面“XXX”上设置直播延迟为XXX秒，直播码率为XXX kbps',
    ubdSetIPAndPort: '设置了收发机“XXX”的IP端口',
    ubdSetSDIoutput: '设置了收发机“XXX”的SDI',
    ubdShare: '通过XXX将收发机“XXX”的直播分享到“XXX”（分辨率：XXX,比特率：XXX）',
    ubdStopShare: '通过XXX停止收发机“XXX”在XXX上的直播分享',
    ubdDownloadRecord: '下载收发机"XXX"的文件',
    ubdEditMemo: '设备"XXX"备注“XXX”',
    ubdRequestToken: 'Anywhere XXX 通过扫描code"XXX"发起了配对请求',
    ubdAcceptRequest: '了收发机"XXX"对code“XXX”的请求',
    ubdEditDeviceName: '设置设备 "XXX"名称为“XXX"',
    ubdEditToken: '创建Token “XXX”，到期时间：XXX，设备：“XXX”',
    ubdSendEmail: '将Token “XXX”邮件分享给 XXX',
    ubdSetDeviceLocation: '设置设备位置：XXX',

    // 新增anywhere
    manPanTitle1: '鼠标拖拽左侧视频区域',
    manPanTitle2: ' 调整人物在画面位置',
    autoPanTitle1: '自动识别人脸',
    autoPanTitle2: '保持人物时刻处在画面中央',
    autoSelectQuality: '自动',
    detailTemp: '温度',
    detailUsage: '使用率',
    // profile
    profile: '配置',
    OpenPath: '打开路径',
    enterID: '请输入Programe ID',
    selectProfile: '请选择配置',
    ProfileAdd: '新增',
    ProfileBack: '返回',
    ProfileName: '配置名称',
    SetEncoderFail: '设置编码器失败',
    ProgrameNum: 'Program ID只允许数字',
    ConfirmRecording: '您是否要删除此录音设置配置文件？',
    RecordingPrompt: '提示',
    ProfileNameMust: '请输入配置名称',
    EditSuccess: '编辑成功',
    CreateSuccess: '创建成功',
    SampleRate: '采样率',
    Tune: 'Tune',
    AudioEncoder: '音频编码器',
    Preset: '预设',
    Level: '级别',
    Scale: '缩放',
    ProfileAuto: '自动',
    GlobalQuality: '全局质量',
    VideoEncoder: '视频编码器',
    Muxer: '封装',
    hasProfileName: '配置名称已存在，请重新输入',
    // TokenService&VLAN
    tokenService: '配对服务',
    creatNewToken: '创建新配对',
    ReceiverName: '接收机名称',
    ReceiverID: '接收机ID',
    ReceiverIP: '接收机IP',
    ReceiverPort: '接收机端口',
    ReceiverTimeSpan: '接收机时间范围',
    ReceiverEmail: '接收机邮箱',
    TransmitterName: '发射机名称',
    TransmitterPID: '发射机PID',
    TransmitterEmail: '发射机邮箱',
    SaveAs: '另存为',
    SetAToken: '设置配对',
    CheckTokenInfo: '核对配对信息',
    SetToken: '设置配对',
    TokenMessage: '配对信息',
    ShouldMailedTo: '邮件发送至：',
    CopyEmail: '复制邮箱',
    TakeNextToken: '获取下一配对',
    TransmitterValidTime: '发射机有效时间',
    CreateTime: '创建时间',
    ExpiredAt: '失效时间',
    SetValidTime: '设置有效时间',
    SureSetNoTime: '是否将配对有效期设为0？',
    SureDeletePeer: '请确认是否要删除配对 ',
    VLANSetting: 'VLAN设置',
    IPServicePort: 'IP和服务端口',
    Interface: '接口',
    IPMethod: 'IP方式',
    MACAddress: 'MAC地址',
    IPAddress: 'IP地址',
    Mask: '掩码',
    Gateway: '网关',
    RouterServicePort: '路由器服务端口',
    StopInterface: '停用接口',
    RouterClientList: '路由器使用列表',
    DeleteToken: '删除配对',
    SelectInterface: '选择接口',
    EnterRouterPort: '进入路由服务端口',
    NoClients: 'No Clients',
    SureBeUsed: '将用作VLAN服务器，无法用于常规的直播传输或互联网访问。确认要继续吗？',
    StartRouterFail: '开启路由失败',
    StopRouterFail: '停止路由失败',
    SaveSuccess: '保存成功',
    SaveFail: '保存失败',
    PeerName: '名称',
    IPAdress: 'IP地址',

    RoleManagement: '角色管理',
    roleName: '角色名称',
    accounts: '账户',
    devices: '设备',
    inputRoleName: '角色名称不可以为空',
    inputPermission: '权限不可以为空',
    editRole: '编辑',
    createRole: '创建角色',
    editeRole: '修改角色',
    deleteAdmin: '你确定要移除管理员账户?',
    deleteIncluld: '包括它的子账户',
    userAccountTip: '用户账户',
    selectAccount: '请选择用户账户',
    deletetipsPre: '该角色下有',
    deletetipsLast: '个帐号在使用，你仍然想要删除它吗？',
    roleNotExist: '这个角色名称已经存在',
    // Haran新增Transcriber
    TranscriberText: '翻译字幕',
    Repeat: '重复',
    endRepeat: '结束重复',
    halfHour: '半小时',
    anHour: '一个小时',
    allDay: '全天',
    TranscriberBooking: '创建翻译预约',
    EndtimeEmpty: '结束时间不能为空',

    switchSuccess: '区域切换成功，请重启R服务',
    removeShare: '是否确认移除分享？',
    myBookmark: '我的书签',
    parentBookmark: '父级的书签',
    otherBookmark: '的标签',
    allAdded: '所有帐号已添加',

    startAll: '开始全部',
    stopAll: '停止全部',
    bookmarks: '书签',
    dragHere: '将源拖到这里使用',
    searchReceivers: '搜索\'Receivers\'',
    searchIn: '搜索',
    warningTitle: '警告',
    Reboot: '重启设备',
    RestartBtn: '重启服务',
    RebootBtn: '重启设备',
    ifRestartService: '重启服务可能需要花费一定时间。你仍然要重启它吗？',
    ifRebootR: '重启设备可能需要花费一定时间。你仍然要重启它吗？',
    alreadyShare: '当前账号已分享',
    PairedUnits: '配对情况',
    pairedDeviceName: '设备名称：',
    Update: '更新于：',
    // TimeLock相关
    MainSource: '主视频源',
    removeMainRTips: '警告：移除主时间源时，整个时间同步将取消。',
    serverTimeLocking: '时间同步中（服务器）',
    clientTimeLocking: '时间同步中（客户端）',
    otherOccur: ': 用户{userEmail}已在多路控制中使用该设备',
    failedDevice: '设备 {device} 同步失败。',
    pendingDevice: ' 设备 {device} 同步状态未知。',
    TimeLockTitle: '时间同步',
    serverIPTiltle: 'Server Device NTP',
    InternalIp: 'Internal IP/Port:',
    ExternallIp: 'External IP/Port:',
    TimeService: '时间服务',
    ReleaseTimeLock: '解除时间同步',
    StillRemove: '您仍然要移除它吗？',
    StillRelease: '您仍然要解除它吗？',
    OneReceiverTip: '当只剩一个收发机时，时间同步将自动取消。',
    ReceiverUsingTimeLock: '个收发机在使用时间同步。',
    ThereAre: '有',
    CreatedBy: '创建于',
    At: '在',
    NullIp: '内网Ip为空',
    sameMachineTip: '同一个机器的多个R不允许同时添加到TimeLock中',
    noTimeLockFeature: '设备没有开启TimeLock的feature',
    failedGetIp: '获取IP/Port失败',
    NotDevice: '设备不存在',
    NotTR: '设备不是T/R。timeLocke只支持T/R',
    MoreThanTwo: 'R的数量必须大于等于2',
    AccountSynchronizing: '该用户TimeLock已经开启',
    // deviceSynchronizing: 'Exist device synchronizing'
    IpSynchronizing: '设备已经开启TimeLock的相同内网IP的设备',
    notSynchronizing: '没有Sync，不可以更新',
    failedGetFeature: '从TPC中校验TimeLock Feature 失败',
    failedGetMainR: '无法获取主R',
    mainRcantRemove: '主R不能被移除',
    syncSuccess: '同步成功',
    removeSyncSuccess: '取消同步成功',
    sourcePeerId: '视频PID',
    noPermission: '对不起，您没有移除权限',
    otherOccurDevices: '正在进行time lock，用户：',
    askUsersRelease: '请在继续操作前联系这些用户释放设备。',
    askUserRelease: '请在继续操作前联系该用户释放设备。',

    // ecoPreview
    ecoPreviewBtn: '经济模式',
    exitPreviewBtn: '退出经济模式',
    editTime: '计时编辑',
    previewSetting: '经济模式-计时设置',
    timer: '计时',
    laterPreview: ' 分钟后退出经济模式预览。',
    remainPreview: '保持经济模式预览，直到手动退出。',
    visitWebR: '访问WebR',
    refreshIP: '刷新IP映射',

    pictureSize: '图片需小于480*640',
    offsetX: 'X轴偏移',
    offsetY: 'Y轴偏移',
    // transcribre
    CaptionPosition: '字幕位置：',
    Lower: '下方',
    Upper: '上方',
    wordReplace: '启用文字替换：',
    ManageTxt: '管理',
    speakerRecogition: '说话人识别：',
    onlySupportEng: '启用本功能后，仅支持识别英语。',
    scheduleTimeTxt: '预约时间：',
    MayInterruptLive: '启用实时字幕或更改延迟设置可能会中断直播，请确认是否继续？',
    SetSmartCaption: '设置智能字幕',
    lowerAdd: '添加',
    startTimeEmpty: '请选择开始时间',
    endTimeEmpty: '请选择结束时间',
    CaptionFontFamily: '字幕字体：',
    wordReplacement: '文字替换',
    OriginalWord: '原始文字',
    ReplacedWord: '替换文字',
    PleaseInputWord: '请输入文字',
    outputStreaming: '输出正在推送，请先停止',
    encoderOneNDI: '每个编码器只能有一路NDI',

    ExternallOnlyIp: '外网IP',
    ExternallPort: '外网端口',
    LocalIP: '本地IP',
    LocalPort: '本地端口',
    controlAndPort: '页面管理和流端口',
    restartToEffect: '请重启接收机服务，设置方可生效',
    customLogo: '自定义图标',
    videoBitDepth: ' 视频比特位深',
    stayLastFrame: '传输中断时停留在最后一帧',
    VLANClientInfo: 'VLAN 客户端信息',
    VLANInterface: 'VLAN 接口：',
    VLANMAC: 'VLAN MAC：',
    IPLabel: 'IP：',
    MASKLabel: '掩码：',
    VLANServerInfo: 'VLAN 服务器信息',
    ServerIPHost: '服务器IP 或Host',
    PortLabel: '端口：',
    connectServer: '连接VLAN 服务器',
    ConnectedDevices: '已连接设备',
    VLANClient: 'VLAN 客户端',
    VLANServer: 'VLAN 服务器',
    ConnectBtn: '连接',
    beUserdVLAN: ' 将用作VLAN服务器，无法用于常规的直播传输或互联网访问。确认要继续吗？',
    MainLevel: '主层',
    IPAndRPort: '接收机IP & 端口',
    getIP: '获取IP',
    InputContent: '请输入内容',
    gridDescriptionRight: '开始/停止直播时打开事件描述弹出窗口',
    limitBelow: '图片大小在{0}kb以内',

    updateSlugFailed: '描述信息更新失败',
    gridTitle: '事件',

    byChosen: '按选择顺序',
    byName: '按名称',
    byStatus: '按状态',

    Advanced: '高级设置',
    AdvancedMore: '高级设置...',
    bufferSize: '接收缓冲区大小',
    connectTimeout: '连接超时',
    rwTimeout: '读写超时',
    passphrase: '密码短语',
    additional: '附加',
    shareFeedBack: '反馈分享',

    sameExtName: '该Ext已存在，与{name}源重复',
    realTrackCantChange: '实时地图模式切换语言可能会导致轨迹偏差，请谨慎操作',
    startNewEvent: '你确定要开启一个新事件吗？',
    replaceEvent: '你确定要开启一个新事件吗？当前事件将终止。',
    stopEvent: '你确定要终止这个事件吗？',
    updateEvent: '你确定要更新这个事件的设置吗？',
    SDIFormat: '3G-SDI格式',
    ReceiverLiveWithOther: '您现在要开始使用新源进行 LIVE 吗？',
    /* General */
    EasternTime: '东部标准时间',
    NTPService: '自定义NTP服务',
    NTPContent: '默认使用系统NTP服务。可以打开自定义NTP<br/>服务进行更改。请注意，如果设备启用了<br/>“时间同步锁定”，NTP将不会生效。',
    DomainName: 'IP / 域名',
    NTPOccupy: '该设备已开启“时间同步锁定”，时间将和{name}保持同步。',
    stopTips: '结束直播传输时发出提醒',
    defaultDelay: '默认延时和比特率',
    switchRegionTips: '重启接收机服务后，切换区域方可生效。',
    confirmSwitch: '确认切换',
    NTPEnterTips: '请输入NTP服务 IP/域名和端口',
    DelaySecond: '延迟（秒）',
    BitrateKbps: '码率（kbps）',

    TryNewUI: '尝试新界面',
    // Passthrough Streaming
    passThroughStream: '直接推流',
    Protocol: '协议',
    Mode: '模式',
    Caller: 'Caller',
    Listener: 'Listener',
    ListenerPortTip: 'Listener 端口范围是60000-65000',
    IpNotEmpty: 'IP不能为空',
    portNotZero: '端口不能为0',
    delayTip: 'SRT 延时范围是20-8000ms',
    // Remote File Storage
    remoteFileStorage: '远程文件存储',
    DestinationName: '目的地名称',
    accessKeyId: '访问密钥 ID',
    parallelThread: '并行数量',
    secretAccessKey: '访问密钥',
    dataConnType: '数据类型',
    autoPassive: 'Auto Passive',
    cannotEmpty: '输入不能为空',
    destinationManage: '目的地管理',
    keyOrUserName: '密钥ID/用户名',
    keyPassword: '密钥/密码',
    uploadPreferences: '上传首选项',
    destinationManagemention: '目的地管理',
    uploadDestination: '上传目的地',
    deleteAfter: '上传后删除',
    remoteDirectory: '远程目录',
    uploadRecord: '上传录制',
    uploadModule: '模块',
    retry: '重试',

    FollowLive: '输出状态与直播开启/关闭保持一致',
    preAssignPid: '预分配PID',
    durationHour: '时长(小时)',
    trackTips: '（仅支持最近一个月的数据）',
    confirmModeChange: '您确认使用{mode}模式吗？',
    keepRestart: '关机后保留相关配置',
    Restore: '重启',

    onlineNotify: '设备上线通知',
    offlineNotify: '设备离线通知',
    advanceControlOption: '高级控制',
    openWebR: '打开收发机控制页面（适用于7.8及以下软件版本，收发机网络端口需打开）',
    release: '释放',
    // workbench
    CreateCloudR: '创建新云机',
    CloudReceiver: '云机',
    Custom: '自定义',
    DoesNotRepeat: '不重复',
    Daily: '每天',
    EveryWeekday: '每个工作日（周一到周五）',
    EveryWeekend: '每个周末（周六周日）',
    RepeatEvery: '循环每',
    RepeatOn: '循环在',
    Ends: '结束',
    Never: '永不',
    // On: '至',
    After: '达到',
    occurrences: '次',
    MonthlyOnDay: '每月第{Day}天',
    MonthlyOnWeek: '每月的{SeveralFew}{WeeksSeveral}',
    first: '第一个',
    // second: '第二个',
    third: '第三个',
    fourth: '第四个',
    fifth: '第五个',
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    Friday: '星期五',
    Saturday: '星期六',
    Sunday: '星期日',
    EveryFewDays: '每 {number} 天',
    AWeekAFew: '每周{week}',
    EveryFewWeeks: '每 {number} 周{week}',
    MonthlyDay: '每月在第 {day} 天',
    MonthlyFirstWeeks: '每月{first}{week}',
    EveryMonthlyDay: '每 {number} 个月在第 {day} 天',
    EveryMonthlyFirstWeeks: '每 {number} 个月{first}{week}',
    HowTimes: ', 重复 {times} 次',
    UntilTime: ', 到 {time} 结束',
    fromUntil: '从 {start} 至 {end}',
    fromLess: '从 {start}',
    setStartEnd: '请设置开机和关机时间',
    selectFuture: '请选择一个未来的时间',
    startUpperStopTime: '开机时间需早于关机时间',
    startUpperEndDate: '开始日期需早于结束日期',
    selectAdmin: '请选择需要分配的admin账号',
    saveFirst: '请先保存计划设置',
    onlyOne: '仅当前时间',
    allSchedule: '所有预定计划',
    confirmDeleteCloudR: '你确定要删除吗?',
    cloudRLimit: '您已达到{num}个的数量上限。请先删除不使用的云机。',
    gotIt: '知道了',
    createProject: '创建云机',
    editProject: '编辑云机',
    projectName: '云机名称',
    inputInstanceName: '请输入云机名称',
    startingWait: '开机中，请稍后',
    noMatch: '暂无数据',
    updateSuccess: '更新成功',
    hardware: 'Hardware',
    NoSource: '没有可用的视频源',
    transmissionError: '传输错误率',
    batteryError: '低电量',
    noData: '无结果，请查看你的搜索条件和筛选设置'
  }
}
