import Vue from 'vue'
import Vuex from 'vuex'
/*
 * import axios from '@/assets/js/axiosExtend.js'
 * import Bus from '@/assets/js/vueBus.js'
 */
import commonModule from '@/store/common/index'
import homeModule from '@/store/home/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageShow: '',
    settingMapShow: false,
    mediaJsUrl: '',
    mapLoaded: 'false',
    userBehaviors: '',
    selectTId: '',
    ReceiverInfoObj: {},
    GetKernelStateObj: {},
    SettingObj: {
      CurrentSetting: {
        Name: 'Internal_Live'
      },
      ModeList: []
    },
    packList: [],
    onlinePackList: [],
    isFristInitWebrtc: true,
    showConfigT: true,
    showBg: true,
    showFeatureControl: true,
    webrtcUrl: 'http://10.12.23.232:9001',
    dashboardObj: {
      cpu: {},
      disks: {},
      memory: {},
      swap: {},
      sysinfo: {},
      user: {}
    },
    psdashVersion: '',
    clientId: '7b375a08-8177-4ec6-ada5-587979a1693c',
    showFeatureObj: {
      gridEncoder: false,
      transcriber: false,
      featureControl: false,
      visionTag: false,
      showVisionTag: true,
      IPStreamOut: false,
      isCoreSetting: true
    },
    interputObj: {
      userObj: true
    },
    channelNumber: 2,
    isFilePreview: false,
    currentSelectTab: 0,
    selectClipStartTime: 0,
    selectClipEndTime: 0,
    progressTid: '',
    selectSourceId: '',
    selectSourceName: '',
    selectSourceStatus: '',
    selectSourcePlatform: '',
    selectSourcePlatformType: '',
    selectSourceType: '',
    selectSourceLivePeerId: '',
    selectLastRId: '',
    selectCurrentRId: '',
    hasTranscriberFeature: false,
    websocket: {
      rInfoWebsocket: null,
      pageRequest: null,
      pageRWebsocket: null,
      pageAnyWheresockets: null
    },
    baseUrl: {},
    baseUserInfo: {},
    testDataUrl: '/ccp/tvucc-rws/receiver/command',
    selectRId: '',
    selectRStatus: 1,
    selectRIp: '',
    selectRPort: '',
    voipInfo: { status: 0, peerId: '' },
    // 用来做兼容的
    currentRVersion: null,
    allMySourceData: [],
    drawDownSource: null,
    receiverRList: [],
    changingR: false,
    featureFlag: {
      shareTransmission: false,
      liveOnHoldFlag: false,
      showThumbnails: true,
      startTransmission: true,
      stopTransmission: true,
      aleartMessage: true,
      gridDescriptionConfirm: false,
      onlineNotify: true,
      offlineNotify: true,
      lowBatteryNotify: true,
      errorNotify: true,
      userDeviceBatteryInfo: null
    }
  },
  mutations: {
    websocket (state, data) {
      state.websocket = data
    }
  },
  /*
   * mutations,
   * actions,
   */
  modules: {
    common: commonModule,
    home: homeModule
  }
})
