// 转换format信息
const TRANSFORMFORMAT = val => {
  switch (Number(val)) {
    case 0:
      return 'NTSC'
    case 2:
      return 'PAL'
    case 5:
      return '1080P25'
    case 6:
      return '1080P29.97'
    case 7:
      return '1080P30'
    case 8:
      return '1080I50'
    case 9:
      return '1080I59.94'
    case 11:
      return '720P50'
    case 12:
      return '720P59.94'
    case 13:
      return '720P60'
    case 14:
      return '1080P50'
    case 15:
      return '1080P5994'
    case 16:
      return 'UHD 4KP25'
    case 17:
      return 'UHD 4KP29.97'
    case 22:
      return '1080P60'
    case 24:
      return 'UHD 4KP30'
    default:
      return 'N/A'
  }
}

export default {
  TRANSFORMFORMAT
}
