<template>
  <div id="headerBox">
    <div
      v-show="showMobile"
      id="mobHeader">
      <div
        class="mobFilter closeFilterMenu"
        v-show="!toggleHeaderMobMenu">
        <div
          class="iconfont i-drag nav_mob_filter"
          @click="filterSectionToggle"></div>
      </div>
      <div
        class="leftSideMobSection"
        v-show="toggleHeaderMobMenu">
        <div
          class="mobFilter showFilterMenu"
          v-show="toggleHeaderMobMenu">
          <div
            class="el-icon-close closeBtn"
            @click="filterSectionToggle"></div>
        </div>
        <div
          id="navCommonMob"
          class="nav-common"></div>
      </div>
    </div>
    <tvu-top-header
      :projectName="showMobile? '' : $t('lang.title')"
      :globalFunList="['waffle', 'account', 'helpCenter']"
      ref="tvuccHeader"
      theme="green"
      :tvuLogo="!showMobile"
      :logoUrl="logoUrl"
      @callback="topHeaderCallback">
      <template #top-header-version>
        <div>{{ version }}</div>
      </template>
      <template #top-header-middle>
        <div
          v-show="!showMobile"
          id="navCommon"
          class="nav-common">
        </div>
        <div
          class="logoContant"
          v-show="showMobile">
          <div class="tvuLogo f_left"></div>
          <div class="slotBox f_left">
            <div
              class="receiverText"
              :title="version">{{$t('lang.title')}}</div>
          </div>
        </div>
        <a
          v-if="StateObj.common.baseUrl.showNewUI"
          class="backBtn"
          :href="switchUrl"
          @click.stop="setBehavior">{{ $t('lang.TryNewUI') }}</a>
      </template>
      <template #account-center-module>
        <div
          class="switchSupport"
          v-if="supportViewAccount && supportViewAccount != ''"
          @click="logout('changeToSupport')">
          <span><i class="iconfont">&#xe6cf;</i>{{$t('lang.switchTo')}} : {{supportViewAccount.supportUserEmail}}</span>
        </div>
        <div class="language">
          <span>{{ $t('lang.Language') }}</span>
          <Language
            :langOps='langOps'
            class="f_right"></Language>
        </div>
      </template>
    </tvu-top-header>
  </div>
</template>
<script>
import Language from './Language'
import Bus from '@/assets/js/vueBus.js'
import { mapState } from 'vuex'
import Tool from '@/assets/js/utils'

export default {
  name: 'HeaderCc',
  props: ['height', 'background'],
  components: {
    Language
  },
  data () {
    return {
      isPromptAlert: true,
      activeIndex: '1',
      menuList: [],
      headerCss: {
        height: this.height || 80,
        zoom: 0
      },
      langOps: {
        height: 28,
        width: 110,
        color: '#fff'
      },
      localLanguage: navigator.language,
      // seen:false,
      toggleHeaderMobMenu: false,
      setGridNumTimer: null, // 向Grid标签添加数量显示的定时器
      more10S: 1, // 超过10次清除定时器
      supportViewAccount: null, // 这个数组有数据代表,是support通过setting页面monitor操作用户跳转过来的 =>
      startLiveAlert: true,
      stopLiveAlert: true,
      alertTime: 1,
      setAlertNumTimer: null, // 向Grid标签添加数量显示的定时器cc-ms-dev.tvunetworks.com/user-group 10.12.22.24:8081
      switchUrl: '',
      onlineNotify: true,
      offlineNotify: true,
      logoUrl: require('../assets/img/logo.png'),
      showMobile: false
    }
  },
  computed: {
    ...mapState({
      StateObj: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      pageSerBusRequest: state => state.websocket.pageSerBusRequest,
      featureFlag: state => state.featureFlag,
      errorNotify: state => state.featureFlag.errorNotify,
      lowBatteryNotify: state => state.featureFlag.lowBatteryNotify
    })
  },
  watch: {
    errorNotify: {
      handler (val, oldVal) {
        this.setBatteryAndError(val, oldVal, 'liveErrorRate')
      },
      immediate: true
    },
    lowBatteryNotify: {
      handler (val, oldVal) {
        this.setBatteryAndError(val, oldVal, 'battery')
      },
      immediate: true
    }
  },
  created () {
    this.switchUrl = `${window.location.protocol}//${this.StateObj.common.baseUrl.switchUrl}`
    this.getVersion()
    Bus.$on('jumpLoginPage', () => {
      this.jumpLogin()
    })
    this.updateAccount('init')

    Bus.$on('postMessage', data => {
      this.postMessage(data)
    })
    window.addEventListener('message', event => {
      // const V3Url = 'https://local.tvunetworks.com:8081'
      const V3Url = `${window.location.protocol}//${this.$store.state.common.baseUrl.switchUrl}`
      if (event.origin === V3Url || `${event.origin}/tvucc` === V3Url) {
        // 处理接收到的消息
        const obj = Tool.isJSON(event.data) ? JSON.parse(event.data) : event.data
        if (obj && obj.type && obj.type == 'monitor') {
          // this.setSupportMonitor(obj.userChangeInfo)
          this.setClearMonitor({
            userChangeInfo: obj.userChangeInfo,
            isMonitor: true
          })
        }
      }
    })
  },
  async mounted () {
    Bus.$on('sourceId', data => { // 但获取到当前R的id的时候 创建websocket
      this.StateObj.selectRId = data && data.peerId
    })
    window.pageRequest = this.$store.state.websocket.pageRequest
    this.addGridNotify()
    this.addStatusNotify()
    this.addAlertNotify()

    $(window).bind('hashchange', () => {
      this.toggleHeaderMobMenu = false
      $('#pro_nav_icon_bg').show()
    })
    $(window).resize(() => {
      this.changeStyle()
    })
    this.changeStyle()
  },
  methods: {
    setBatteryAndError (val, oldVal, type) {
      console.log(1111, val && (val != oldVal || !this.pageSerBusRequest || !this.pageSerBusRequest.sendMsgs[type]))
      if (val && (val != oldVal || !this.pageSerBusRequest || !this.pageSerBusRequest.sendMsgs[type])) {
        this.addDeviceInfoNotify(type)
      } else if (!val && val != oldVal) {
        this.setNotify([], type)
      }
    },
    addGridNotify () {
      if (this.$createWebsocket.pageRequest) { // 新添加给grid导航标签添加正在请求数量显示的功能
        this.$createWebsocket.pageRequest('stop', 'getGridNotifyNum') // 停止websocket
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'getGridNotifyNum',
          module: 'getGridNotifyNum',
          data: ''
        },
        time: 0,
        key: 'getGridNotifyNum',
        success: res => { // 第一次先发送一次websocket请求 获取初始数据
          if (this.$createWebsocket.pageRequest) { // 获取到数据之后,停止socket 以后都是30s获取一次
            this.$createWebsocket.pageRequest('stop', 'getGridNotifyNum') // 停止websocket
          }
          this.setNumber(res.result)
        }
      })
    },
    addAlertNotify () {
    // alert消息
      const timer = Number(this.StateObj.common.baseUrl?.getAlertMsgTimer || 30 * 1000) + Math.floor(Math.random() * 9000)+1000
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'alertMsg',
          module: 'alertMsg',
          data: ''
        },
        time: timer,
        key: 'alertMsg',
        success: res => {
          Bus.$emit('alertNotReadMsg', res.result.notReadMsg.length)
          this.addNoReadIcon(res.result.notReadMsg.length)
          if (!this.featureFlag.aleartMessage) return
          if (res.result.notReadMsg.length) {
            res.result.newMsg.map((v, i) => {
              setTimeout(() => {
                this.$notify({
                  title: v.title,
                  dangerouslyUseHTMLString: true,
                  offset: 120,
                  type: 'warning',
                  customClass: 'alert_message_box',
                  message: `<div><span>${this.$t('lang.from')}:</span><span>${v.sender_name}</span>`
                })
              }, i)
            })
          }
        }
      })
    },
    addStatusNotify () {
      // 全局消息提醒
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'deviceStatusChange',
          module: 'deviceStatusChange',
          data: ''
        },
        time: 0,
        isOnce: true,
        key: 'deviceStatusChange',
        success: res => {
          const data = res.result.device
          const oldStatus = res.result.oldStatus
          const newStatus = res.result.newStatus
          let msg
          let flag = false
          const title = data.name
          if (((oldStatus == '0' && newStatus == '1') || (oldStatus == '0' && newStatus == '2')) && this.featureFlag.onlineNotify) {
            msg = `${this.$t('lang.onlined')}`
            flag = true
          } else if (((oldStatus == '2' && newStatus == '0') || (oldStatus == '1' && newStatus == '0')) && this.featureFlag.offlineNotify) {
            msg = `${this.$t('lang.offlined')}`
            flag = true
          } else if (oldStatus == '1' && newStatus == '2') {
            msg = `${this.$t('lang.startLiveTip')}`
            if (!this.featureFlag.startTransmission) {
              flag = false
            } else {
              flag = true
            }
          } else if (oldStatus == '2' && newStatus == '1') {
            msg = `${this.$t('lang.stopLiveTip')}`
            if (!this.featureFlag.stopTransmission) {
              flag = false
            } else {
              flag = true
            }
          }
          if (flag) {
            this.$notify({
              title: title,
              message: msg,
              customClass: 'notifyGlobal ccNotifyStyle',
              offset: 120,
              duration: 5000
            })
          }
        }
      })
    },
    // 设备电量及错误率提示
    addDeviceInfoNotify (type) {
      if (!this.pageSerBusRequest) {
        setTimeout(() => {
          this.addDeviceInfoNotify(type)
        }, 500)
      } else {
        this.pageSerBusRequest && this.pageSerBusRequest.send({
          message: {
            type: type,
            module: type,
            serverName: 'tvucc-alert',
            data: JSON.stringify({
              module: type,
              type: type,
              data: { userId: localStorage.getItem('userId') }
            })
          },
          time: 0,
          isOnce: true,
          key: type,
          success: res => {
            if (res.module == 'battery' && this.featureFlag.lowBatteryNotify || res.module == 'liveErrorRate' && this.featureFlag.errorNotify) this.setNotify(res.result, res.module)
          }
        })
      }
    },
    setNotify (list, module) {
      const className = module == 'battery' ? 'batteryNotify' : 'errorNotify'
      const title = module == 'battery' ? `${this.$t('lang.batteryError')} <span class="notifyNum">${list.length}</span>` : `${this.$t('lang.transmissionError')} <span class="notifyNum">${list.length}</span>`
      const MessageDom = document.getElementsByClassName(className)
      if ((!MessageDom || !MessageDom.length) && list.length) {
        let listHTML = '<div class="deviceNotify"><ul>'

        list.forEach(item => {
          listHTML += `<li class="${item.peerId}"><span>${item.name}</span><span class="right">${item.value}%</span></li>`
        })

        listHTML += '</ul></div>'
        this.$tvuNotification({
          title: title,
          customClass: `deviceInfoTip ccNotifyStyle ${className}`,
          dangerouslyUseHTMLString: true,
          showToggle: true,
          message: listHTML,
          offset: 120,
          duration: 0,
          onClose: () => {
            this.setUserDeviceBatteryInfo(module, list)
          }
        })
      } else {
        if (list.length) {
          let listHTML = '<ul>'
          list.forEach(item => {
            listHTML += `<li class="${item.peerId}"><span>${item.name}</span><span class="right">${item.value}%</span></li>`
          })
          listHTML += '</ul>'
          const parentElement = document.getElementsByClassName(className)[0]
          const childTitle = parentElement.getElementsByClassName('el-notifyNum')[0]
          const childListBox = parentElement.getElementsByClassName('deviceNotify')[0]
          childListBox.innerHTML = listHTML
          childTitle.innerHTML = list.length
        } else {
          while (MessageDom.length > 0) {
            MessageDom[0].parentNode.removeChild(MessageDom[0])
          }
        }
      }
    },
    setUserDeviceBatteryInfo (module, list) {
      if (module != 'battery') return
      const params = {
        pn: 'userDeviceBatteryInfo',
        content: JSON.stringify(list)
      }
      this.axios.post('/ccp/tvucc-user/userDevice/setPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        if (res.data.errorCode == '0x0') {
          this.$set(this.featureFlag, 'userDeviceBatteryInfo', JSON.stringify(list))
        }
      }).catch(() => {})
    },
    jumpLogin () {
      const { customeDirect } = this.$store.state.common.baseUrl
      const filterArr = customeDirect? customeDirect.filter(v => location.hostname.includes(v.domain)) : []
      const urlArr = JSON.parse(localStorage.getItem('newuserserviceLoginUrl'))
      let curUrl
      const userEmail = this.$store.state.common.baseUserInfo.email
      if (urlArr && urlArr.length) {
        for (let i = 0; i < urlArr.length; i++) {
          const emailArr = urlArr[i].email.split(';')
          if (emailArr.includes(userEmail)) {
            curUrl = JSON.parse(JSON.stringify(urlArr[i]))
            break
          }
        }
      }
      window.localStorage.setItem('NewCCsession', '')
      window.sessionStorage.setItem('userChangeInfo', '')
      if (curUrl && !filterArr.length) { // 有匹配的按匹配方式跳转
        window.location.href = `${window.location.protocol}//${curUrl.userservice}/newuserservice/?url=${window.location.protocol}//${curUrl.cc}/tvucc&serviceName=${localStorage.getItem('authServerName')}`
      } else if (filterArr.length) {
        window.location.href = filterArr[0].loginUrl
      } else { // 如果没有匹配的按老方式跳转
        if (window.localStorage.newCCInitConfig) {
          window.location.href = `${JSON.parse(window.localStorage.newCCInitConfig).urlInfo.newUserServiceUrl}/?url=${JSON.parse(window.localStorage.newCCInitConfig).urlInfo.newTvuccUrl}/&serviceName=${localStorage.getItem('authServerName')}`
        }
      }
    },
    topHeaderCallback (obj) {
      if (obj.type == 'sessionExpired') {
        this.jumpLogin()
      }
      if (obj.type == 'signOut') {
        this.logout('logout')
      }
    },
    setBehavior () {
      const parmas = {
        pn: 'header',
        content: JSON.stringify({ defaultVersion: 'CC3.0' }),
        remark: this.StateObj.common.baseUserInfo.id
      }
      this.axios.post('/ccp/tvucc-user/userBehavior/set', parmas).then(res => {
        if (res.status == 200) {}
      }).catch(() => {})
    },
    changeStyle () {
      this.showMobile = $(window).width() <= 1024
    },
    // 转换名字
    TranslateName (name) {
      if (typeof name == 'string') {
        return name.substr(0, 2)
      } else {
        return name
      }
    },
    updateAccount (tag) {
      const supportViewAccount = window.sessionStorage.getItem('userChangeInfo')
      if (supportViewAccount && JSON.parse(supportViewAccount)) {
        this.supportViewAccount = JSON.parse(supportViewAccount)
        this.$nextTick(() => {
          if (this.$refs.logoutBtn) {
            this.$refs.logoutBtn.show()
          }
          if (tag && tag == 'update') {
            try {
              this.$refs.tvuccHeader.getCurrentAccountInfo()
            } catch (error) {
              console.log(error)
            }
          }
        })
        this.$forceUpdate()
      } else {
        this.$nextTick(() => {
          if (this.$refs.logoutBtn) {
            this.$refs.logoutBtn.hide()
          }
          if (tag && tag == 'update') {
            try {
              this.$refs.tvuccHeader.getCurrentAccountInfo()
            } catch (error) {
              console.log(error)
            }
          }
        })
        this.supportViewAccount = null
      }
    },
    getVersion () {
      this.version = 'UI：' + window.localStorage.getItem('newCCVersion')
    },
    // 获取用户行为记录
    getUserBehavior (pn, remark) {
      const serverName = 'user'
      const params = { pn, remark, serverName }
      this.axios.get('/ccp/tvucc-user/userBehavior/get', {
        params: params
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          this.$store.state.userBehaviors = res.data.result ? res.data.result : {}
          Bus.$emit('isReturnUserBehaviors', true)
        }
      }).catch(() => {})
    },
    // 退出登入
    logout (options) {
      if (options === 'logout') {
        this.axios.get('/ccp/tvucc-user/user/logout').then(res => {
          const errorCode = res.data.errorCode
          if (errorCode === '0x0') {
            window.localStorage.setItem('NewCCsession', '')
            window.sessionStorage.setItem('userChangeInfo', '')
            // Config-T会存入TSID，在登出时应该清空
            Tool.clearTSID()
            window.location.href = window.location.protocol + '//' + res.data.result.redirectUrl
          }
        }).catch(() => {})
      } else if (options == 'changeToSupport') {
        this.$confirm(this.$t('lang.gotoSupportTips'), '', {
          cancelButtonText: this.$t('lang.cancel'),
          confirmButtonText: this.$t('lang.i18n_confirm'),
          cancelButtonClass: 'btn-custom-cancel',
          type: 'warning',
          iconClass: 'myIcon'
        }).then(() => {
          // this.clearSupportMonitor()
          this.setClearMonitor({
            email: this.supportViewAccount.supportUserEmail,
            isMonitor: false
          })
        }).catch(() => {})
      }
    },
    postMessage (data) {
      if ($('#iframeBox').length && $('#iframeBox')[0].contentWindow) {
        // const V3Url = 'https://local.tvunetworks.com:8081'
        const V3Url = `${window.location.protocol}//${this.$store.state.common.baseUrl.switchUrl}`
        $('#iframeBox')[0].contentWindow.postMessage(data, V3Url)
      }
    },
    async setClearMonitor (obj) {
      let canContinue = obj.isMonitor
      if (!obj.isMonitor) {
        const domain = document.domain.substring(document.domain.indexOf('.') + 1)
        await this.axios.get(`/ccp/tvucc-user/user/monitorSwitch?email=${obj.email}&domain=${domain}`).then((res) => {
          if (res.data.errorCode == '0x0') {
            canContinue = true
          } else {
            const errorInfo = this.$t(toTips[res.errorCode]) || res.errorInfo
            this.$message.error(errorInfo)
          }
        }).catch(() => {
        })
      }
      if (canContinue) {
        let pathKey = this.$route.path == '/st' ? 'stS' : 'st'
        let userChangeInfo = ''
        if (obj.isMonitor) {
          userChangeInfo = obj.userChangeInfo
        } else {
          window.sessionStorage.setItem('userChangeInfo', '')
        }
        window.sessionStorage.setItem(
          'userChangeInfo',
          JSON.stringify(userChangeInfo)
        )
        // 重新获取导航
        let langH = window.localStorage.getItem('lang')
        window.headerObj = new headerTemplate(langH)
        // 获取User tag前端重写
        this.getUserTags()
        this.updateAccount('update')
        this.$createWebsocket.pageRWebsocket('off')
        this.$createWebsocket.pageRequest('off')
        this.$router.push({ path: `/${pathKey}` }).catch(() => {})

        this.$nextTick(() => {
          this.$createWebsocket.pageRequest(' ')
          this.$createWebsocket.pageRWebsocket('', '', 'sendHeart')
          window.pageRequest = this.$store.state.websocket.pageRequest
        })
      }
    },
    filterSectionToggle () {
      this.toggleHeaderMobMenu = !this.toggleHeaderMobMenu
      if (this.toggleHeaderMobMenu) {
        $('#pro_nav_icon_bg').hide()
      } else {
        $('#pro_nav_icon_bg').show()
      }
    },
    getUserTags () {
      const userGroupUrl = this.StateObj.common.baseUrl.userGroupUrl
      if (!userGroupUrl) return
      this.axios.get(`${userGroupUrl}/user-group/user/getUserTag`).then(res => {
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          const addTags = result.addTags
          const delTags = result.delTags
          const domainName = window.location.host.includes('com') ? '.tvunetworks.com' : '.tvunetworks.cn'
          const httpsFlag = window.location.protocol.includes('https')
          const exp = new Date()
          exp.setTime(exp.getTime() - 1)
          delTags.forEach(itm => {
            window.document.cookie = `${itm}=;path=/;domain=${domainName};expires=${exp.toGMTString()}`
          })
          addTags.forEach(itm => {
            window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${domainName};${httpsFlag ? 'secure' : ''}`
          })
        }
      }).catch(() => {})
    },
    // alert导航栏添加圆点
    addNoReadIcon (num) {
      if (!$('nav .itemMenu')) {
        setTimeout(() => {
          this.addNoReadIcon(num)
        }, 600)
        return
      }
      if (!Number(num)) {
        if ($('nav .itemMenu[datakey="al"] .alert_icon').length) $('nav .itemMenu[datakey="al"] .alert_icon').remove()
        if ($('.moreBtn>span .alert_icon').length) { //
          $('.moreBtn>span .alert_icon').remove()
        }
        return
      }
      if ($('.itemMenu').length > 0) {
        $('.itemMenu').map((index, item) => {
          if ($(item).attr('datakey') == 'al') {
            if ($(item).find('.gridNumber').length > 0) {
              $(item).find('.gridNumber').remove()
            } else if ($(item).find('.gridNumberGrid').length > 0) {
              $(item).find('.gridNumberGrid').remove()
            }
            if (num > 0) {
              let positionClass = 'gridNumber'
              if ($(item).hasClass('verticalTab')) {
                positionClass = 'gridNumberGrid'
              }
              $(item).append('<span class="' + positionClass + '" title="' + num + '">' + num + '</span>')
            }
            clearInterval(this.setGridNumTimer)
          }
        })
      }
    },
    setNumber (num) {
      this.setGridNumTimer = setInterval(() => { // 防止导航栏没有加载,设置定时器直到获取到导航栏/或10秒下个websocket创建为止.
        if (this.more10S > 10) {
          clearInterval(this.setGridNumTimer)
        }
        if ($('.itemMenu').length > 0) {
          $('.itemMenu').map((index, item) => {
            if ($(item).attr('datakey') == 'gm') {
              if ($(item).find('.gridNumber').length > 0) {
                $(item).find('.gridNumber').remove()
              } else if ($(item).find('.gridNumberGrid').length > 0) {
                $(item).find('.gridNumberGrid').remove()
              }
              if (num > 0) {
                let positionClass = 'gridNumber'
                if ($(item).hasClass('verticalTab')) {
                  positionClass = 'gridNumberGrid'
                }
                $(item).append('<span class="' + positionClass + '" title="' + num + '">' + num + '</span>')
              }
              clearInterval(this.setGridNumTimer)
            }
          })
        } else {
          this.more10S++
        }
      }, 1000)
      const timer = Number(this.StateObj.common.baseUrl?.getGridNotifyTimer || 30 * 1000) + Math.floor(Math.random() * 9000)+1000
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'getGridNotifyNum',
          module: 'getGridNotifyNum',
          data: ''
        },
        time: timer,
        key: 'getGridNotifyNum',
        success: res => {
          if ($('.itemMenu').length > 0) {
            $('.itemMenu').map((index, item) => {
              if ($(item).attr('datakey') == 'gm') {
                if ($(item).find('.gridNumber').length > 0) {
                  $(item).find('.gridNumber').remove()
                } else if ($(item).find('.gridNumberGrid').length > 0) {
                  $(item).find('.gridNumberGrid').remove()
                }
                if (res.result > 0) {
                  let positionClass = 'gridNumber'
                  if ($(item).hasClass('verticalTab')) {
                    positionClass = 'gridNumberGrid'
                  }
                  $(item).append('<span class="' + positionClass + '" title="' + res.result + '">' + res.result + '</span>')
                }
                if (this.setGridNumTimer) {
                  clearInterval(this.setGridNumTimer)
                  this.setGridNumTimer = null
                }
              }
            })
          }
        }
      })
    }
  },
  beforeDestroy () {
    this.setGridNumTimer && clearInterval(this.setGridNumTimer)
    this.setAlertNumTimer && clearInterval(this.setAlertNumTimer)
  }
}
</script>
<style scoped lang="less">
.supportChangeColor {
  color: #60c871;
}

.supportDis {
  height: 45px;
  line-height: 45px;
  display: block;
  cursor: pointer;
}

.border {
  border-bottom: 1px solid #444;
}
#headerBox {
  background: #33ab4f;
  position: relative;
  z-index: 20000;

  .logoContant {
    position: absolute;
    left: 40%;
    transform: translateX(-40%);
    padding-right: 0 !important;
    min-width: 180px;
    height: 64px;
    line-height: 64px;
    .tvuLogo {
      background-size: 100% !important;
      background-position: center !important;
      width: 32px !important;
      height: 32px !important;
      margin: 15px 5px !important;
    }

    .slotBox {
      div {
        height: 64px;
        line-height: 64px;
        font-size: 0.16rem;
        font-weight: bold;
        color: #fff;
      }
    }
  }
  // #pcHeader {
  //   height: 100%;
  //   display: block;
    .operateR {
      height: 100%;
      width: 300px;
      .bilingual {
        color: #33ab4f;
      }
    }
    .backBtn {
      color: #33ab4f;
      background: #fff;
      display: inline-block;
      padding: 4px 12px;
      border: 1px solid #33ab4f;
      border-radius: 16px;
      position: absolute;
      right: 170px;
      top: 50%;
      transform: translateY(-50%);
      height: 30px;
      line-height: 20px;
    }

    .slotBox {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      div {
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        text-align: left;
        // width:200px;
      }
    }
    .usrBox {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding-right: 75px;
      &:hover {
        .userOperationWrap {
          display: block;
        }
      }
      .userBtn {
        width: 45px;
        height: 45px;
        background: url('../assets/img/user.png') no-repeat;
        cursor: pointer;
        float: right;
        top: 50%;
        transform: translateY(-50%);
        i {
          font-size: 18px;
          margin-left: 45px;
          font-weight: bold;
          margin-top: 12px;
          color: #fff;
        }
      }
    }
    .userOperationWrap {
      width: 340px;
      position: absolute;
      top: 30px;
      right: 8px;
      padding-top: 12px;
      display: none;
      z-index: 11;
      color: #fff;
      background: #252525;
      border-radius: 6px 6px 0px 0px;
      &::after {
        content: "";
        border: 14px solid;
        border-color: transparent transparent #252525 transparent;
        position: absolute;
        top: -25px;
        right: 75px;
      }
      .userTop {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 12px 24px 0 24px;
        .headLeft {
          display: flex;
        }
        .nickName {
          width: 42px;
          height: 42px;
          line-height: 42px;
          font-size: 20px;
          background: #5052ec;
          border-radius: 50%;
          font-weight: 700;
        }
        .message {
          text-align: left;
          margin-left: 12px;
          width: calc(100% - 60px);
          .name {
            font-size: 14px;
            font-weight: bold;
            color: #dbdbdb;
            line-height: 17px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .email {
            font-size: 12px;
            color: #666666;
            line-height: 14px;
            margin-top: 4px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .jurisdiction {
          font-size: 14px;
          color: #dbdbdb;
          line-height: 17px;
        }
      }
      .userBottom {
        background: #333333;
        padding-bottom: 12px;
        border-radius: 0px 0px 6px 6px;
        box-shadow: 2px 4px #353333;
        .switchSupport {
          text-align: left;
          padding: 10px 0 5px 22px;
        }
        .Manager{
          line-height: 40px;
          height: 40px;
          color: #dbdbdb;
          cursor: pointer;
          padding: 0 24px;
          text-align: left;
        }
        .language {
          height: 40px;
          display: flex;
          padding: 0 24px;
          justify-content: space-between;
          align-items: center;
          div {
            color: #dbdbdb;
            cursor: pointer;
            .bilingual {
              color: #33ab4f;
            }
          }
        }
        .loginOut {
          padding: 0 24px;
          height: 40px;
          color: #dbdbdb;
          text-align: left;
          line-height: 40px;
          span {
            cursor: pointer;
          }
        }
      }
    }
  // }

  #mobHeader {
    height: 100%;
    width: 100%;
    position: absolute;

    .backBtn {
      color: #33ab4f;
      background: #fff;
      display: inline-block;
      padding: 4px 12px;
      border: 1px solid #33ab4f;
      border-radius: 16px;
      position: absolute;
      right: 130px;
      top: 50%;
      transform: translateY(-50%);
    }
    .showFilterMenu {
      margin: 12px 0 0 0.24rem;
    }

    .mobFilter {
      width: 55px;
      padding-top: 2px;
      font-size: 14px;

      .closeBtn {
        font-size: 0.3rem;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.56);
        color: #a0a0a0;
        cursor: pointer;
      }

      .nav_mob_filter {
        width: 55px;
        height: 55px;
        cursor: pointer;
        color: #fff;
      }

      .nav_mob_filter.i-drag:before {
        font-size: 40px;
        line-height: 64px;
      }
    }

    .leftSideMobSection {
      text-align: left;
      position: absolute;
      left: -1px;
      width: 180px;
      background: #252525;
      border: 1px solid rgb(68, 68, 68);
      margin: 0px;
      border-radius: 4px;
      z-index: 99;
      color: #fff;
      font-size: 0.15rem;
      width: 100%;
      padding-bottom: 10px;
    }
  }
}

/*覆盖elemen-ui drop样式*/
.el-dropdown {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

[v-cloak] {
  display: none !important;
}

@media (max-width: 1024px) {
  #headerBox {
    height: 64px !important;
    .loadSudokuIcon {
      top: 3px;
    }
  }
  #switchLanguage {
    width: 105px !important;
    background: transparent !important;
    color: rgb(255, 255, 255) !important;
    font-size: 18px !important;
    margin-left: -6px;
  }
}
</style>
<style lang="less">
.alert_message_box.el-notification {
  background-color: #444;
  box-shadow: 0px 0px 15px #100400;
  border: none;
  .el-notification__icon.el-icon-warning {
    color: #dc4e39;
    font-size: 20px;
  }
  .el-notification__title,
  .el-notification__content,
  .el-notification__closeBtn.el-icon-close {
    color: #fff;
  }
  .el-notification__title {
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .el-notification__content {
    width: 250px;
    > p > div > span {
      float: left;
    }
    > p > div > span:nth-child(2) {
      width: 225px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.alert_icon {
  position: absolute;
  top: 0;
  right: -20px;
  background-color: #f57c00;
  text-align: center;
  color: #fff;
  width: 19px;
  height: 19px;
  line-height: 19px;
  border-radius: 9.5px;
  padding: 0;
  font-size: 12px;
}
</style>
