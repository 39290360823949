import config from '@/config'
import $store from '@/store.js'

// 按钮背景色
export const BTNSTATUS = status => {
  return config.BtnStatus[status]
}
// 按钮名称
export const BTNNAME = status => {
  return config.BtnName[status]
}

export const WIDTHCLASS = data => {
  let type = data.type
  if (type === 'TVUPack' || type === 'TVUGrid') {
    if (type === 'TVUGrid') {
      return 'col-md-5 col-xs-5'
    } else {
      if (data.status != 0) {
        return 'col-md-7 col-xs-7 line'
      } else {
        return 'col-md-7 col-xs-7'
      }
    }
  } else {
    return 'col-md-6 col-xs-6'
  }
}

export const SHOWICON = PeerObj => {
  let obj = {}
  let data = {
    0: 'grayColor',
    1: 'greenColor',
    2: 'redColor'
  }
  // 根据状态绑定颜色
  Object.keys(data).map(v => {
    if (PeerObj.status == v) {
      obj[data[v]] = true
    }
  })
  // 根据类型绑定icon图标
  switch (PeerObj.type) {
    case 'TVUPack':
    case 'T':
      obj.packIcon = true
      break
    case 'Ext':
      obj.extIcon = true
      break
    case 'YouTube':
      obj.youtubeIcon = true
      break
    case 'TVUGrid':
    case 'X':
      obj.packIcon = true
      break
    case 'LocalSDI':
      obj.localSDIIcon = true
      break
    case 'SMPTE2022':
      obj.extIcon = true
      break
  }
  return obj
}

export const CANSUE = item => {
  const key = item.type
  const moudle = item.voipEnable ? 'VoIP' : 'IFB'
  if (key === 'TVUPack') {
    if (moudle == 'VoIP') {
      let enable = item.voipEnable
      if (enable && item.status != 0) {
        return false
      } else {
        return true
      }
    } else {
      if (item.IFBProperty) {
        let enable = item.IFBProperty.IfbEnabled
        if (enable && item.status != 0) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  }
}

export const RETURNSTATUS = item => {
  let _str = ''
  const type = item.type
  const module = item.voipEnable ? 'VoIP' : 'IFB'
  if (type === 'TVUPack') {
    if (module === 'VoIP') {
      if (item.voipSuccess || item.voipConnection) {
        _str = 'red'
      }
    } else {
      if (item.IFBProperty) {
        if (item.IFBProperty.Color === 'Red') {
          _str = 'red'
        }
      }
    }
  }
  return _str
}

export const SWITCHBUSSTATUS = item => {
  var _str = ''
  const type = item.type
  const IsRemotePreload = item.IsRemotePreload
  if (type === 'TVUGrid') {
    if (IsRemotePreload) {
      _str = 'green switch'
    } else {
      _str = 'white switch'
    }
  } else {
    _str = 'hide'
  }
  return _str
}
// 显示home页面缩略图
export const SHOWDEVICEIMG = item => {
  let _url = ''
  const blobUrl = $store.state.common.baseUrl.blobUrl
  // const blobUrl = '10.12.23.99:55443/t';
  if (!item) return
  const type = item.type
  const status = item.status
  const header = window.location.protocol.includes('https') ? 'https://' : 'http://'
  let configInfo = JSON.parse(localStorage.getItem('newCCInitConfig'))
  let isCompressThumbnail = '0'
  if (configInfo) isCompressThumbnail = JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.isCompressThumbnail
  switch (type) {
    case 'T':
      if (status === '2') {
        // _url = header + blobUrl + "/" + item.livePeerId.toUpperCase() + "?" + Math.random();
        _url = header + blobUrl + '/' + item.livePeerId.toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      } else if (status === '0') {
        _url = config.DefaultImgByStatus.T[0]
      } else if (status === '3') {
        _url = config.DefaultImgByStatus.T[3]
      } else {
        // _url = header + blobUrl + "/" + item.peerId.toUpperCase() + "?" + Math.random();
        _url = header + blobUrl + '/' + item.peerId.toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      }
      break
    case 'X':
      if (status === '2') {
        _url = header + blobUrl + '/' + item.peerId.toUpperCase() + '?'
        // _url = header + blobUrl + '/' + item.livePeerId.toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      } else if (status === '0') {
        _url = config.DefaultImgByStatus.X[0]
      } else if (status === '3') {
        _url = config.DefaultImgByStatus.X[3]
      } else if (status === '1000') {
        _url = config.DefaultImgByStatus.X[1000]
      } else if (status === '1001') {
        _url = config.DefaultImgByStatus.X[1001]
      } else {
        // _url = header + blobUrl + "/" + item.peerId + "?" + Math.random();
        _url = header + blobUrl + '/' + item.peerId + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      }
      break
    case 'YouTube':
      if (status === '1' || status === '0') {
        _url = config.DefaultImgByStatus.YouTube.img
      } else {
        // _url = header + blobUrl + "/" + item.livePeerId.split(';')[0].toUpperCase() + "?" + Math.random();
        _url = header + blobUrl + '/' + item.livePeerId.split(';')[0].toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      }
      break
    case 'Ext':
      if (status === '1' || status === '0') {
        _url = config.DefaultImgByStatus.Ext.img
      } else {
        // _url = header + blobUrl + "/" + item.livePeerId.split(';')[0].toUpperCase() + "?" + Math.random();
        _url = header + blobUrl + '/' + item.livePeerId.split(';')[0].toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      }
      break
    case 'SMPTE2022':
      if (status === '1' || status === '0') {
        _url = config.DefaultImgByStatus.SMPTE2022.img
      } else {
        // _url = header + blobUrl + "/" + item.livePeerId.split(';')[0].toUpperCase() + "?" + Math.random();
        _url = header + blobUrl + '/' + item.livePeerId.split(';')[0].toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      }
      break
    case null:
      if (status === '1' || status === '0') {
        _url = config.DefaultImgByStatus.Ext.img
      } else {
        // _url = header + blobUrl + "/" + item.livePeerId.split(';')[0].toUpperCase() + "?" + Math.random();
        _url = header + blobUrl + '/' + item.livePeerId.split(';')[0].toUpperCase() + '?'
        _url = isCompressThumbnail == '0' ? `${_url}type=original&app=cc&${Math.random()}` : `${_url}${Math.random()}&app=cc`
      }
      break
  }
  return _url
}

export const CHANGELANGUAGE = value => {
  let obj = {
    '英语(美国)': 'en-US',
    '日语(日本)': 'ja-JP',
    '葡萄牙语(葡萄牙)': 'pt-PT',
    '中文(简体，中国)': 'zh-Hans',
    'en-US': 'en-US',
    'ja-JP': 'ja-JP',
    'pt-PT': 'pt-PT',
    'zh-Hans': 'zh-Hans'
  }

  return obj[value]
}

export const FORMATSORT = obj => {
  let value
  config.SelectBySorts.map(v => {
    if (obj[v.value]) {
      value = v.value
    }
  })
  if (!value) {
    value = 'nameFirst'
  }
  return value
}

export const FORMATROLE = (val, role) => {
  if (role == 1) return i18n.t(`lang.${config.ManageRoleType[val]}`)
  return i18n.t(`lang.${config.RoleType[val]}`)
}

export const SETSOURCEICON = (val, flag) => {
  // flag为true表示除了返回文案，还需要返回每个类型的图标名称
  let { type, platform, peerId } = val
  let name, str, obj
  if (type == 'T') {
    if (platform < 10 || platform > 14) {
      name = 'TVUPack'
      str = '&#xe641;'
    }
    if (platform >= 10 && platform <= 14) {
      name = 'TVUAnywhere'
      str = '&#xe766;'
    }
    if (!platform) {
      name = 'unknow'
      str = ''
    }
    obj = {
      name,
      str
    }
  }
  if (type == 'X') {
    let str = peerId.substr(peerId.length - 1, 1)
    if (str == 1) {
      obj = {
        name: i18n.t('lang.Grid-Pack'),
        str: '&#xe763;'
      }
    }
    if (str == 2) {
      obj = {
        name: i18n.t('lang.Grid-SDI'),
        str: '&#xe762;'
      }
    }
  }
  if (type != 'T' && type != 'X' || !obj) {
    obj = {
      name: type,
      str: '&#xe725;'
    }
  }
  if (flag) {
    return obj.str
  } else {
    if (!obj.name) obj.name = ''
    if (!val.name) val.name = ''
    return `${obj.name}: ${val.name}`
  }
}

export const SETTINGTYPE = (val, flag) => {
  // flag为true表示除了返回文案，还需要返回每个类型的图标名称
  let { type, platform, peerId } = val
  if (platform == 5001) {
    return i18n.t('lang.RPSEncoder')
  }
  if (platform == 5002) {
    return i18n.t('lang.RPSDecoder')
  }
  if (type == 'R') {
    return i18n.t('lang.receiver')
  }
  if (type == 'T') {
    let name
    if (platform < 10 || platform > 14) {
      name = 'TVUPack'
    }
    if (platform >= 10 && platform <= 14) {
      name = 'TVUAnywhere'
    }
    if (!platform) {
      name = 'unknow'
    }
    return i18n.t(`lang.${name}`)
  }
  if (type == 'X') {
    let str = peerId.substr(peerId.length - 1, 1)
    if (str == 1) {
      return i18n.t('lang.Grid-Pack')
    }
    if (str == 2) {
      return i18n.t('lang.Grid-SDI')
    }
  }
  if (type == 'Ext') {
    return 'Ext'
  }
  if (type == 'YouTube') {
    return 'YouTube'
  }
  if (type == 'SMPTE2022') {
    return 'Ext'
  }
}

// 时间戳格式化时间格式
export const DATE = (date, fmt) => {
  if (!date) {
    return ''
  }
  date = date + ''
  if (date.length > 13) {
    date = date.slice(0, 13)
  }
  date = new Date(date * 1)

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
      )
    }
  }
  return fmt
}

// 时间戳格式化时间格式
export const UTCDATE = (date, fmt) => {
  if (!date) {
    return ''
  }
  date = date + ''
  if (date.length > 13) {
    date = date.slice(0, 13)
  }
  date = new Date(date * 1)

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getUTCFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getUTCMonth() + 1,
    'd+': date.getUTCDate(),
    'h+': date.getUTCHours(),
    'm+': date.getUTCMinutes(),
    's+': date.getUTCSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
      )
    }
  }
  return fmt
}

export const MAPLOACTION = obj => {
  let value = obj.status == 1 ? obj.automatic : obj.manual
  let firstValue = value && value.split(',')[0]
  firstValue = parseFloat(firstValue).toFixed(4)
  let secondValue = value && value.split(',')[1]
  secondValue = parseFloat(secondValue).toFixed(4)
  return firstValue + ',' + secondValue
}
export const BITRATE = obj => {
  if (obj.includes('K')) {
    obj = parseInt(obj.split('K')[0] || 0)
    let z = obj % 1024
    if (z === 0) {
      let n = obj / 1024
      return n + '.0M'
    } else {
      return obj + 'K'
    }
  } else {
    return obj
  }
}

export default {
  BTNSTATUS,
  WIDTHCLASS,
  SHOWICON,
  CANSUE,
  SHOWDEVICEIMG,
  SETSOURCEICON,
  RETURNSTATUS,
  SWITCHBUSSTATUS,
  BTNNAME,
  CHANGELANGUAGE,
  FORMATSORT,
  FORMATROLE,
  SETTINGTYPE,
  DATE,
  UTCDATE,
  MAPLOACTION,
  BITRATE
}
