import mutations from './mutations.js'

const state = {
  initLogin: true
}

export default {
  namespaced: true,
  state,
  mutations
}
