/**
 * 使用该指令需要传递一个对象
 * {
 *    onScroll: this.onScroll  滚动加载时触发的回调
 *    distance: 10, 距离底部多少距离的时候触发回调 默认是100px
 *    directive: '.el-select-dropdown .el-select-dropdown__wrap' 你需要绑定的dom的class，默认为绑定的指令的当前dom
 * },
 * *
 */
scroll.install = function (Vue) {
  // 必须出现滚动跳才可触发
  Vue.directive('scroll', {
    bind: (el, binding, vnode) => {
      // 是否执行回调事件
      let isCallback = true
      // 距离底部剩余距离开始触发回调
      let distance = binding.value.distance || 100
      // 以后绑定的哪个dom就将该DOM绑定给directive
      let elDom = binding.value.directive
        ? el.querySelector(binding.value.directive)
        : el
      // 监听滚动事件
      if (!elDom) {
        return
      }
      elDom.onscroll = e => {
        // 获取当前节点可滚动的距离
        let scrollHeight = e.target.scrollHeight - e.target.clientHeight
        // 获取节点剩余可滚动的高度
        let residualHeight = scrollHeight - e.target.scrollTop
        //  滚动到指定区域执行回调事件

        if (
          typeof binding.value.onScroll === 'function' &&
          residualHeight <= distance &&
          isCallback
        ) {
          // 执行事件回调函数
          binding.value.onScroll()
          isCallback = false
        } else if (residualHeight > distance) {
          isCallback = true
        }
      }
    }
  })
}
export default scroll
