<template>
  <div
    id="app"
    v-if="hasInitLang">
    <headerCc></headerCc>
    <el-main
      class="scrollbar"
      id="main-box"
      :class="{'iframeMain': dataMark.includes('rc')}">
      <router-view :key="dataMark"></router-view>
    </el-main>
    <notice
      :dialogVisible.sync="visible.notice"
      :msg='noticeMsg'></notice>
  </div>
</template>

<script>
import HeaderCc from '@/components/HeaderCc'
import Notice from '@/components/notice'
import { mapActions, mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import { loadSudoku, loadLanguageJs, loadHeaderJs } from '@/assets/js/loadSudoku.js'

export default {
  name: 'App',
  data () {
    return {
      dataMark: '',
      visible: {
        notice: false
      },
      noticeMsg: [],
      role: null,
      refreshCookieTimer: null
    }
  },
  components: {
    HeaderCc,
    Notice
  },
  computed: {
    ...mapState('common', {
      StateObj: state => state,
      hasInitLang: state => state.hasInitLang
    }),
    ...mapState({
      featureFlag: state => state.featureFlag
    }),
    accountType () {
      return this.$store.state.common.baseUserInfo.role
    }
  },
  beforeCreate () {
    const { mySourceUrl, customeDirect } = this.$store.state.common.baseUrl
    let urlVal = mySourceUrl || ''
    const filterArr = customeDirect ? customeDirect.filter(v => location.hostname.includes(v.domain)) : []
    if (urlVal && location.hostname != urlVal && !filterArr.length) {
      let hostVal = `${window.location.protocol}//${JSON.parse(window.localStorage.newCCInitConfig).urlInfo.mySourceUrl}/${location.hash}${location.search}`
      if (process.env.NODE_ENV === 'production') location.href = hostVal
    } else if (filterArr.length) {
      location.href = filterArr[0].loginUrl
    } else {
      location.href = `${location.origin}${location.pathname}${location.hash}${location.search}`
    }
    this.$createWebsocket.pageRequest()
    this.$createWebsocket.pageSerBusRequest()
    this.$createWebsocket.pageRWebsocket('', '', 'sendHeart')
  },
  created () {
    // 当initConfig成功时存储变量，加载九宫格
    loadSudoku(window.localStorage.newCCInitConfig && JSON.parse(window.localStorage.newCCInitConfig).urlInfo || {})
    loadLanguageJs()
    loadHeaderJs()
    this.getPromptMsgCont()

    Bus.$on('roleInfo', async role => {
      if (this.role !== null || !['3', '4'].includes(role)) return
      this.role = role
      await this.showNotice()
      setTimeout(() => {
        if (this.visible.notice) $('body .v-modal').addClass('notice')
      }, 100)
    })
  },
  mounted () {
    this.setRem()
    window.onunhandledrejection = event => {
      console.error('Unhandled Promise rejection:', event.reason)
    }
    this.refreshCookieTimer = setInterval(() => {
      const rtcVideoArr = document.querySelectorAll('.videoLabelCheckCookie')
      let hasVideo = false
      if (rtcVideoArr.length > 0) {
        rtcVideoArr.forEach((v, index) => {
          const dom = document.getElementsByClassName('videoLabelCheckCookie')[index]
          const domParent = dom.parentNode
          const style = window.getComputedStyle(domParent)
          const display = style.display
          if (display != 'none') hasVideo = true
        })
        if (hasVideo) {
          this.axios.get('/ccp/initLoginUser').then(() => {
          }).catch(() => {})
        }
      }
    }, this.StateObj.baseUrl && this.StateObj.baseUrl.refreshCookieTimer || 10 * 60 * 1000)
  },
  methods: {
    ...mapActions('common', [
      'baseUrl',
      'baseUserInfo'
    ]),
    getBaseUserInfo () {
      this.baseUserInfo('baseUserInfo')
    },
    setRem () {
      let rem = 100 // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      $('html').css('font-size', `${rem}px`)
      $(window).resize(() => {
        let rem = 100 // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        $('html').css('font-size', `${rem}px`)
      })
    },
    // 是否为第一次登陆进来
    isLogin () {
      if (localStorage.getItem('NewCCsession') != localStorage.getItem('lastSession')) {
        localStorage.setItem('lastSession', localStorage.getItem('NewCCsession'))
        return true
      }
      return false
    },
    getPromptMsgCont () {
      this.axios({
        method: 'post',
        url: '/ccp/tvucc-user/userDevice/listPreference',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: {}
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result.length > 0) {
            const list = res.data.result
            list.map(v => {
              if (this.accountType == 2 && (v.pn == 'errorNotify' || v.pn == 'lowBatteryNotify')) v.content == 'false'
              this.$set(this.featureFlag, v.pn, !!(v.content == 'true'))
            })
          }
        }
      }).catch(() => {})
    },
    // 如果是登录后第一次进入页面，显示通知
    async showNotice () {
      if (this.isLogin()) {
        await this.axios.post('/ccp/tvucc-alert/featureagreement/getNotice', {}).then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0' && res.data.result.length) {
            this.noticeMsg = []
            res.data.result.map(v => {
              if (v.featureName.toLowerCase().includes('tokensystem')) {
                this.noticeMsg.push('TVU Anywhere Token System')
              }
              if (!this.noticeMsg.includes('TVU Grid') && v.featureName.toLowerCase().includes('grid')) {
                this.noticeMsg.push('TVU Grid')
              }
            })
            this.visible.notice = true
          }
        }).catch(() => {})
        return
      }
      this.visible.notice = false
      $('body .v-modal').removeClass('notice')
    }
  },
  watch: {
    $route (to, from) {
      this.dataMark = to.path
    }
  },
  beforeDestroy () {
    this.refreshCookieTimer && clearInterval(this.refreshCookieTimer)
  }
}
</script>
<style lang="less">
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgba(144, 147, 153, 0.3);
  opacity: 0.7;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
.el-main {
  color: #dbdbdb;
  background: #242424;
  height: calc(100% - 64px);
  overflow-y: auto;
  &.iframeMain {
    overflow-y: hidden;
  }
}
</style>
