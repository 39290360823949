import Tool from '@/assets/js/utils.js'
class Sockets {
  // 使用es6语法 生成websocket方法
  constructor (data) {
    this.url = data.url || ''
    this.param = data.param || {}
    this.message = data.message || ''
    this.time = data.time || 2000
    this.success = data.success || ''
    this.error = data.error || ''
    this.response = data.response || ''
    this.key = data.key || ''
    // 内部变量
    this.closed = null
    this.interval = null
    this.responseObj = null
    this.successObj = null
    this.rInfo = null
    this.sendMsgs = null
    this.socket = null
    this.sendHeart = data.sendHeart || false
    this.receMsg = null
    this.sendHeartInterval = null
  }

  init () {
    /*
     * var protocol = document.location.protocol === 'https:' ? 'wss://' : 'ws://'
     * var pathname = document.location.pathname.slice(document.location.pathname.indexOf('/'), document.location.pathname.indexOf('/', document.location.pathname.indexOf('/') + 1) + 1)
     */
    var urlParam = ''
    var that = this
    that.closed = false
    that.interval = {}
    that.responseObj = {}
    that.successObj = {}
    that.sendMsgs = {}
    that.rInfo = {}
    /*
     * console.log('init', true)
     * 拼接websocket地址
     */
    for (var k in that.param) {
      urlParam += k + '=' + that.param[k] + '&'
    }
    urlParam = urlParam.slice(0, urlParam.length - 1)
    if (urlParam) urlParam = '?' + urlParam
    if (!that.url) {
      console.log('请输入接口地址')
      return
    }
    // var socketUrl = protocol + document.location.host + pathname + that.url + urlParam;
    var socketUrl = that.url + urlParam
    try {
      that.socket = new WebSocket(socketUrl)
    } catch (error) {
      console.log(error)
    }
    // 绑定事件
    that.socket.onopen = function () {
      that.send({
        message: that.message,
        time: that.time,
        key: that.key,
        resonse: that.response,
        success: that.success
      })
      if (that.sendHeartInterval) clearInterval(that.sendHeartInterval)
      // 用来判断是否发心跳
      if (that.sendHeart) {
        that.sendHeartInterval = setInterval(function () {
          if (that.socket && that.socket.readyState === 1) {
            that.socket.send(1)
          }
        }, 5000)
      }
    }
    that.socket.onmessage = function (e) {
      Object.keys(that.responseObj).forEach(function (key) {
        var response = that.responseObj[key]
        response(e)
      })
      if (e.data !== 'heartbeat' && e.data && e.data.length > 1) {
        if (!Tool.isJSON(e.data)) {
          console.log('数据格式不正确', e.data)
          return
        }
        var data = JSON.parse(e.data)
        var key
        if (data.categoryId && data.operationType) {
          var rkey = 'key' + data.categoryId + data.operationType
          Object.keys(that.rInfo).forEach(function (v) {
            if (that.rInfo[v] === rkey) {
              key = v
            }
          })
        } else if (data.dataType) {
          key = data.dataType
        }
        key = key || JSON.parse(e.data).module // 获取后台返回的key 以便响应对应的response 注意这个key要和后台返回的关键字相同
        var success = that.successObj[key]
        try {
          if (typeof that.receMsg == 'function') that.receMsg(e.data)
          if (success) success(JSON.parse(e.data))
        } catch (error) {
        }
      } else {
        success = that.successObj.heart
        if (success) success(e)
      }
    }
    that.socket.onerror = function (e) {
      if (typeof that.error === 'function') that.error(e)
    }
    that.socket.onclose = function (e) {
      if (!that.closed) {
        setTimeout(() => {
          that.reconnect(e)
        }, 3000)
      }
    }
  }

  reconnect (e) {
    // websocket断线重连，只要非前端主动断开的连接，都会重连
    var that = this
    var sendMsgs = this.sendMsgs
    that.off()
    if (e.code === 3001 || e.code === 3000) return // 3001代表session过期，不用重连了.
    that.init()
    if (!sendMsgs) return
    Object.keys(sendMsgs).forEach(function (v) {
      that.send(sendMsgs[v])
    })
    console.log('reconnect')
  }

  send (data) {
    // websocket发送信息 isMoreSend是否是多次发同一key canSendMore:是否允许多值发送
    var that = this
    var message = data.message || ''
    var time = data.time || 1000
    var key = data.key || ''
    var response = data.response || ''
    var success = data.success || ''
    var heart = data.isHeart || false
    if (data.receMsg) {
      that.receMsg = data.receMsg
    }

    if (!heart) {
      if (!message || !key || !that.interval) return
      if (!data.isOnce) {
        if (Object.keys(that.interval).indexOf(key) > -1) {
          // 当前websocket连接中，key值唯一
          // console.log("当前key值已存在！");
          console.log(key, 'Current key  exist!')
          return
        }
      }
      // 构造信息，信息类型必须为字符串，带有module值
      var msg = typeof message === 'string' ? JSON.parse(message) : message
      if (msg.categoryId && msg.operationType) {
        var rkey = 'key' + msg.categoryId + msg.operationType
        that.rInfo[key] = rkey
      } else if (msg.dataType) {
        key = msg.dataType
      } else {
        if (!msg.module) msg.module = key
      }
      msg = JSON.stringify(msg)
      that.sendMsgs[key] = data
      // 发送信息
      var isOnce = data.isOnce || ''
      if (isOnce) {
        that.interval[key] = setTimeout(function () {
          if (that.socket && that.sendMsgs[key] && that.socket.readyState === 1) { that.socket.send(JSON.stringify(that.sendMsgs[key].message)) }
        }, time)
      } else {
        that.interval[key] = setInterval(function () {
          if (that.socket && that.sendMsgs[key] && that.socket.readyState === 1) { that.socket.send(JSON.stringify(that.sendMsgs[key].message)) }
        }, time)
      }
      // 将相关值储存在当前对象中
      if (typeof response === 'function') that.responseObj[key] = response
      if (typeof success === 'function') that.successObj[key] = success
    } else {
      that.interval[key] = setInterval(function () {
        if (that.socket && that.socket.readyState === 1) { that.socket.send(message) }
      }, time)
      if (typeof response === 'function') that.responseObj[key] = response
      if (typeof success === 'function') that.successObj[key] = success
    }
  }

  has (key) {
    // 查找当前连接中是否有所传key的信息
    return this.interval && Object.keys(this.interval).indexOf(key) > -1
  }

  stop (key) {
    // 从当前连接中，删除指定的信息传递
    var that = this
    if (Object.prototype.toString.call(key) === '[object Array]') {
      key.forEach(function (key) {
        that.stop(key)
      })
    } else if (typeof key === 'string') {
      if (that.interval) {
        if (Object.keys(that.interval).indexOf(key) > -1) {
          clearInterval(that.interval[key])
          delete that.interval[key]
          delete that.responseObj[key]
          delete that.successObj[key]
          delete that.sendMsgs[key]
          if (that.rInfo[key]) delete that.rInfo[key]
        }
      }
    } else {
      console.log('数据格式错误')
    }
  }

  off () {
    // 关闭连接，重置数据，清除所有定时器
    var that = this
    that.closed = true
    if (that.interval) {
      Object.keys(that.interval).forEach(function (k) {
        clearInterval(that.interval[k])
      })
      that.interval = null
      that.responseObj = null
      that.successObj = null
      that.rInfo = null
      that.sendMsgs = null
    }
    if (that.socket) {
      that.socket.close()
      console.log('close websocket')
      that.socket = null
    }
  }
}

export default Sockets
