import Vue from 'vue'
import Router from 'vue-router'
import $store from '@/store.js'
// import Tool from '@/assets/js/utils.js'
import Bus from '@/assets/js/vueBus.js'
import Tool from './assets/js/utils'
// import transformProtocol from '@/assets/js/transformProtocol.js'

// 按需加载书写形式
const ReceiverControl = () => import('@/views/receivercontrol/index')
const MySource = () => import('@/views/MySource/index')
const SourceMap = () => import('@/components/sourceMap')
// 百度地图
const SourceMapTrack = () => import('@/views/MySource/sourceMapTrack')

const Alert = () => import('@/views/Alert')
const Token = () => import('@/views/Token/Token')
const Share = () => import('@/views/Share/index')
const LiveVideo = () => import('@/views/Share/LiveVideo')
const Social = () => import('@/views/Share/SocialAccount')
const MultiView = () => import('@/views/MultiView/Index')
const MultiViewPlus = () => import('@/views/MultiViewPlus/Index')
const Booking = () => import('@/views/Booking')
const Transcriber = () => import('@/views/Transcriber')
const Setting = () => import('@/views/Setting')
const RouterMonitor = () => import('@/views/RouterMonitor')
const VideoC = () => import('@/components/VideoC')
const Info = () => import('@/views/MySource/receiverInfo')
const Cascardar = () => import('@/components/Cascader')
const SourceContent = () => import('@/views/MySource/sourceContent')
const SourceList = () => import('@/components/sourceList')
const VideoButton = () => import('@/components/VideoButton')
const SelectSourceR = () => import('@/components/SelectSourceR')
const Switch = () => import('@/views/Switch/index')
/* test */
const HeaderCc = () => import('@/components/HeaderCc')
// rps
const Rps = () => import('@/views/Rps')
const MyReceiver = () => import('@/views/MyReceiver/Index')
const NewHome = () => import('@/views/Home/Index')
const NMySource = () => import('@/views/Home/RightSource/Index')
const NSourceContent = () => import('@/views/Home/RightSource/sourceContent')
const NSourceList = () => import('@/components/sourceList')
const NSourceMap = () => import('@/components/sourceMap')
const SourceControl = () => import('@/views/SourceControl/index')
const Manual = () => import('@/views/SourceControl/Manual')
const ChannelSetting = () => import('@/views/SourceControl/ChannelSetting')
const GridManage = () => import('@/views/GridManage/switchGrid')
const SearchMonitor = () => import('@/views/SearchMonitor/index')
const MultiControl = () => import('@/views/MultiControl/index')
let routes = [
  {
    path: '/',
    name: '',
    redirect: {
      name: 'SourceContent'
    }
  },
  {
    path: '/Cascardar',
    name: 'Cascardar',
    component: Cascardar
  },
  {
    path: '/hm',
    name: 'MySource',
    component: MySource,
    meta: {
      title: 'TMySource'
    },
    children: [
      {
        path: '',
        name: 'SourceContent',
        component: SourceContent,
        meta: {
          title: 'TMySource'
        }
      },
      {
        path: 'list',
        name: 'SourceList',
        component: SourceList,
        meta: {
          title: 'TMySource'
        }
      },
      {
        path: 'SourceMap',
        name: 'SourceMap',
        component: SourceMap,
        meta: {
          title: 'TMySource'
        }
      },
      {
        path: 'SourceMapTrack',
        name: 'SourceMapTrack',
        component: SourceMapTrack,
        meta: {
          title: 'TMySource'
        }
      }
    ]
  },
  {
    path: '/sem',
    name: 'setting',
    component: Setting,
    meta: {
      title: 'TSetting'
    }
  },
  {
    path: '/rc',
    name: 'AdvanceControl',
    component: ReceiverControl,
    meta: {
      title: 'TAdvanceControl'
    }
  },
  {
    path: '/tm',
    name: 'MultiControl',
    component: MultiControl,
    meta: {
      title: 'TMultiControl'
    }
  },
  {
    path: '/st',
    name: 'Setting',
    component: Setting,
    meta: {
      title: 'TSetting'
    }
  },
  {
    path: '/stS',
    name: 'Setting',
    component: Setting,
    meta: {
      title: 'TSetting'
    }
  },
  {
    path: '/bk',
    name: 'Booking',
    component: Booking,
    meta: {
      title: 'TBooking'
    }
  },
  {
    path: '/tck',
    name: 'Transcriber',
    component: Transcriber,
    meta: {
      title: 'Transcriber'
    }
  },
  {
    path: '/mv',
    name: 'MultiView',
    component: MultiView,
    meta: {
      title: 'TMultiView'
    }
  },
  {
    path: '/mvp',
    name: 'MultiViewPlus',
    component: MultiViewPlus,
    meta: {
      title: 'TMultiView'
    }
  },
  {
    path: '/sm',
    name: '',
    component: Share,
    meta: {
      title: 'TShare'
    },
    children: [
      {
        path: '',
        name: '',
        component: LiveVideo,
        meta: {
          title: 'TShare'
        }
      },
      {
        path: 'LiveVideo',
        name: 'LiveVideo',
        component: LiveVideo,
        meta: {
          title: 'TShare'
        }
      },
      {
        path: 'SocialAccount',
        name: 'SocialAccount',
        component: Social,
        meta: {
          title: 'TShare'
        }
      }
    ]
  },
  {
    path: '/tkm',
    name: 'Token',
    component: Token,
    meta: {
      title: 'TToken'
    }
  },
  {
    path: '/nal',
    name: 'Alert',
    component: Alert,
    meta: {
      title: 'TAlert'
    }
  },
  {
    path: '/al',
    name: 'Alert',
    component: Alert,
    meta: {
      title: 'TAlert'
    }
  },
  {
    path: '/rm',
    name: 'RouterMonitor',
    component: RouterMonitor,
    meta: {
      title: 'TRouterMonitor'
    }
  },
  {
    path: '/header',
    name: 'HeaderCc',
    component: HeaderCc
  },
  {
    path: '/videoC',
    name: VideoC,
    component: VideoC
  },
  {
    path: '/info',
    name: Info,
    component: Info
  },
  {
    path: '/videoButton',
    name: VideoButton,
    component: VideoButton
  },
  {
    path: '/SelectSourceR',
    name: SelectSourceR,
    component: SelectSourceR
  },
  {
    path: '/sw',
    name: Switch,
    component: Switch,
    meta: {
      title: 'TMyReceivers'
    }
  },
  {
    path: '/rp',
    component: Rps,
    meta: {
      title: 'RPS'
    }
  },
  {
    path: '/mr',
    component: MyReceiver,
    meta: {
      title: 'TMyReceivers'
    }
  },
  {
    path: '/nhm',
    component: NewHome,
    meta: {
      title: 'homeTitle'
    },
    children: [
      {
        path: '/',
        name: '',
        component: NMySource,
        meta: {
          title: 'homeTitle'
        },
        children: [
          {
            path: '',
            name: 'NSourceContent',
            component: NSourceContent,
            meta: {
              title: 'homeTitle'
            }
          },
          {
            path: 'list',
            name: 'NSourceList',
            component: NSourceList,
            meta: {
              title: 'homeTitle'
            }
          },
          {
            path: 'map',
            name: 'NSourceMap',
            component: NSourceMap,
            meta: {
              title: 'homeTitle'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/sc',
    name: '',
    component: SourceControl,
    meta: {
      title: 'homeTitle'
    },
    children: [
      {
        path: '',
        name: '',
        component: Manual,
        meta: {
          title: 'homeTitle'
        }
      },
      {
        path: 'manual',
        name: 'manual',
        component: Manual,
        meta: {
          title: 'homeTitle'
        }
      },
      {
        path: 'pool',
        name: 'pool',
        component: Manual,
        meta: {
          title: 'homeTitle'
        }
      },
      {
        path: 'settingChannel',
        name: 'settingChannel',
        component: ChannelSetting,
        meta: {
          title: 'homeTitle'
        }
      }
    ]
  },
  {
    path: '/gd',
    component: GridManage,
    meta: {
      title: 'GridManage'
    }
  },
  {
    path: '/smn',
    component: SearchMonitor,
    meta: {
      title: 'SearchMonitor'
    }
  }
]
const router = new Router({
  routes,
  linkExactActiveClass: 'tabActive'
})
router.beforeEach((to, from, next) => {
  if (typeof SM == 'object') {
    typeof SM.closeShareBox == 'function' && SM.closeShareBox()
  }
  if ($('.mediaDialog').length > 0 && $('#dialogBg').length > 0) {
    $('#dialogBg').remove()
    $('.mediaDialog').remove()
  }
  $store.dispatch('common/baseUrl')
  let pageMark = to.path.split('/')[1]
  pageMark = pageMark === 'stS' ? 'st' : pageMark
  $.ajax({
    url: '/ccp/initLoginUser',
    type: 'get',
    headers: { pageMark: pageMark },
    success (res, status, xhr) {
      if (res.errorCode == '0x0') {
        localStorage.setItem('userId', res.result.id)
        localStorage.setItem('EmailName', res.result.email)
        let data = res.result ? { userInfo: res.result } : {}
        $store.commit('common/BASE_USER_INFO', data)
        Bus.$emit('roleInfo', data.userInfo.role)
        if ((res.result.pagePermissionInfo != null && res.result.pagePermissionInfo.permission) || pageMark == 'mvp' || pageMark == 'gd') {
          // 修改网页标题
          if (to.meta.title && to.meta.title != undefined && to.meta.title != 'undefined') {
            document.title =
              i18n.t('lang.titlePre') + ' - ' + i18n.t(`lang.${to.meta.title}`)
          } else {
            document.title = 'TVU Command Center'
          }
          next()
        } else {
          next({
            path: `/${res.result.pagePermissionInfo.defeaultPage}`
          })
        }
      } else if (res.errorCode == '80000302') {
        window.location.href = res.errorInfo
      }
    },
    error (xhr) {
      if (xhr.status == 401 || xhr.status == 402) {
        const { customeDirect } = $store.state.common.baseUrl
        let urlArr = JSON.parse(localStorage.getItem('newuserserviceLoginUrl'))
        let curUrl
        if (urlArr) {
          for (let i = 0; i < urlArr.length; i++) {
            if (urlArr[i].cc == window.location.host) {
              curUrl = JSON.parse(JSON.stringify(urlArr[i]))
              break
            }
          }
        }
        const filterArr = customeDirect ? customeDirect.filter(v => location.hostname.includes(v.domain)) : []
        if (curUrl) {
          // 有匹配的按匹配方式跳转
          window.location.href = `${window.location.protocol}//${
            curUrl.userservice
          }/newuserservice/?url=${window.location.protocol}//${
            curUrl.cc
          }/tvucc&serviceName=${localStorage.getItem('authServerName')}`
        } else if (filterArr.length) {
          window.location.href = filterArr[0].loginUrl
        } else {
          // 如果没有匹配的按老方式跳转
          window.location.href = `${
            JSON.parse(window.localStorage.newCCInitConfig).urlInfo
              .newUserServiceUrl
          }/?url=${
            JSON.parse(window.localStorage.newCCInitConfig).urlInfo.newTvuccUrl
          }/&serviceName=${localStorage.getItem('authServerName')}`
        }
        window.localStorage.setItem('NewCCsession', '')
        window.sessionStorage.setItem('userChangeInfo', '')
        // Config-T会存入TSID，在登出时应该清空
        Tool.clearTSID()
      }
    }
  })
})
Vue.use(Router)
export default router
