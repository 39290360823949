import Vue from 'vue'

let delayTime, delayTimer, bitTime, bitTimer
const handInputVal = (el, binding, value) => {
  value = value.toString()
  if (value.includes('.')) {
    value = `${value.split('.')[0]}.${value.split('.')[1]}`
    value = value.split('.')[1] ? value : value.split('.')[0]
  }
  value = Number(value) > Number(binding.value.max) ? binding.value.max : value
  value = Number(value) < Number(binding.value.min) ? binding.value.min : value
  if (typeof value == 'string' && value.split('.').length > 2) {
    value = Number(`${value.split('.')[0]}.${value.split('.')[1]}`)
  }
  return value
}

Vue.directive('inputDelayRange', {
  update: (el, binding) => {
    let value = (el.value != undefined
      ? el.value
      : el.children[0].value
    ).replace(/[^\d.]/g, '')
    if (value == '') {
      binding.value.updateValue(value, binding.value.index)
      return
    }
    binding.value.max = 50
    binding.value.min = 0.5
    value = handInputVal(el, binding, value)
    delayTime = 2
    if (delayTimer) {
      clearInterval(delayTimer)
    }
    delayTimer = setInterval(() => {
      delayTime--
      if (!delayTime) {
        binding.value.updateValue(value, binding.value.index)
      }
    }, 1000)
  }
})

Vue.directive('inputBitRange', {
  update: (el, binding) => {
    let value = (el.value != undefined
      ? el.value
      : el.children[0].value
    ).replace(/[^\d.]/g, '')
    if (value == '') {
      binding.value.updateValue(value, binding.value.index)
      return
    }
    binding.value.max = 10240
    binding.value.min = 100
    value = handInputVal(el, binding, value)
    bitTime = 2
    if (bitTimer) {
      clearInterval(bitTimer)
    }
    bitTimer = setInterval(() => {
      bitTime--
      if (!bitTime) {
        binding.value.updateValue(value, binding.value.index)
      }
    }, 1000)
  }
})

/*
 * Vue.directive('loadmore', {
 *     bind (el, binding) {
 */

/*
 *       // 获取element-ui定义好的scroll盒子
 *       const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
 *       SELECTWRAP_DOM.addEventListener('scroll', function () {
 *         console.log('222')
 *         const CONDITION = this.scrollHeight - this.scrollTop === this.clientHeight;
 *         if (CONDITION == false) {
 *           binding.value()
 *         }
 *       })
 *     }
 *   })
 */
