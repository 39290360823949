const filterSpecialChar = {
  /*
   * regular 通过传入的正则表达式来过滤字符,默认过滤空格
   * changeVal 过滤后更改数据
   */
  install: function (Vue) {
    Vue.directive('filterSpecialChar', {
      update: function (el, binding, vnode) {
        try {
          let inputVal = el.tagName == 'INPUT' ? el.value : el.children[0].value
          if (!inputVal) {
            return false
          }
          let regular = binding.value.regular ? binding.value.regular : /\s+/g
          // regular:/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g
          inputVal = inputVal.replace(regular, '')
          // 手动刷新data中绑定的属性
          binding.value.changeVal(inputVal)
          // el.dispatchEvent(new Event(modifiers.lazy ? 'change' : 'input'))
        } catch (e) {
          console.info('filterSpecialCharError', e)
        }
      }
    })
  }
}

export default filterSpecialChar
