import $store from '@/store.js'
import Tool from '@/assets/js/utils.js'
let protocol = window.location.protocol
/*
 * let version = localStorage.getItem('newCCVersion');
 * 加载serviceList.js并存储全局变量供media及公共header使用
 */
function loadSudoku (data) {
  window.serviceList = {
    userserviceApi: `${protocol}//${data.userAuthUrl}`, // "cc-ms-dev.tvunetworks.com/userAuth"， userauth地址           （可按照服务后端配置自行绑定）
    newUserServiceUrl: `${protocol}//${data.serviceListUrl}`, // "cc-ms-dev.tvunetworks.com/serviceListUI"，
    menuInterfaceUrl: `${protocol}//${data.menuListInterfaceUrl}`, // 用于公共头部接口
    shareUrl: `${protocol}//${data.shareServerUrl}`, // 用于header
    mediaApiUrl: `${protocol}//${data.mediaApiUrl}`, // 用于media接口
    mediaServerUrl: `${protocol}//${data.mediaServerUrl}`, // 用于media js css
    blobUrl: `${protocol}//${data.blobUrl}`,
    menuServerUrl: `${protocol}//${data.menuServerUrl}`
  }
  window.versionNewsObj = {
    code: $store.state.common.baseUrl.versionCode,
    pageSize: 10,
    versionUrl: `${protocol}//${$store.state.common.baseUrl.versionUrl}`,
    versionApiUrl: `${protocol}//${$store.state.common.baseUrl.versionApiUrl}`,
    theme: 'black',
    rightWidth: '280px'
  }
  // document.write(`<script type='text/javascript' src="${window.versionNewsObj.versionUrl}/versionNews.js"></script>`)
  if ($store.state.common.baseUrl.gtag) initGtag()
}
function initGtag () {
  // Global site tag (gtag.js) - Google Analytics
  (function (h, o, t, j, a, r) {
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + j
    a.appendChild(r)
  })(window, document, 'https://www.googletagmanager.com/gtag/js?id=', $store.state.common.baseUrl.gtag)
  window.dataLayer = window.dataLayer || []

  function gtag () { dataLayer.push(arguments) }

  gtag('js', new Date())
  gtag('config', $store.state.common.baseUrl.gtag)
}
// 加载language的js文件
const loadLanguageJs = () => {
  let jsUrl = `${protocol}//${$store.state.common.baseUrl.languageUrl}/langConfig.js`
  document.write(`<script type="text/javascript" src=${jsUrl} ></script>`)
  isCompleteLoad()
}
const isCompleteLoad = () => {
  if (typeof langConfig != 'undefined') {
    $store.state.common.hasInitLang = true
  } else {
    setTimeout(() => {
      isCompleteLoad()
    }, 100)
  }
}
// 加载headerConfig
function loadHeaderJs () {
  // let cssUrl = 'https://local.tvunetworks.com:8000/headerConfig.min.css'
  let cssUrl = `${protocol}//${$store.state.common.baseUrl.menuServerUrl}headerConfig.min.css`
  let jsUrl = `${protocol}//${$store.state.common.baseUrl.menuServerUrl}headerConfig.js`
  // let jsUrl = 'https://local.tvunetworks.com:8000/headerConfig.js'
  Tool.includeLinkStyle(cssUrl)
  Tool.dynamicLoadJs(jsUrl, function () {
    let timers = setInterval(() => {
      try {
        if (langConfig) {
          let lang =
            langConfig.langValue ||
            window.localStorage.getItem('lang') ||
            navigator.language ||
            navigator.userLanguage
          clearInterval(timers)
          window.headerObj = new headerTemplate(lang)
        }
      } catch (err) {
        if (err) {
        }
      }
    }, 10)
  })
}
// 加载mediaCommon
function loadMediaJs (callback) {
  if ($('#mediaCommon').length > 0 && typeof callback == 'function') {
    callback()
  } else {
    let url = `${protocol}//${$store.state.common.baseUrl.mediaServerUrl}/js/mediaCommon.js`
    // let url = 'https://sunny-dev8088.tvunetworks.com/js/mediaCommon.js'
    Tool.dynamicLoadJs(
      url,
      function () {
        if (typeof callback == 'function') {
          callback()
        }
      },
      'mediaCommon'
    )
  }
}
export { loadSudoku, loadMediaJs, loadHeaderJs, loadLanguageJs }
