import Tool from '@/assets/js/utils.js'
import $store from '@/store.js'
import SocketApi from '@/assets/js/websocket'
let protocol = document.location.protocol === 'https:' ? 'wss://' : 'ws://' // 获取协议
export const createWebsocket = {
  pageRequest (type, key) {
    let dataRequest = {
      url: `${protocol}${$store.state.common.baseUrl.wsBaseUrl}/pageRequest?taskId=${Tool.return32Str()}_q`
    }
    // 初始化

    if (type == 'off' && this.pageRequests) {
      this.pageRequests.off() // 关闭
    } else if (type == 'stop' && this.pageRequests) {
      this.pageRequests.stop(key)
    } else {
      if ($store.state.common.baseUrl.wsBaseUrl) {
        this.pageRequests = new SocketApi(dataRequest)
        this.pageRequests.init()
      }
    }
    $store.state.websocket.pageRequest = this.pageRequests
  },
  // 新的websocket用来获取R的信息
  pageRWebsocket (type, key, needSendHeart) {
    let dataRequest = {
      url: `${protocol}${$store.state.common.baseUrl.wsBaseUrl}/pageRWebsocket?taskId=${Tool.return32Str()}_q`,
      sendHeart: true
    }
    if (type == 'off' && this.pageRWebsockets) {
      this.pageRWebsockets.off() // 关闭
    } else if (type == 'stop' && this.pageRWebsockets) {
      this.pageRWebsockets.stop(key)
    } else {
      if ($store.state.common.baseUrl.wsBaseUrl) {
        this.pageRWebsockets = new SocketApi(dataRequest)
        this.pageRWebsockets.init()
      }
    }
    $store.state.websocket.pageRWebsocket = this.pageRWebsockets
  },
  deviceChange (type, key) {
    let dataWebsocket = {
      url: `${protocol}${$store.state.common.baseUrl.wsBaseUrl}/deviceChange?taskId=${Tool.return32Str()}_q`
    }
    // 初始化
    if (type == 'off' && this.deviceChanges) {
      this.deviceChanges.off() // 关闭
    } else if (type == 'stop' && this.deviceChanges) {
      this.deviceChanges.stop(key)
    } else {
      this.deviceChanges = new SocketApi(dataWebsocket)
      this.deviceChanges.init()
    }
    $store.state.websocket.deviceChange = this.deviceChanges
  },
  pageAnyWheresocket (type, key, peerId) {
    let dataWebsocket = {
      url: `${protocol}${$store.state.common.baseUrl.wsBaseUrl}/anywhere/peerId?math=${Math.random()}`
    }
    // 初始化
    if (type == 'off' && this.pageAnyWheresockets) {
      this.pageAnyWheresockets.off() // 关闭
    } else if (type == 'stop' && this.pageAnyWheresockets) {
      this.pageAnyWheresockets.stop(key)
    } else {
      this.pageAnyWheresockets = new SocketApi(dataWebsocket)
      this.pageAnyWheresockets.init()
    }
    let newWebsocket = $store.state.websocket
    newWebsocket.pageAnyWheresocket = this.pageAnyWheresockets
    $store.commit('websocket', newWebsocket)
  },
  pageSerBusRequest (type, key) {
    let dataRequest = {
      url: `${protocol}/${$store.state.common.baseUrl.wsBaseUrl}/pageSerBusRequest?taskId=${Tool.return32Str()}&businessId=${localStorage.getItem('userId')}&notCheckUser=true`,
      sendHeart: true
    }
    // 初始化

    if (type == 'off' && this.pageSerBusRequestWs) {
      this.pageSerBusRequestWs.off() // 关闭
    } else if (type == 'stop' && this.pageSerBusRequestWs) {
      this.pageSerBusRequestWs.stop(key)
    } else {
      if ($store.state.common.baseUrl.wsBaseUrl) {
        // 如果获取到url 并且获取到session之后才可以创建websocket
        this.pageSerBusRequestWs = new SocketApi(dataRequest)
        this.pageSerBusRequestWs.init()
      }
    }
    $store.state.websocket.pageSerBusRequest = this.pageSerBusRequestWs
  }
}
export default {
  install: function (vm) {
    vm.prototype.$createWebsocket = createWebsocket
  }
}
