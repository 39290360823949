export default {
  lang: {
    CustomerUser: 'Customer User',
    switchTo: 'Switch to',
    internetOffline: 'Internet connection failed, please try again later.',
    /* ---- title ---- */
    titlePre: 'TVU Командный Центр',
    TMySource: 'Мои источники',
    TAdvanceControl: 'Расширенный контроль',
    TMultiControl: 'Multi Control',
    TMyReceivers: 'Мои приемники',
    TFileDownload: 'Загрузка файлов',
    TSetting: 'Настройка',
    TMultiView: 'Полиэкран',
    TShare: 'Публикация',
    TToken: 'Токен',
    TAlert: 'Оповещение',
    GridViaToken: 'Grid Via Token',
    GlobalGrid: 'Global Grid',
    TRouterMonitor: 'Маршрутизатор Монитор',
    TBooking: 'Бронирование',
    RPS: 'RPS',
    homeTitle: 'Домашняя страница',

    title: 'Командный Центр',
    title1: 'Переключение',
    deviceSource: 'Источник',
    source: 'Источник',
    i18n_source: 'Источник',
    destination: 'Адресат',
    i18n_destination: 'Адресат',
    grid: 'TVU Грид',
    all: 'Все',
    i18n_all: 'Все',
    live: 'Жить',
    home_Live: 'Жить',
    live_OnHold: 'Live on hold',
    online: 'Онлайн',
    home_EnterReceiverNamePeerID: 'Введите имя получателя/PeerID',
    home_Online: 'Онлайн',
    offline: 'Оффлайн',
    sort: 'Сортировать по статусу',
    favorite: 'Избранное',
    status: 'Статус',
    total: 'Всего',
    stop: 'Стоп',
    Stop: 'Стоп',
    end: 'Стоп',
    Switch: 'Переключить',
    Take: 'Переключить',
    of: 'Of',
    notSource: 'Пожалуйста выберите источник',
    ParamError: 'Ошибка сервера, пожалуйста свяжитесь с поддержкой TVU',
    SendToRFail: 'Ошибка сервера, пожалуйста свяжитесь с поддержкой TVU',
    ReceiverNotFound: 'Приёмник не найден, пожалуйста уточните и попробуйте ещё раз',
    ReceiverOffLine: 'Приемник выключен',
    DeviceOffLine: 'Источник выключен',
    RnotfoundthisSource: 'Источник не найден, пожалуйста уточните и попробуйте снова',
    ReceiverNotLive: 'Трансляция на приëмнике остановлена',
    ReceiverNotLiveWithSource: 'Прямая трансляция между приёмником и источником не установлена',
    selectWebrtcSource: 'Извините ... Предпросмотр видео сейчас недоступен. Пожалуйста проверьте настройки WebRTC на устройстве',
    timeOut: 'Время запроса истекло, пожалуйста попробуйте позже',
    pleaseWait: 'Запрос послан, пожалуйста ожидайте',
    modelTips: 'Полезная информация',
    close: 'Нет',
    i18n_no: 'Нет',
    updateBrowser: 'Для лучшего отображения используйте последнюю версию Chrome',
    guideSelT: 'Выберите источник для перехода',
    guideDesVideo: 'Опишите видео',
    guideFavorite: 'Отметьте, чтобы внести в избранное',
    next: 'Следующий',
    goIt: 'Иди',
    notSwitch: 'Невозможно переключиться на тот же источник',
    loading: 'Загружается',
    i18n_loading: 'Загружается~',
    stopping: 'Остановлен',
    receiverisnotexist: 'Указанный приёмник недоступен',
    receiverNotLiveWithCurrentSource: 'Источник не осуществляет трансляцию с данным приемником',
    rIsNotLiving: 'Данный приёмник не осуществляет трансляцию',
    localSourceCanNotStop: 'Приёмник транслирует сигнал с локального видео входа. Не может быть остановлен',
    sourceNotLiveWithCurrentReceiver: 'Истоник не осуществляет трансляцию с данным приемником',
    cameraOff: 'Камера не подключена!',
    ReceiverOnLive: 'Данный приёмник сейчас осуществляет трансляцию с другого передатчика. Вы желаете остановить ее и начать трансляцию с этого передатчика?',
    ReceiverOnLive1: 'Данный приёмник сейчас осуществляет трансляцию с другого передатчика',
    ReceiverOnLive2: 'Вы желаете остановить ее и начать трансляцию с этого передатчика?',
    ReceiverDeviceOnLine: 'Данный передатчик сейчас осуществляет трансляцию на другой приемник. Вы желаете остановить ее и начать трансляцию с этим приемником?',
    ReceiverDeviceOnLine1: 'Данный передатчик сейчас осуществляет трансляцию на другой приемник',
    ReceiverDeviceOnLine2: 'Вы желаете остановить ее и начать трансляцию с этим приемником?',
    i18n_transimitterLiveReceiver: 'Данный передатчик сейчас осуществляет трансляцию на другой приемник. Вы желаете остановить ее и начать трансляцию с этим приемником?',
    home_transimitterLiveReceiver: 'Данный передатчик сейчас осуществляет трансляцию на другой приемник. Вы желаете остановить ее и начать трансляцию с этим приемником?',
    i18n_stopLiveFailed: 'Не удалось остановить трансляцию',
    i18n_startLiveFailed: 'Не удалось запустить трансляцию',
    vaildVal: 'Допустимое значение {0}<->{1}',
    adminUser: 'Учетная запись  поддержки или главного администратора временно недоступна для данной системы',
    netStop: 'Прерывание соединения с сетью ...',
    i18n_yes: 'Да',
    confirm: 'Да',
    PHSearch: 'Поиск видео или трансивера',
    NoResult: 'Ничего не найдено',
    DeviceNoSource: 'Источник некорректен, пожалуйста проверьте видео соединение',
    inputDescrible: 'Название сюжета',
    searchT: 'Название источника',
    versionLow61: 'Версия программного обеспечения источника устарела. Необходимо установить обновление',
    // Start && stop live code
    isBooking: 'Получатель выполняет событие резервирования',
    currentRLiveSourceNotBelongToUser: 'Текущий живой источник не принадлежит текущему пользователю.',
    receiverNotConnect: 'Приемник не подключен',
    notHaveStartauthor: 'Нет разрешения на запуск Live, обратитесь в службу технической поддержки',
    notHaveStoptauthor: 'Разрешение No Stop Live, пожалуйста, свяжитесь со службой технической поддержки',
    notHaveVoipauthor: 'Нет разрешения VoIP, пожалуйста, свяжитесь со службой технической поддержки',
    // Add ext
    success: 'успех',

    enditFailed: 'Редактировать не удалось',
    // My Receiver Page
    rinputDescrible: 'Введите памятку',
    sourceFrom: 'Источник от',
    newMyRecevierInputTitle: 'Видео или Направление',
    newMyRecevierSort: 'Сортировать по статусу',
    receiverDataIsEmpty: 'Receiver data empty',
    recevierMyFavorite: 'Мое любимое',
    addFavoriteSuccess: 'Добавьте к любимому успеху.',
    EnterSourceNamePeerID: 'Введите имя источника/PeerID',
    notSupportFunction: 'Not support this operation',

    // advance control
    moreThanLimitation: 'Max number of previews - {0} has been reached. Please turn off other preview windows first.',
    syncTitle: 'Time Lock is only availble when selected receivers are live with TVU Pack',
    syncCanUseTitle: '启动失败,请检查R和T的版本是否都大于6.1(包含6.1)，如果是的，请至少选择2个以上的R',

    notHaveSyncFeature: 'Device {0} not access tpc time lock feature',
    synchronizing: 'Device is synchronizing',
    deviceSynchronizing: 'Exist device synchronizing by other account',
    maxMultiControlNumber: 'You can only select six video to preview',
    notLastOne: 'You need to delete from last one',
    picturesFormat: 'The max picture size is 480*640',
    theSameDevice: 'The current device is already exists',

    /* ---- home ---- */
    // new home
    receiverControlTitle: 'Receiver Control',
    sourceControlTitle: 'Source Control',
    adjustCamera: 'Adjust Camera',
    dynamicsorting: 'Dynamic Sorting',
    serverFailed: 'Server exception',
    modeSwitching: 'Mode switching, please operate later',
    anywhereTimeout: 'Anywhere connection timed out, please check device settings or contact technical support.',

    // new multiview
    cannotSwitchSplitScreenMode: 'Your current video number is larger than you want to switch the split-screen mode, can not switch!',
    rVerionNeedV56Above: 'The receiver software requires to be v5.6 or above.',
    showInfo: 'Show Info',
    AddToCall: 'Add to call',
    startToCall: 'Start the call',
    selectMonitorSelectCall: 'Only TVUPack and TVU Anywhere support VoIP.',
    selectObjCall: 'Please select which you want to establish the voice call with.',
    setVideoFeedbackTrueViewVideo: 'Video preview is disabled. Please enable it or contact TVU support for assistance.',
    setVideoFeedbackTrueViewVideoMoreLive: 'Max number of previews - 5 has been reached. Please turn off other preview windows first.',
    continueCall: 'Continue call',
    interruptAllCall: 'Would you like to end the active calls?',
    interruptAll: 'Hang up',
    file: 'File',
    addfile: 'Add File',
    addFolder: 'Add Folder',
    addMedia: 'Add Media',
    Folder: 'Folder',
    selectAFolder: 'Select a folder',
    addvideo: 'Add Local Video',
    editfile: 'Edit File',
    fileName: 'File name',
    bookingImportFile: 'Import Excel or CSV files to add in batches.',
    bookingImportFileTemp: 'View file template.',
    search: 'Search',
    bookingChannel: 'Channel',
    bookingDeleteFail: 'Some file is being used by an event and cannot be deleted',
    bookingEditFail: 'Some events are using this file and cannot be modified',
    durationEmpty: 'Duration connot be empty.',
    durationType: 'Duration need enter a positive integer',
    durationUnit: '( s )',
    upload: 'Upload',
    proccessing: 'Proccessing...',
    uploadVideoLocal: 'Upload local video file',
    uploadHasSame: 'The file you selected has already been uploaded',
    selectAVideo: 'Please select a video to add.',
    videoExist: 'Video already exists',
    fileNeedType: 'Upload files can only be in xls, xlsx, csv format!',
    videoNeedType: 'Upload videos can only be in ts,rm,rmvb,wmv,wtv,avi,3gp,mkv,mpg,video/mpeg,video/mp4,mov format!',
    // playout
    fileNotformat: 'The file content format is incorrect',
    noAvailable: 'N / A',
    monitorUserTips: 'Are you sure you want to View this user?',
    gotoSupportTips: 'Are you sure you want to return to the support account?',
    deviceNameBytes: 'Device name can not exceed 15 bytes.',
    VirtualCloudR: 'Virtual Cloud Receiver',
    mediaFolder: 'Media',

    // setting
    instanceId: 'Instance id',
    rId: 'Receiver ID',
    Ip: 'IP',
    creater: 'Starter',
    startTime: 'Start Time',
    operation: 'Operation',
    stopR: 'Stop Receiver',
    stopTime: 'Shutdown time',
    shutdownP: 'Closer',
    Shutdown: 'Shut-down',
    ShutdownTime: 'Shut-down Time',
    startUpCloudR: 'Start-up Cloud Receiver',
    ending: 'Ending',
    ClosedBySupport: 'Closed By Support',
    ClosedBySystem: 'Closed By System',
    canUseArea: 'Available area',
    selectArea: 'Please select a region you want to start',
    connectTpcFail: 'Connect TPC failed',
    connectInstanceFail: 'Connect Instance-service failed',
    getRegionFail: 'Get active region failed',
    createInsFail: 'Create instance failed',
    shutdownInsFail: 'Shutdown instance failed',
    userInsnull: 'User instance is not exist',
    userInsreadyCLose: 'User instance already closed',
    confirmShutDown: 'Are you sure you want to shut it down?',
    virtualRDes: 'Virtual cloud R',

    // multiview plus

    workspace: 'Workspace',
    currentDevice: 'Current Device',
    workspaceList: 'Workspace List',
    newWorkspace: 'New Workspace',
    enternewWorkspace: 'Enter new workspace',
    plzSelectLayout: 'Please select layout.',
    plzEnterspaceName: 'Please enter workspace name.',
    duplicate: 'Duplicate',
    deleteSpace: 'Are you sure you want to delete this workspace?',
    BacktoView: 'Back to View',
    ManageWorkspace: 'Manage Workspace',
    addTo: 'Add to',
    moveTo: 'Move to',
    copyTo: 'Copy to',
    newWorkSpace: 'New Workspace',
    moveSuccess: 'Move Success',
    copySuccess: 'Copy Success',
    addSuccess: 'Add Success',
    deleteSuccess: 'Delete Success',
    deleteInfo: 'Are you sure you want to remove this device from current workspace?',
    ManageDevices: 'Manage Devices',
    showDesc: 'Show Description',
    mvpRecevierSort: 'Sort by name',
    tomultiview: 'Go Multi-view',
    tomultiviewplus: 'Go Multi-view-plus',
    Rename: 'Rename',
    Enter: 'Enter',
    Duplicate: 'Duplicate',
    Delete: 'Delete',
    dragAndDrop: 'Drag and drop to the workspace'
  }
}
