import * as type from './mutations-type.js'
import Vue from 'vue'
import Bus from '@/assets/js/vueBus.js'
import axios from 'axios'

export default {
  [type.BASE_URL] (state, { urlInfo }) {
    state.baseUrl = urlInfo
    if (!state.webrtc) {
      state.webrtc = new Window.RtcClient()
      const https = window.location.protocol.includes('https')
      https ? state.webrtc.init(urlInfo.rtcSslUrl) : state.webrtc.init(urlInfo.rtcUrl)
      // 配置信令服务器地址到开发环境
      // https ? state.webrtc.init('10.12.23.111:9091') : state.webrtc.init('10.12.23.111:9001')
      // https ? state.webrtc.init('10.12.22.94:9091') : state.webrtc.init('10.12.23.111:9001')
    }
    setTimeout(function () {
      Bus.$emit('createPageRequest')
    }, 100)
    // 用于tvu-common-components的传参
    Vue.prototype.$TVU = {
      http: axios,
      serverName: 'CommandCenter',
      userAuthUrl: urlInfo.userAuthUrl // 后端配置的url地址
    }
  },
  [type.BASE_USER_INFO] (state, { userInfo }) {
    state.baseUserInfo = userInfo
    Bus.$emit('siginalUrl')
  },
  [type.MAP_LOADED] (state, data) {
    state.mapLoaded = data
  },
  [type.CURRENT_RID] (state, data) {
    state.currentRid = data
  },
  [type.CURRENT_RINFO] (state, data) {
    state.currentRInfo = data
  },
  [type.SETTING_MAP_SHOW] (state, data) {
    state.settingMapShow = data
  },
  [type.DEVICELIST_VOLUMECHARTDATA] (state, data) {
    state.deviceListVolumeChartData = data
  },
  [type.DEVICELIST_SLOTINFODATA] (state, data) {
    state.deviceListSlotInfoData = data
  },
  [type.DEVICELIST_RECEIVERDATADATA] (state, data) {
    state.deviceListReceiverDataData = data
  },
  [type.DEVICELIST_CURRENTRINFODATA] (state, data) {
    state.deviceListCurrentRInfoData = data
  },
  [type.DEVICELIST_SETINFODATA] (state, data) {
    state.deviceListSetInfoData = data
  },
  [type.CHANGE_SOURCECONTROL_TAB] (state, data) {
    state.sourceControlTab = data
  },
  [type.CHANGE_SOURCESize] (state, data) {
    state.showDeviceSize = data
  },
  [type.CHANGE_RecevierSize] (state, data) {
    state.showRecevierSize = data
  }
}
