import actions from '@/store/common/actions'
import mutations from '@/store/common/mutations'

const state = {
  mapLoaded: 'false',
  // 展开大盒子中的rid
  currentRid: '',
  hasInitLang: false,
  // 展开大盒子中的R信息
  currentRInfo: {},
  webrtc: null,
  baseUrl: {},
  baseUserInfo: {},
  // 列表中设备的信息
  deviceListVolumeChartData: [],
  deviceListSlotInfoData: [],
  deviceListReceiverDataData: [],
  deviceListCurrentRInfoData: [],
  deviceListSetInfoData: [],
  voiceInfo: {},
  mvVoipStream: [], // multiview上面voip的信息
  sourceControlTab: 'manual',
  showDeviceSize: 's', // MySource 页面尺寸变量
  showRecevierSize: 's', // MyRecevier 页面尺寸变量
  playSourceInfo: null,
  bkBookmarkL: 0,
  selectRcPeerId: ''
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
